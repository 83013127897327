<!--Was adding other converter lines.... but that actually didn't work because
it would never check for changes in the selected purchase. I'm saying it's trash-->
<template>
  <app-loading-indicator v-if="loading" />
  <v-container v-else>
    <base-title title="Create Intake" />
    <v-toolbar
      dense
      flat
    >
      <help-dialog
        headline-text="About the intake process"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            @click="on"
          >
            About
          </v-btn>
        </template>
        <p>
          Before catalytic converters can be cut they must first be sorted and counted in the process of creating an <strong>Intake</strong>.
        </p>
        <p>
          When purchase creation is completed all related InventoryBoxes will be automatically marked as CUT (regardless of location).
        </p>
        <p>
          A Intake email detailing the check in counts and intake counts will be sent to <em>new_intake</em> recipients.
        </p>
      </help-dialog>
    </v-toolbar>
    <v-sheet class="px-4">
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-form
          ref="form"
          @submit.prevent
        >
          <v-row>
            <v-col>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model="purchases"
                  :items="receivedPurchases"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select from Purchases (RECEIVED) w/ all Check Ins (COMPLETE)"
                  multiple
                  item-text="friendlyName"
                  return-object
                  hint="Intake multiple purchases (Check In may need to be RECEIVED in Transit Dashboard first)"
                  persistent-hint
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-list>
            <v-tooltip
              bottom
              :disabled="purchases.length > 0"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <ValidationProvider
                    rules="max:30"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-n4"
                      v-model="reference"
                      label="Reference"
                      counter="30"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    rules="max:256"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      label="Notes"
                      v-model="notes"
                      type="string"
                      counter="256"
                      rows="4"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </span>
              </template>
              <span>
                You must select a purchase to continue
              </span>
            </v-tooltip>
            <v-list-item
              v-for="(line, i) in allLines"
              :key="i"
              dense
            >
              <v-list-item-content class="py-0">
                <v-row align="center">
                  <v-col :cols="$vuetify.breakpoint.xs ? 3 : ($vuetify.breakpoint.sm ? 2 : 1)">
                    <v-chip
                      v-if="line.converterCategory"
                      :color="getColorByConverterCategory(line.converterCategory)"
                    >
                      {{ line.converterCategory }}
                    </v-chip>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 4 : 5">
                    <div>
                      {{ line.name }}
                    </div>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-tooltip
                        bottom
                        :disabled="purchases.length > 0"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-text-field
                              :disabled="purchases.length === 0"
                              v-model="line.value"
                              type="number"
                              :suffix="line.units.toLowerCase()"
                              :error-messages="errors"
                            />
                          </span>
                        </template>
                        <span>
                          You must select a purchase to continue
                        </span>
                      </v-tooltip>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-dialog
            v-model="allocationDialog"
          >
            <allocation-select
              @selected="setAllocations"
            />
          </v-dialog>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="mr-2"
                :disabled="invalid || addingIntake || purchases.length === 0 || loadingMoreLines"
                :loading="addingIntake"
                @click="allocationDialog = true"
                color="primary"
              >
                Select Allocations
              </v-btn>
              <v-btn
                class="ml-2"
                :disabled="!allocations || invalid || addingIntake || purchases.length === 0 || loadingMoreLines"
                :loading="addingIntake"
                @click="addIntake"
                color="primary"
              >
                Save Intake
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AllocationSelect from './components/AllocationSelect'
import axios from 'axios'

export default {
  name: 'CreateIntake',
  components: { AllocationSelect },
  data: () => ({
    notes: '',
    allocationDialog: false,
    allocations: null,
    selectedLot: null,
    loading: true,
    purchases: [],
    reference: null,
    addingIntake: false,
    loadingMoreLines: false
  }),
  computed: {
    ...mapGetters({
      getReceivedPurchases: 'intakeStore/receivedPurchases',
      intakeSetups: 'intakeStore/intakeSetups',
      checkInLines: 'checkIn/checkInLines'
    }),
    // used to add friendlier name rep
    receivedPurchases () {
      return this.getReceivedPurchases.map(p => {
        const ciIds = p.check_ins.map(url => {
          const match = url.match(/(\d*)\/?$/gm)
          return (match.length > 0) ? match[0].replace('/', '') : ''
        }).join(', ')
        const yards = p.yard_names.join(', ')
        p.friendlyName = `${ciIds} ${yards} (${p.id})`
        return p
      })
    },
    allLines () {
      let all = this.intakeSetups
      if (this.checkInLines && this.checkInLines.length > 0) all = [...this.intakeSetups, ...this.checkInLines]
      return all.map(line => {
        const result = {
          id: line.catalog.id,
          name: line.catalog.name,
          units: line.catalog.unit_of_measure,
          value: '0'
        }
        if (line.catalog.converter) {
          result.converterCategory = line.catalog.converter.category
          result.order = line.line_order
        }
        return result
      })
    }
  },
  methods: {
    ...mapActions({
      fetchIntakeSetups: 'intakeStore/fetchIntakeSetups',
      createIntake: 'intakeStore/createIntake',
      fetchCheckInLines: 'checkIn/fetchCheckInLines',
      fetchReceivedPurchases: 'intakeStore/fetchReceivedPurchases',
      sendEmails: 'intakeStore/sendEmails'
    }),
    setAllocations (allocations) {
      this.allocationDialog = false
      this.allocations = allocations
    },
    /**
       * Assign color based on category
       * @param {Object} line
       * @return {String}
       */
    getColorByConverterCategory (key) {
      if (key === 'AM') return '#E27D60'
      else if (key === 'IF') return '#A9AF35'
      else if (key === 'DF') return '#1CE45B'
      else if (key === 'OM') return '#E8A87C'
      else if (key === 'DP') return '#C38D9E'
      else if (key === 'DS') return '#41B3A3'
      else if (key === 'GN') return 'info'
      else if (key === 'BD') return 'accent'
      else return 'primary'
    },
    applyAllocations () {
      let allocations = []
      this.purchases.forEach(purchase => {
        for (const key in this.allocations) {
          const lot = this.allocations[key]
          allocations.push({
            lot_id: lot.id,
            purchase_id: purchase.id,
            converter_type: key
          })
        }
      })
      return axios.post('/api/purchase_lots/dynamic/', allocations)
    },
    /**
       * Add a new intake with the appropriate intake lines
       */
    async addIntake () {
      this.addingIntake = true
      await this.applyAllocations()
      const body = {
        lines: this.allLines.filter(line => line.value !== '0'),
        purchases: this.purchases,
        notes: this.notes
      }
      if (this.reference) body.reference = this.reference
      const id = await this.createIntake(body)
      this.addingIntake = false
      if (id) {
        this.sendEmails(id)
        this.$router.push({ name: 'intake', params: { id } })
      }
    },
    /**
       * Load all data for the intake
       */
    async loadData () {
      try {
        if (this.$route.params.purchase) {
          this.purchases.push(this.$route.params.purchase)
        }
        await this.fetchIntakeSetups()
        await this.fetchReceivedPurchases()
      } catch (err) {
        this.$store.commit('setSnackbarError', 'Failed to load intake requirements', { root: true })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch('purchaseStore/fetchAvailableLots')
    this.loadData()
  }
}
</script>
