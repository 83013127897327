<template>
  <v-container>
    <v-row
      justify="center"
      class="mt-n6"
    >
      <v-textarea
        v-model="notes"
        auto-grow
        two-lines
        @input="modified = true"
      />
      <base-button
        icon-bool
        icon="mdi-close-circle-outline"
        color="error"
        @clicked="$emit('cancel')"
      />
      <base-button
        icon="mdi-plus-circle-outline"
        color="success"
        :disabled="!modified"
        :loading="updating"
        @clicked="updateNotes"
        icon-bool
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EditNotes',
  data: () => ({
    modified: false,
    notes: null
  }),
  props: {
    lotNotes: { type: String, required: false, default: null },
    hedgeNotes: { type: String, required: false, default: null },
    updating: { type: Boolean, required: true }
  },
  methods: {
    updateNotes () {
      let notes = this.notes
      this.$emit('updateNotes', notes)
    }
  },
  mounted () {
    if (this.lotNotes) this.notes = this.lotNotes
    if (this.hedgeNotes) this.notes = this.hedgeNotes
  }
}
</script>
