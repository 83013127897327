<template>
  <text-fab
    v-bind="[$attrs, $props]"
    v-on="$listeners"
  >
    <slot>
      Edit
    </slot>
  </text-fab>
</template>

<script>
export default {
  name: 'FixedTextFab',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    iconText: {
      type: String,
      default: 'mdi-pencil'
    },
    right: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: true
    }
  }
}
</script>
