<!--Represents a line in a v-list for individual catalog entries and is responsible for
updating the vuex store with changes to the catalog entries-->
<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title
        class="caption primary--text"
      >
        {{ line.catalog.name }} <v-icon x-small>
          mdi-open-in-new
        </v-icon>
      </v-list-item-title>
      <v-list-item-subtitle
        class="caption"
      >
        @{{ line.price }}
      </v-list-item-subtitle>
      <p class="caption text--lighten-1">
        $ {{ totalDollars }}
      </p>
    </v-list-item-content>
    <v-text-field
      small
      dense
      required
      outlined
      style="max-width:15em"
      type="number"
      v-model="count"
      :background-color="statusColor"
      :loading="!ready"
      :rules="numField"
      :suffix="line.catalog.unit_of_measure.toLowerCase()"
      @change="fieldChange"
      @keyup="fieldChange"
    />
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CatalogLine',
  props: {
    line: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      ready: true,
      statusColor: '',
      count: this.line.count,
      numField: [v => !isNaN(v) || 'Must be a number'],
      timeout: null
    }
  },
  methods: {
    lineUpdate () {
      this.ready = false
      let payload = { count: this.count }
      this.patchCatalogLine({ catalogLineUrl: this.line.url, payload })
        .then(() => {
          this.statusColor = ''
        })
        .catch(() => {
          this.statusColor = 'error'
          // register a retry attempt
          this.timeout = setTimeout(this.lineUpdate, 5000)
        })
        .finally(() => {
          this.ready = true
        })
    },
    fieldChange () {
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(this.lineUpdate, 3000)
    },
    ...mapActions('checkIn/edit', ['patchCatalogLine'])
  },
  computed: {
    totalDollars () {
      return Math.round(this.line.count * parseFloat(this.line.price))
    }
  }
}
</script>
