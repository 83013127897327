<template>
  <div>
    <v-list-item
      @click="dialog = !dialog"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ catalogLine.catalog.name }}
        </v-list-item-title>
      </v-list-item-content>
      <v-row>
        <v-col
          offset="6"
          cols="6"
        >
          ${{ catalogLine.price }}
        </v-col>
      </v-row>
      <v-list-item-icon>
        <v-icon
          color="error"
        >
          mdi-pencil
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-dialog
      v-model="dialog"
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          Direct Line Edit
        </v-card-title>
        <v-card-subtitle>
          {{ catalogLine.catalog.name }}
          <br>
          {{ Math.round(catalogLine.price) }} (current)
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            :disabled="!dialogReady"
            :loading="!dialogReady"
            :placeholder="String(catalogLine.price)"
            :error="dialogError"
            v-model="dialogValue"
            prepend-icon="mdi-currency-usd"
            type="number"
            @keydown.enter="saveCatalogLinePrice"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveCatalogLinePrice"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PricingDialogCatalog',
  props: {
    catalogLine: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    price: null,
    dialog: false,
    dialogReady: true,
    dialogError: false,
    dialogValue: ''
  }),
  methods: {
    saveCatalogLinePrice () {
      if (!this.dialogValue) {
        this.dialogError = true
        return
      }
      const price = Number(this.dialogValue)
      const payload = { price }
      this.dialogReady = false
      this.patchCatalogLine({ catalogLineUrl: this.catalogLine.url, payload }).then(() => {
        this.dialog = false // close the dialog
        this.dialogError = false // clear error
      }).catch(() => {
        this.dialogError = true
      }).finally(() => {
        this.dialogReady = true
      })
    },
    ...mapActions('checkIn/edit', [
      'patchCatalogLine'
    ])
  }
}
</script>
