var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('create-check-ins'),"message":"Not Authorized to Create Check Ins"}},[_c('v-container',[(_vm.loading)?_c('app-loading-indicator'):(_vm.failedToLoad)?_c('h3',[_vm._v(" Failed to Load ")]):_c('div',{staticClass:"mt-0"},[_c('v-card',{staticClass:"mb-16",attrs:{"flat":"","min-height":"100vh"}},[_c('v-card-text',{staticClass:"text-center"},[_c('cash-disclaimer')],1)],1),_c('v-sheet',{staticClass:"px-4",attrs:{"id":"payment"}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-center"},[_vm._v(" Check Out Shipping and Payment ")]),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"disabled":_vm.dockBuy,"label":"Requires Transit"},model:{value:(_vm.requiresTransit),callback:function ($$v) {_vm.requiresTransit=$$v},expression:"requiresTransit"}}),(_vm.requiresTransit)?_c('span',[_vm._v(" This Check-In "),_c('strong',[_vm._v("does")]),_vm._v(" require transit. Logistics will be notified. ")]):_c('span',[_vm._v(" This Check-In "),_c('strong',[_vm._v("does not")]),_vm._v(" require transit. The current location will be updated to "),_c('strong',[_vm._v(" "+_vm._s(_vm.checkIn.to_location_name)+". ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Payment Type*","items":_vm.$store.getters['getPaymentTypes'],"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":_vm.dockBuy ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Transport Method*","disabled":_vm.dockBuy,"items":_vm.$store.getters['getTransportMethods'],"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.transportMethod),callback:function ($$v) {_vm.transportMethod=$$v},expression:"transportMethod"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":_vm.paymentType && _vm.paymentType === 'UNP' ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Payment Method","items":_vm.$store.getters['getPaymentMethods'],"item-text":"text","item-value":"value","disabled":!_vm.paymentType || _vm.paymentType === 'UNP',"error-messages":errors},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":_vm.paymentType && _vm.paymentType === 'UNP' ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Payment Amount","prepend-icon":"mdi-currency-usd","hint":_vm.checkIn.total_prices ? ("Total Cost: $" + (_vm.checkIn.total_prices.total)) : '',"disabled":!_vm.paymentType || _vm.paymentType === 'UNP',"error-message":errors,"type":"number"},model:{value:(_vm.paymentAmount),callback:function ($$v) {_vm.paymentAmount=$$v},expression:"paymentAmount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Notes (Internal)"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Notes (External)"},model:{value:(_vm.publicNotes),callback:function ($$v) {_vm.publicNotes=$$v},expression:"publicNotes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"External Reference"},model:{value:(_vm.customerRef),callback:function ($$v) {_vm.customerRef=$$v},expression:"customerRef"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Customer Name*","error-messages":errors},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Customer Email","error-messages":errors,"hint":"This will *NOT* send the customer an email"},model:{value:(_vm.customerEmail),callback:function ($$v) {_vm.customerEmail=$$v},expression:"customerEmail"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('h3',[_vm._v(" Customer Signature ")]),_c('v-card',[_c('VueSignaturePad',{ref:"signaturePad",staticStyle:{"background":"lightgrey"},attrs:{"height":"400px","options":{ onEnd: _vm.onEnd }}})],1),(!_vm.finishedSigning)?_c('div',{staticClass:"text-right mt-2"},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.locked,"color":"error"},on:{"click":_vm.clearSignature}},[_vm._v(" Clear ")]),_c('v-btn',{attrs:{"disabled":_vm.emptySignature,"color":"primary"},on:{"click":_vm.finishSigning}},[_vm._v(" Finish Signing ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"ma-4",attrs:{"large":"","loading":_vm.completing,"disabled":invalid || !_vm.finishedSigning,"color":"primary"},on:{"click":_vm.completeCheckIn}},[_vm._v(" Complete Check-In ")])],1)]}}])})],1)],1),_c('edit-dialog',{attrs:{"headline-text":"Signature Confirmation","accept-text":"Accept Signature","inputs-valid":_vm.cashSigned !== null},on:{"accept":_vm.confirmedSignature},model:{value:(_vm.displaySigningDialog),callback:function ($$v) {_vm.displaySigningDialog=$$v},expression:"displaySigningDialog"}},[_c('v-select',{attrs:{"label":"Confirm Signature Type","items":_vm.cashSignedOptions},model:{value:(_vm.cashSigned),callback:function ($$v) {_vm.cashSigned=$$v},expression:"cashSigned"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }