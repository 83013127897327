<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <div class="text-h6">
          Available Inventory
        </div>
        <v-skeleton-loader
          :loading="loading"
          type="table"
        >
          <inventory-box-table
            v-model="availableBoxes"
            :headers="['name', 'contains', 'weight', 'pieces']"
            no-name-link
            @rowClicked="addBox"
          />
          <v-pagination
            v-model="page"
            :length="pagesLength"
            @input="fetchBoxes"
          />
        </v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <div class="text-h6">
          Selected Inventory
        </div>
        <v-skeleton-loader
          :loading="loading"
          type="table"
        >
          <inventory-box-table
            :value="value"
            :headers="['name', 'contains', 'weight', 'pieces']"
            no-name-link
            @rowClicked="removeBox"
          />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fetchInventoryBoxes } from '../../../requests/inventoryBoxes'
import InventoryBoxTable from './InventoryBoxTable.vue'

export default {
  name: 'InventoryBoxSelect',
  components: { InventoryBoxTable },
  props: {
    // Selected Boxes
    value: {
      type: Array,
      required: true
    },
    // typically filtering should be for current location
    searchFilters: {
      type: Object,
      default: Object
    },
    excludeBoxIds: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    availableBoxesSource: [],
    page: 1,
    pagesLength: 0,
    search: '',
    pageSize: 10,
    loading: false
  }),
  computed: {
    availableBoxes () {
      const selected = [...this.value].map(b => b.id)
      return this.availableBoxesSource.filter(b => !this.excludeBoxIds.includes(b.id) && !selected.includes(b.id))
    }
  },
  methods: {
    addBox (box) {
      // this.value.push(box)
      this.$emit('input', [...this.value, box])
    },
    removeBox (box) {
      // this.value = this.value.filter(b => b.id !== box.id)
      this.$emit('input', [...this.value.filter(b => b.id !== box.id)])
    },
    getFilters () {
      return {
        page: this.page,
        page_size: this.pageSize
      }
    },
    async fetchBoxes () {
      this.loading = true
      const params = Object.assign({}, this.getFilters(), this.searchFilters)
      const response = await fetchInventoryBoxes({ params })
      this.availableBoxesSource = response.data.results
      this.count = response.data.count
      this.pagesLength = Math.ceil(response.data.count / this.pageSize)
      this.loading = false
      this.$emit('available', this.availableBoxesSource)
    }
  },
  mounted () {
    this.fetchBoxes()
    this.page = 1
    this.pagesLength = 0
  }
}
</script>
