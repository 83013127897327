<template>
  <v-card>
    <slot>
      <v-card-title
        v-if="title"
        v-html="title"
      />
      <v-card-subtitle
        v-if="subtitle"
        v-html="subtitle"
      />
    </slot>
    <v-row>
      <card-list-item
        :breakpoint-col-val="6"
        :other-col-val="4"
        title="Platinum"
      >
        <span class="pt-color">
          {{ pt | trimDecimals | addCommas }} ctoz
        </span>
      </card-list-item>
      <card-list-item
        :breakpoint-col-val="6"
        :other-col-val="4"
        title="Palladium"
      >
        <span class="pd-color">
          {{ pd | trimDecimals | addCommas }} ctoz
        </span>
      </card-list-item>
      <card-list-item
        :breakpoint-col-val="6"
        :other-col-val="4"
        title="Rhodium"
      >
        <span class="rh-color">
          {{ rh | trimDecimals | addCommas }} ctoz
        </span>
      </card-list-item>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'PpmValueCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    pt: {
      type: [String, Number],
      default: 0
    },
    pd: {
      type: [String, Number],
      default: 0
    },
    rh: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>
