<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        small
        v-bind="attrs"
        v-on="on"
      >
        Clone
      </v-btn>
    </template>
    <v-toolbar dark>
      <v-toolbar-title>
        Clone Purchase
      </v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-text>
        Please select check ins you would like to transfer in the clone.
        Clicking Proceed will commit the changes and redirect you to the new purchase.
        <v-checkbox
          v-for="ci in purchase.check_ins"
          :key="ci.url"
          :label="ci.id.toString()"
          @change="cbSelected(ci.url, $event)"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!$store.getters.can('clone-purchase')"
          color="success"
          text
          @click="clonePurchase"
        >
          Proceed
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PurchaseSplitDialog',
  props: {
    purchase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      selected: []
    }
  },
  methods: {
    cbSelected (ci, event) {
      if (event) {
        this.selected = [...this.selected, ci]
      } else {
        this.selected = this.selected.filter(s => s !== ci)
      }
    },
    async clonePurchase () {
      this.dialog = false
      const response = await axios.post(this.purchase.url + 'clone/')
      if (response.data) {
        const newPurchase = response.data
        this.$store.commit('setSnackbarSuccess', 'New purchase was created #' + newPurchase.id)
        await Promise.all(this.selected.map(ciUrl => axios.patch(ciUrl, { purchase: newPurchase.url })))
        this.$router.push({ name: 'purchase', params: { id: newPurchase.id } })
      } else {
        this.$store.commit('setSnackbarError', 'Purchase creation failed')
      }
    }
  }
}
</script>
