<!--Represent a v-list item that displays a converter line also responsible for making updates to the vue store on
a per line basis-->
<template>
  <!-- Pricing Dialog -->
  <v-dialog
    :value="value"
    max-width="300px"
    @input="closingDialog"
  >
    <v-card>
      <v-card-title>
        {{ line.converter.code }} ({{ line.converter.category }})
      </v-card-title>
      <v-card-text
        class="font-weight-black"
      >
        <v-row>
          <check-in-input
            icon="mdi-circle-slice-8"
            count-field="fl_count"
            :converter-line-url="line.url"
            :initial-count="line.fl_count"
          />
          <check-in-input
            icon="mdi-circle-slice-6"
            count-field="tq_count"
            :converter-line-url="line.url"
            :initial-count="line.tq_count"
          />
          <check-in-input
            icon="mdi-circle-slice-4"
            count-field="hl_count"
            :converter-line-url="line.url"
            :initial-count="line.hl_count"
          />
          <check-in-input
            icon="mdi-circle-slice-2"
            count-field="qr_count"
            :converter-line-url="line.url"
            :initial-count="line.qr_count"
          />
        </v-row>
        <v-row dense>
          <v-col>value</v-col>
          <v-col class="text-right">
            {{ netUnits.toFixed(2) }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>pieces</v-col>
          <v-col class="text-right">
            {{ grossUnits }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>each</v-col>
          <v-col class="text-right">
            ${{ line.price }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>total</v-col>
          <v-col class="text-right">
            ${{ totalDollars }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closingDialog"
        >
          DONE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CheckInInput from './CheckInInput'

export default {
  name: 'ConverterLineDialog',
  components: {
    CheckInInput
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    line: {
      type: Object,
      required: true
    }
  },
  computed: {
    netUnits () {
      const total =
        this.line.qr_count * 0.25 +
        this.line.hl_count * 0.5 +
        this.line.tq_count * 0.75 +
        this.line.fl_count
      return total
    },
    grossUnits () {
      const total =
        this.line.qr_count +
        this.line.hl_count +
        this.line.tq_count +
        this.line.fl_count
      return total
    },
    totalDollars () {
      const total =
        this.line.qr_count * 0.25 +
        this.line.hl_count * 0.5 +
        this.line.tq_count * 0.75 +
        this.line.fl_count
      return Math.round(total * parseFloat(this.line.price))
    }
  },
  methods: {
    closingDialog () {
      this.$emit('input', false)
    }
  }
}
</script>
