import axios from 'axios'
import store from '@/store'

class CheckinLinesTableBuilder {
  #headers = ['Description', 'Price', 'Pieces', 'Total']
  #widths = [125, '*', '*', 100]
  showFullValue = false
  showOverallAverage = false // determines if converter total average shown

  constructor (invoice) {
    this.invoice = invoice
    this.showFullValue = invoice.formatting?.show_full_value
    this.showOverallAverage = invoice.formatting?.show_overall_average
    if (this.showFullValue) {
      this.#headers.splice(3, 0, 'Value')
      this.#widths.splice(2, 0, '*')
    }

    this.CURRENCY_USD = new Intl.NumberFormat('en-US',
      { style: 'currency', currency: 'USD' }
    )
  }

  header () {
    return {
      style: 'row',
      stack: [
        {
          style: 'subheading',
          margin: [0, 0, 0, 10],
          text: this.invoice.yard_name
        },
        {
          margin: [0, 0, 0, 10],
          text: `#${this.invoice.id}`
        },
        { text: this.invoice.yard_address }
      ]
    }
  }

  // Build invoice payment info data if it exists on the Check-In
  paymentInfo () {
    if (!this.invoice.payment_type) return {}
    return {
      style: 'row',
      stack: [
        { text: `Contact: ${this.invoice.contact}` },
        { text: `Type: ${this.invoice.payment_type}` },
        { text: `Payment: ${this.invoice.payment_amount} ${(this.invoice.payment_method)}` }
      ]
    }
  }

  purchaseLines () {
    return this.__tableFormat(this.__converterLines().concat(this.__otherLines()))
  }

  // Build Check-In table footer
  footer () {
    const footerData = this.invoice.footer.concat(this.invoice.footer_totals)
    const formatted = footerData.map(line => {
      let lineShowAverage = this.__showAverage(line.identity)
      let { item, value_average, pieces, values, average, total } = line /* eslint-disable-line camelcase */
      let row = []
      row.push(item) // always item
      const r2 = !this.showFullValue && lineShowAverage
      row.push(r2 ? value_average : '') /* eslint-disable-line camelcase */ // value_average or blank
      row.push((this.showFullValue ? pieces : values) || '') // show pieces or value
      if (this.showFullValue) {
        row.push(lineShowAverage ? average : '') // show None. average or Blank
      }
      row.push(this.__currencyFormat(total)) // always total
      return row
    })
    return this.__tableFormat(formatted)
  }

  __currencyFormat (value) {
    const v = Number(value)
    if (isNaN(v)) return value
    if (value) return this.CURRENCY_USD.format(Number(value))
    return this.CURRENCY_USD.format(0)
  }

  __tableFormat (body) {
    return {
      style: 'table',
      table: {
        body,
        widths: this.#widths
      }
    }
  }

  // Build Check-In lines table rows.
  // If showFullValue is true for this Check-In, append a table definition for its 'value'.
  // Append a table row for each breakdown line.
  __converterLines () {
    return this.invoice.lines.reduce((acc, line) => {
      if (line.pieces === 0) return acc
      let lineData = [line.item, line.price, line.pieces, line.total]
      if (this.showFullValue) {
        lineData.splice(3, 0, line.value)
        const breakdownData = line.breakdown.map(bd => ['', bd.name, bd.pieces, bd.value, ''])
        acc.push(lineData, ...breakdownData)
      } else {
        acc.push(lineData)
      }
      return acc
    }, [this.#headers])
  }

  /**
   * Format a Check-In's other line data for printing/downloading with pdfMake
   * @param { Object } invoice Check-In invoice object
   * @return { Object } Check-In line data in pdfmake table format
   */
  __otherLines () {
    // if showFullValue is true for the Check-in, append a table definition for its 'value'.
    return this.invoice.other_lines.reduce((acc, line) => {
      if (line.pieces === 0) return acc
      let lineData = [line.item, line.price, line.pieces, line.total]
      if (this.showFullValue) {
        lineData.splice(3, 0, line.value)
      }
      acc.push(lineData)
      return acc
    }, [])
  }

  /**
   * Determines whether we display 'average value' from a Check-In footer
   *
   * @param { String } identity Check-in footer identity value
   * @returns { Boolean } Whether to display the average value from a footer
   */
  __showAverage (identity) {
    if (this.showOverallAverage && identity === 'converter-total') return true
    return !['grand-total', 'other-total', 'converter-total'].includes(identity)
  }
}

/**
 * Build a PdfMake document-definition-object for a Check-In invoice receipt.
 * Used to build and print/download a PDF from the invoice data
 *
 * @param { Number } id Id of the Check-in to generate a receipt for
 * @returns { Object } Checkin invoice receipt data in pdfmake document-definition-object form
 */
async function checkInReceipt (id) {
  try {
    const response = await axios.get(`/api/check_ins/${id}/invoice/`)
    const template = new CheckinLinesTableBuilder(response.data)
    return [
      template.header(),
      template.purchaseLines(),
      template.footer(),
      template.paymentInfo()
    ]
  } catch (err) {
    store.commit('setSnackbarError', `An error occurred while trying to fetch invoice`)
  }
}

export { checkInReceipt }
