<!--Represent a v-list item that displays a converter line also responsible for making updates to the vue store on
a per line basis-->
<template>
  <div>
    <v-list-item
      two-line
      dense
      @click.stop="dialog = !dialog"
    >
      <v-list-item-avatar
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <span class="caption">{{ line.lineNumber }}</span>
        <v-icon
          color="primary"
        >
          mdi-pencil-outline
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-list-item-title
              class="primary--text"
            >
              <span
                v-if="$vuetify.breakpoint.xsOnly"
                class="caption"
              >
                {{ line.lineNumber }}
              </span>
              {{ line.converter.code }} {{ line.converter.category }} ({{ line.converter.converter_group_name }})
            </v-list-item-title>
            <br>
            ${{ totalDollars }} @ {{ line.price }}/ea
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="text-right pa-0 d-flex justify-end"
          >
            <div
              @click.stop=""
              :class="fabWrapperStyles"
            >
              <check-in-fab
                icon="mdi-circle-slice-2"
                :converter-line-url="line.url"
                count-field="qr_count"
                :initial-count="line.qr_count"
                :is-small="true"
              />
              <check-in-fab
                :converter-line-url="line.url"
                icon="mdi-circle-slice-4"
                :initial-count="line.hl_count"
                count-field="hl_count"
                :is-small="true"
              />
              <check-in-fab
                :converter-line-url="line.url"
                icon="mdi-circle-slice-6"
                :initial-count="line.tq_count"
                count-field="tq_count"
                :is-small="true"
              />
              <check-in-fab
                :converter-line-url="line.url"
                icon="mdi-circle-slice-8"
                :initial-count="line.fl_count"
                count-field="fl_count"
              />
            </div>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <!-- Pricing Dialog -->
    <converter-line-dialog
      v-if="dialog"
      v-model="dialog"
      :line="line"
    />
  </div>
</template>

<script>
import CheckInFab from './CheckInFab'
import ConverterLineDialog from './ConverterLineDialog'

export default {
  name: 'ConverterLine',
  components: {
    ConverterLineDialog,
    CheckInFab
  },
  props: {
    line: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    fabWrapperStyles () {
      return {
        'd-flex': true,
        'justify-end': true,
        'pa-3': true,
        'border-box': true,
        'left-border': this.$vuetify.breakpoint.smAndUp,
        'top-border': !this.$vuetify.breakpoint.smAndUp
      }
    },
    netUnits () {
      const total =
        this.line.qr_count * 0.25 +
        this.line.hl_count * 0.5 +
        this.line.tq_count * 0.75 +
        this.line.fl_count
      return total
    },
    grossUnits () {
      const total =
        this.line.qr_count +
        this.line.hl_count +
        this.line.tq_count +
        this.line.fl_count
      return total
    },
    totalDollars () {
      const total =
        this.line.qr_count * 0.25 +
        this.line.hl_count * 0.5 +
        this.line.tq_count * 0.75 +
        this.line.fl_count
      return Math.round(total * parseFloat(this.line.price))
    }
  }
}
</script>

<style scoped>
.btn-box {
  height: 100%;
  width: fit-content;
}

.left-border {
  border-left: solid #bdbdbd 1px
}

.top-border {
  border-top: solid #bdbdbd 1px
}
</style>
