<template>
  <v-row>
    <v-col>
      <v-stepper
        alt-labels
        :value="status"
        class="elevation-0"
      >
        <v-stepper-header>
          <template v-for="(step, index) in availStatuses">
            <v-divider
              v-if="index"
              :key="index"
            />
            <v-stepper-step
              :key="step.value"
              :step="step.value"
              :complete="step.complete"
            >
              <div class="text-center">
                {{ step.text }}
              </div>
            </v-stepper-step>
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StatusStepper',
  props: {
    status: { type: String, required: true },
    statuses: { type: Array, required: true }
  },
  computed: {
    availStatuses () {
      let complete = true
      let steps = this.statuses.map(status => {
        if (status.value === this.status) complete = false
        let formatted = Object.assign(status)
        formatted.complete = complete
        return formatted
      })
      return steps
    }
  }
}
</script>
