<!--Was adding other converter lines.... but that actually didn't work because
it would never check for changes in the selected purchase. I'm saying it's trash-->
<template>
  <v-container
    fluid
    class="mb-16"
  >
    <url-breadcrumbs
      :items="[
        ['Intake List', { name: 'intake-list' }],
        ['Create Intake Using Boxes', { name: 'create-intake-using-boxes' }]
      ]"
    />
    <base-title title="Intake from Inventory" />
    <v-skeleton-loader
      type="table@2,card"
      :loading="loading"
    >
      <Validation-observer
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-form
          :value="!invalid"
          @submit.stop.prevent="createIntake"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h2 class="text-h6">
                Beginning Inventory
              </h2>
              <p>
                These entries will be removed from inventory if not included in final inventory.
              </p>
              <v-card>
                <v-card-text>
                  <inventory-box-table
                    :value="inputBoxes"
                    :headers="['name', 'contains', 'catalog_name', 'container_text', 'weight', 'pieces']"
                    no-name-link
                    @rowClicked="addInputBoxToOutput"
                  />
                  <select-inventory
                    :value="inputBoxes"
                    @input="setInputBoxes"
                  >
                    <template v-slot:activator="{ open }">
                      <v-btn
                        :color="step === 'select-inventory' ? 'primary' : ''"
                        fab
                        bottom
                        right
                        absolute
                        @click="open"
                      >
                        <v-icon>
                          mdi-plus-minus-variant
                        </v-icon>
                      </v-btn>
                    </template>
                  </select-inventory>
                  <div class="d-flex justify-space-between">
                    <p>
                      <em>
                        Click on a row to add it directly to final inventory or click
                        <a
                          @click.prevent="$store.dispatch('intakeBoxStore/addAllInputBoxes')"
                        >
                          here
                        </a>
                        to add all.
                      </em>
                    </p>
                    <div>
                      <span>{{ totalWeightInput | formatNumber }} lbs</span>&nbsp;/&nbsp;
                      <span>{{ totalPiecesInput | formatNumber }} pcs</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
            >
              <h2 class="text-h6">
                Final Inventory
              </h2>
              <p>
                Add lines by clicking on a beginning inventory line, adding a new box, or searching for an existing box
                from inventory.
              </p>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="outputBoxes"
                  >
                    <template
                      v-slot:top
                    >
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <search-autocomplete
                            v-model="searched"
                            label="Search for Existing Box"
                            api-path="/api/inventory_boxes/"
                            clearable
                            :format-entry="x => Object.assign({ friendlyName: x.name + ' ' + x.contains + ' ' + x.catalog_name }, x)"
                          />
                        </div>
                        <div class="mt-2 ml-4">
                          <v-btn
                            :color="step === 'add-boxes' ? 'primary' : ''"
                            @click="addBox"
                          >
                            {{ searched ? 'Add Box' : 'New Box' }}
                          </v-btn>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                      {{ item.name }}
                      <br>
                      <a
                        class="caption"
                        @click="removeOutputBox(item)"
                      >
                        remove
                      </a>
                    </template>
                    <template v-slot:item.catalog="{ item }">
                      <!--start with the line pieces-->
                      <!--start with the and display total pieces-->
                      <!--get the total pieces-->
                      <div class="py-2" />
                      <div>
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            :value="item.catalog"
                            :items="catalogOptions"
                            label="Material"
                            item-text="name"
                            item-value="url"
                            return-object
                            :error-messages="errors"
                            dense
                            @change="selectCatalog(item, $event)"
                          />
                        </validation-provider>
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            :value="item.container"
                            label="Container"
                            :items="containerTypes"
                            :error-messages="errors"
                            dense
                            @change="selectContainer(item, $event)"
                          />
                        </validation-provider>
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            dense
                            label="lot"
                            :value="item.lot"
                            :items="availableLots"
                            item-text="name"
                            item-value="url"
                            return-object
                            required
                            placeholder="Select a Lot"
                            :error-messages="errors"
                            @change="setLot(item, $event)"
                          />
                        </validation-provider>
                      </div>
                    </template>
                    <template v-slot:item.weight="{ item }">
                      <rules-text
                        validation-rules="required|max_value:99999999|decimal:2"
                        label="Gross"
                        :value="item.weight"
                        hint="in pounds"
                        type="number"
                        dense
                        @input="setWeight(item, $event)"
                      />
                    </template>
                    <template v-slot:item.net="{ item }">
                      <rules-text
                        validation-rules="required|max_value:99999999|decimal:2|min_value:0"
                        label="Tare"
                        :value="item.tare"
                        hint="in pounds"
                        type="number"
                        dense
                        @input="setTare(item, $event)"
                      />
                    </template>
                    <template v-slot:item.tare="{ item }">
                      <rules-text
                        validation-rules="required|max_value:99999999|decimal:2|min_value:0"
                        label="Net"
                        :value="item.net"
                        hint="in pounds"
                        type="number"
                        readonly
                        dense
                      />
                    </template>
                    <template v-slot:item.pieces="{ item }">
                      <!--get the total pieces-->
                      <rules-text
                        validation-rules="required|max_value:99999999|decimal:0|min_value:0"
                        label="Pieces"
                        :value="item.pieces"
                        type="number"
                        @input="setPieces(item, $event)"
                      />
                    </template>
                    <template v-slot:body.append>
                      <tr class="text-right">
                        <td>
                          {{ totalBoxes }} Boxes
                        </td>
                        <td />
                        <td>
                          {{ totalWeight | formatNumber }} lbs
                        </td>
                        <td>
                          {{ totalTare | formatNumber }} lbs
                        </td>
                        <td>
                          {{ totalNet | formatNumber }} lbs
                        </td>
                        <td>
                          {{ totalPieces | formatNumber }} pc
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <h2 class="text-h6">
            Other Details
          </h2>
          <v-card>
            <v-card-text>
              <v-textarea
                v-model="notes"
                label="Notes"
              />
              <div>
                <h4 class="font-weight-bold">
                  The resulting inventory will have the following check ins linked.
                </h4>
                <ul>
                  <li
                    v-for="(ci, index) in containsInput"
                    :key="index"
                  >
                    {{ ci.id + ' ' + ci.yard_name }}
                  </li>
                </ul>
              </div>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :value="location"
                  label="Location"
                  item-text="name"
                  item-value="url"
                  :items="locations"
                  @input="setLocation"
                />
              </validation-provider>
            </v-card-text>
          </v-card>
          <text-fab
            :disabled="invalid || outputBoxes.length === 0"
            :color="step === 'save-intake' ? 'primary' : ''"
            bottom
            right
            fixed
            icon-text="mdi-check"
            @click="showCreateDialog = !showCreateDialog"
          >
            Complete Intake
          </text-fab>
          <create-intake-confirmation
            v-model="showCreateDialog"
          />
        </v-form>
      </Validation-observer>
    </v-skeleton-loader>
  </v-container>
</template>

<script>

import InventoryBoxTable from '../Warehouse/components/InventoryBoxTable.vue'
import SelectInventory from './components/SelectInventory.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import SearchAutocomplete from '../../base/components/SearchAutocomplete.vue'
import RulesText from '../../components/ValidationFields/RulesText.vue'
import axios from 'axios'
import CreateIntakeConfirmation from './components/CreateIntakeConfirmation.vue'
import { fetchInventoryLocations } from '../../requests/inventoryBoxes'

export default {
  name: 'CreateIntakeUsingBoxes',
  components: { CreateIntakeConfirmation, RulesText, SearchAutocomplete, SelectInventory, InventoryBoxTable },
  data: () => ({
    loading: false,
    showCreateDialog: false,
    availableLots: [],
    searched: null,
    unsaved: true,
    notes: '',
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Details', value: 'catalog', sortable: false },
      { text: 'Gross', value: 'weight', align: 'right', sortable: false },
      { text: 'Net', value: 'net', align: 'right', sortable: false },
      { text: 'Tare', value: 'tare', align: 'right', sortable: false },
      { text: 'Pieces', value: 'pieces', align: 'right', sortable: false }
    ]
  }),
  computed: {
    containerTypes () {
      return this.$store.getters['getContainerTypes']
    },
    ...mapState('intakeBoxStore', [
      'outputBoxes',
      'inputBoxes',
      'location',
      'locations'
    ]),
    ...mapGetters('intakeBoxStore', [
      'containsInput',
      'catalogOptions',
      'totalBoxes',
      'totalWeight',
      'totalPieces',
      'step',
      'totalWeightInput',
      'totalPiecesInput',
      'totalTare',
      'totalNet'
    ])
  },
  methods: {
    ...mapMutations('intakeBoxStore', [
      'setInputBoxes',
      'addOutputBox',
      'removeOutputBox',
      'updateBox',
      'setLocations',
      'setLocation'
    ]),
    setLot (box, value) {
      box.lot = value.url
      this.updateBox(box)
    },
    selectCatalog (box, value) {
      box.catalog = value.url
      box.catalogName = value.name
      this.updateBox(box)
    },
    selectContainer (box, value) {
      box.container = value
      this.updateBox(box)
    },
    setWeight (box, value) {
      box.weight = value
      this.updateBox(box)
    },
    setTare (box, value) {
      box.tare = value
      this.updateBox(box)
    },
    setPieces (box, value) {
      box.pieces = value
      this.updateBox(box)
    },
    addInputBoxToOutput (box) {
      this.addOutputBox(box)
    },
    addBox () {
      this.addOutputBox(this.searched)
      this.searched = null
    },
    async fetchAvailableLots () {
      const r = await axios.get('/api/lots/?status__ne=CO&page_size=0&fields=name,url,id')
      if (r.data) {
        this.availableLots = r.data
      } else {
        this.$store.commit('setSnackbarError', 'Failed to retrieve available lots')
      }
    },
    async fetchLocations () {
      const r = await fetchInventoryLocations()
      if (r.data) {
        this.setLocations((r.data))
      } else {
        this.$store.commit('setSnackbarError', 'Failed to retrieve available locations')
      }
    }
  },
  mounted () {
    this.loading = true
    Promise.all([
      this.$store.dispatch('fetchConfig'),
      this.$store.dispatch('intakeBoxStore/fetchCatalogOptions'),
      this.fetchAvailableLots(),
      this.fetchLocations()
    ]).finally(() => {
      this.loading = false
    })
  }
}
</script>
