<template>
  <unauthorized
    :authorized="$store.getters.can('view-purchase')"
    message="Unauthorized to view Price Quoting Page"
  >
    <v-container>
      <base-title title="Price Quoting" />
      <!--      <span-->
      <!--        v-if="$store.getters.can('view-markets')"-->
      <!--      >-->
      <!--        <v-skeleton-loader-->
      <!--          type="card"-->
      <!--          v-if="loading"-->
      <!--        />-->
      <!--        <v-card-->
      <!--          class="text-center"-->
      <!--          v-else-if="market"-->
      <!--        >-->
      <!--          <v-card-text>-->
      <!--            PT: {{ market.pt_market }} /-->
      <!--            PD: {{ market.pd_market }} /-->
      <!--            RH: {{ market.rh_market }}-->
      <!--          </v-card-text>-->
      <!--          <v-card-text>-->
      <!--            Last updated {{ new Date(market.created_at).toLocaleTimeString() }} on {{ market.created_at | formatDate }}-->
      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--        <br>-->
      <!--      </span>-->
      <v-card
        class="d-print-none my-4"
      >
        <v-card-text>
          <p>
            It is strongly recommended that you <strong>DO NOT GUARANTEE</strong> a quote price. The pricing is subject
            to change due to fluctuation in the market and the quoted margin.
            This being the case we do <strong>NOT RECOMMENDED</strong> that you provide customers with a copy of quote
            pricing to prevent setting the wrong expectations and prevent comparision from one buy to another.
          </p>
          <p>
            Price quoting is automatically updated as you adjust the percentage. Although we do not provide a way to
            save spot pricing. If you need to save pricing for later you may consider opening a purchase. Alternatively
            if you wish to save the pricing for your own reference this page has been formatted for printing.
          </p>
          <v-text-field
            v-model="margin"
            label="percentage of spread"
            type="number"
            hint="Spread should not fall below 10"
            :loading="loading"
            append-outer-icon="mdi-percent-outline"
            @input="updateConverterPricing"
          />
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <v-card v-else>
        <!--        <paginated-autocomplete-->
        <!--          class="px-4"-->
        <!--          label="Reprice For Yard"-->
        <!--          v-model="yard"-->
        <!--          item-text="name"-->
        <!--          item-value="id"-->
        <!--          base-url="/api/yards/"-->
        <!--          @input="updateConverterPricing"-->
        <!--        />-->
        <v-list>
          <v-list-item
            v-for="(line, index) in lines"
            :key="index"
            two-line
            dense
          >
            <v-list-item-content>
              <v-list-item-title>{{ line.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="line.converterUrl && pricing[line.converterUrl]">
                  {{ pricing[line.converterUrl] | filterNA | trimDecimals | addCommas | dollarSign }}
                </div>
                <div v-else>
                  {{ 0 | filterNA | trimDecimals | addCommas | dollarSign }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'PriceQuote',
  computed: {
    ...mapGetters({
      yards: 'yardStore/yards',
      setupLines: 'checkIn/checkInSetup',
      market: 'marketRate/get'
    })
  },
  data: () => ({
    loading: true,
    loadingPricing: true,
    yard: null,
    prevYard: '',
    lines: [],
    pricing: [],
    margin: 10
  }),
  methods: {
    ...mapActions({
      fetchMarketRate: 'marketRate/fetch',
      fetchSetup: 'checkIn/fetchSetup'
    }),
    /**
     * Update the pricing for converters based on yard changes
     */
    async updateConverterPricing () {
      this.loadingPricing = true

      // load the lines
      this.lines = this.setupLines.map(line => ({
        name: line.catalog.name,
        converterId: line.catalog && line.catalog.converter ? line.catalog.converter.id : null,
        converterUrl: line.catalog && line.catalog.converter ? line.catalog.converter.url : null
      }))

      // fetch pricing
      let params = {
        ids: this.lines.map(l => l.converterId).filter(x => x).join(',')
      }
      if (this.yard) params.yard = this.yard
      if (this.margin > 0) params.spread = this.margin
      const results = await axios.get('/api/converters/price_many/', { params })

      // set in json object
      this.pricing = results.data.reduce((carry, priceLine) => {
        carry[priceLine.url] = priceLine.price
        return carry
      }, {})
      this.loadingPricing = false
    }
  },
  async mounted () {
    await this.fetchSetup()
    this.loading = false
    await this.updateConverterPricing()
    this.loadingPricing = false
  }
}
</script>
