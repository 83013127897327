<template>
  <v-card class="my-4">
    <v-card-title>
      Select Intended Lot
      <info-tooltip>
        Selecting a lot helps provide additional information to the coordinator
      </info-tooltip>
    </v-card-title>
    <v-card-text>
      <paginated-autocomplete
        label="Select Lot"
        :value="checkIn.intended_lot"
        item-text="name"
        item-value="url"
        base-url="/api/lots/"
        :search-params="{ status__ne: 'CO', outbound_transit__isnull: true }"
        @input="setIntendedLot"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SelectIntendedLot',
  props: {
    checkIn: {
      type: Object,
      required: true
    }
  },
  methods: {
    setIntendedLot (lotUrl) {
      axios.patch(this.checkIn.url, { intended_lot: lotUrl || null })
    }
  }
}
</script>
