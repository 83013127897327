<!--
Represents a single piece count row for a purchase
-->
<template>
  <tr
    @click="beginEdit"
    :style="$store.getters.can('create-purchase-lot') ? 'cursor: pointer' : null"
  >
    <td class="text-uppercase">
      {{ name }}
    </td>
    <td v-if="!edit">
      <router-link
        v-if="selectedLot"
        :to="{ name: 'lot', params: { id: selectedLot.id }}"
      >
        {{ selectedLot.name }}
      </router-link>
      <div
        class="error--text"
        v-else
      >
        not linked
      </div>
    </td>
    <td v-else>
      <v-row>
        <v-autocomplete
          v-model="selectedLot"
          :items="availLots"
          item-text="name"
          item-value="url"
          return-object
          placeholder="Select a Lot"
          clearable
        />
        <v-btn
          class="mt-3"
          color="red"
          icon
          fab
          small
          @click.stop="edit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          class="mt-3"
          color="green"
          icon
          fab
          small
          @click.stop="saveEdit"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-row>
    </td>
    <td class="text-right">
      {{ count }}
    </td>
    <td
      class="text-right"
    >
      {{ intakeCount }}
    </td>
  </tr>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PieceCountsRow',
  props: {
    type: { type: String, required: true },
    name: { type: String, required: true },
    purchaseLot: { type: Object, default: null },
    count: { type: Number, required: true },
    intakeCount: { type: Number, required: false, default: null }
  },
  data: function () {
    return {
      edit: false,
      selectedLot: null
    }
  },
  computed: {
    ...mapGetters({
      availLots: 'purchaseStore/pieceCountLotOptions'
    })
  },
  methods: {
    ...mapActions('purchaseStore', [
      'updateSinglePurchaseLot',
      'createSinglePurchaseLot',
      'removeSinglePurchaseLot'
    ]),
    beginEdit () {
      if (this.$store.getters.can('create-purchase-lot')) {
        this.edit = true
      }
    },
    saveEdit () {
      this.edit = false
      if (this.purchaseLot) {
        this.updatePurchaseLot()
      } else {
        this.createPurchaseLot()
      }
    },
    /**
     * Update the existing purchase lot based on the selected lot
     */
    updatePurchaseLot () {
      // if they are the same do nothing
      if (this.selectedLot && (this.selectedLot.id === this.purchaseLot.lot_id)) return
      if (this.selectedLot) {
        this.updateSinglePurchaseLot({
          url: this.purchaseLot.url,
          lotId: this.selectedLot.id,
          converterType: this.type
        })
      } else {
        this.removeSinglePurchaseLot({ url: this.purchaseLot.url })
      }
    },
    /**
     * Create a new purchase lot entry
     */
    createPurchaseLot () {
      if (!this.selectedLot) return
      this.createSinglePurchaseLot({
        lotId: this.selectedLot.id,
        converterType: this.type
      })
    }
  },
  mounted () {
    if (this.purchaseLot) {
      const found = this.availLots.find(x => x.id === this.purchaseLot.lot_id)
      if (found) this.selectedLot = found
    }
  }
}
</script>
