<template>
  <v-dialog
    :value="value"
    max-width="400"
    @input="$emit('input', false)"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title class="headline">
        Create Check In
      </v-card-title>
      <v-card-text>
        <paginated-autocomplete
          v-model="yard"
          label="Yard Select"
          base-url="/api/yards/"
          item-value="url"
          :search-params="{ status: 'GS' }"
          :item-text="x => `${x.name} (${x.city || '--'}, ${x.state || '--'})`"
        />
        <h3 class="text--primary">
          <span v-if="yard">
            {{ yard.friendlyName }}
          </span>
        </h3>
        <br>
        <v-select
          v-model="type"
          :items="statuses"
          item-value="value"
          item-text="text"
          label="Check In Type"
        />
        <template v-if="type === 'PR'">
          Create a Preliminary Check In by filling in the estimated fields below.
          <v-text-field
            label="Pieces (number)"
            v-model="preliminaryPieces"
            type="number"
          />
          <v-text-field
            label="Weight (number)"
            v-model="preliminaryWeight"
            type="number"
          />
          <v-text-field
            label="Skid Count (number)"
            v-model="preliminarySkidCount"
            type="number"
          />
          <v-text-field
            prepend-inner-icon="mdi-currency-usd"
            label="Quoted Average"
            v-model="preliminaryUnitRate"
            type="number"
          />
          <v-switch
            label="Send emails on create"
            v-model="sendEmailsImmediate"
          />
        </template>
        <template v-if="type === 'OP'">
          Open a standard check in to be completed now. You may return to opened check ins through the check in page.
        </template>
        <v-select
          label="To be delivered to*"
          v-model="requiresTransitTo"
          required
          item-text="name"
          return-object
          :items="divisions.filter(d => !d.is_inactive)"
        />
        <v-switch
          label="Requires Transit"
          v-model="requiresTransit"
        />
        <span class="caption">
          Requiring transit creates a task for to create inbound transit
        </span>
        <v-textarea
          label="Notes (Internal)"
          v-model="notes"
        />
        <v-textarea
          label="Notes (External)"
          v-model="publicNotes"
        />
        <v-select
          v-model="currency"
          :items="configCurrencies"
          :loading="configLoading"
          item-value="value"
          item-text="text"
          label="Currency"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!createReady"
          @click="createCheckIn"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import PaginatedAutocomplete from '@/base/components/PaginatedAutocomplete'

export default {
  name: 'CheckInCreateDialog',
  components: { PaginatedAutocomplete },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    purchase: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    sendEmailsImmediate: true,
    yard: null,
    loading: false,
    type: 'OP',
    preliminaryPieces: null,
    preliminaryWeight: null,
    preliminarySkidCount: null,
    preliminaryUnitRate: null,
    requiresTransit: false,
    requiresTransitTo: null,
    notes: '',
    publicNotes: '',
    searchTimeout: null,
    currency: null,
    currencyOptions: [],
    configLoading: false
  }),
  computed: {
    ...mapGetters({
      divisions: 'divisionStore/divisions'
    }),
    ...mapGetters([
      'configCurrencies',
      'configCurrenciesDefault'
    ]),
    statuses () {
      let options = []
      if (this.$store.getters.can('create-preliminary-check-in')) options.push({ value: 'PR', text: 'Preliminary' })
      if (this.$store.getters.can('create-open-check-in')) options.push({ value: 'OP', text: 'Open' })
      return options
    },
    createReady () {
      return !!this.yard && !!this.requiresTransitTo
    }
  },
  methods: {
    ...mapActions({
      fetchPurchase: 'purchaseStore/fetchPurchase',
      fetchDivisions: 'divisionStore/fetchDivisions'
    }),
    async fetchConfig () {
      this.configLoading = true
      await this.$store.dispatch('fetchConfig')
      this.configLoading = false
      if (this.currency === null &&
        this.$store.getters.configCurrenciesDefault &&
        this.$store.getters.configCurrenciesDefault.value
      ) {
        this.currency = this.$store.getters.configCurrenciesDefault.value
      }
    },
    async createCheckIn () {
      this.$emit('input', false)
      let body = {
        purchase: this.purchase.url,
        status: this.type,
        notes: this.notes,
        public_notes: this.publicNotes,
        yard: this.yard,
        currency: this.currency
      }
      body['preliminary_pieces'] = this.preliminaryPieces
      body['preliminary_weight'] = this.preliminaryWeight
      body['preliminary_skid_count'] = this.preliminarySkidCount
      body['preliminary_unit_rate'] = this.preliminaryUnitRate
      body['requires_transit'] = this.requiresTransit
      body['requires_transit_to'] = this.requiresTransitTo.yard
      if (this.requiresTransitTo.logistics_location) {
        body['to_location'] = this.requiresTransitTo.logistics_location.url
      }
      const id = await this.$store.dispatch('checkIn/createCheckIn', body)
      if (!id) {
        this.$store.commit('setSnackbarError', 'Something went wrong creating the check in')
        return
      }

      if (this.type === 'OP') {
        return this.$router.push({ name: 'edit-checkin', params: { id } })
      }

      if (this.type === 'PR') {
        let promises = []
        // Generate initial transit
        promises.push(axios.post(`/api/check_ins/${id}/generate_initial_transit/`))
        // Send emails
        if (this.sendEmailsImmediate) {
          promises.push(axios.post(`/api/check_ins/${id}/send_emails/`))
        }
        await Promise.all(promises)
      }
      return this.$router.push({ name: 'edit-checkin', params: { id } })
    }
  },
  async mounted () {
    this.loading = true
    if (this.divisions.length === 0) {
      await this.fetchDivisions()
    }
    await this.fetchConfig()
    this.loading = false
  }
}
</script>
