<template>
  <v-dialog
    v-model="completeCheckInDialog"
    persistent
    width="500"
    height="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="ml-4"
        v-on="on"
        :disabled="disabled"
        color="primary"
      >
        Complete Check In
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Complete Check In
        <v-spacer />
        <base-button
          icon-bool
          icon="mdi-close"
          @clicked="completeCheckInDialog = false"
        />
      </v-card-title>
      <v-card-text>
        Click the button below if you are ready to enter in final notes and payment details.
      </v-card-text>
      <div class="text-center py-4">
        <v-btn
          class="mr-4"
          @click="$router.push({ name: 'edit-checkin', params: { id: $route.params.id } })"
          color="error"
          text
        >
          Editing
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="loading"
          @click="completeCheckIn"
        >
          Complete
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompleteCheckInDialog',
  props: {
    loading: { type: Boolean, required: true },
    disabled: { type: Boolean, required: false }
  },
  data: () => ({
    completeCheckInDialog: false
  }),
  methods: {
    /**
     * Tell the parent to complete the check in
     */
    completeCheckIn () {
      this.$emit('complete')
    }
  }
}
</script>
