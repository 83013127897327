<template>
  <v-card>
    <v-card-subtitle>
      Hedge Information
    </v-card-subtitle>
    <v-row>
      <card-list-item title="Memo">
        <template v-if="!editingInfo">
          {{ purchase.hedge_request_memo ? purchase.hedge_request_memo : 'Edit to add' }}
        </template>
        <template slot="title-button">
          <base-button
            icon-bool
            color="info"
            icon="mdi-pencil"
            other-style-value="ml-10 mb-0 mt-n5"
            x-small
            @clicked="editingInfo = true"
            v-if="purchase.status !== 'CU' && !editingInfo"
          />
        </template>
        <edit-notes
          v-if="editingInfo"
          :hedge-notes="purchase.hedge_request_memo"
          :updating="updatingPurchase"
          @cancel="editingInfo = false"
          @updateNotes="updateNotes"
        />
      </card-list-item>
      <card-list-item
        title="Hedge"
        v-if="hedge"
      >
        <router-link :to="{ name: 'hedge', params: { id: hedge.id }}">
          {{ hedge.reference ? hedge.reference : hedge.id }}
        </router-link>
      </card-list-item>
    </v-row>
    <hedge-dialog
      v-if="!hedge"
      @createPurchaseHedge="createPurchaseHedge"
      @addHedge="addHedge"
    />
  </v-card>
</template>

<script>
import HedgeDialog from './HedgeDialog'
import EditNotes from '@/base/components/EditNotes'

export default {
  name: 'HedgeSection',
  components: { HedgeDialog, EditNotes },
  props: {
    hedge: { type: Object, required: false, default: null },
    purchase: { type: Object, required: true },
    updatingPurchase: { type: Boolean, required: true }
  },
  data: () => ({
    editingInfo: false
  }),
  methods: {
    updateNotes (notes) {
      let params = { hedge_request_memo: notes }
      this.$emit('updatePurchase', params)
      this.editingInfo = false
    },
    /**
     * Create a new hedge for the given purchase
     * @param {Object} body
     */
    createPurchaseHedge (body) {
      this.$emit('createPurchaseHedge', body)
    },
    /**
     * Add the given hedge to the purchase
     * @param {Number} hedgeId
     */
    addHedge (hedgeId) {
      this.$emit('addHedge', hedgeId)
    }
  }
}
</script>
