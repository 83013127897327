<template>
  <card-list-item title="PPM Calculation">
    <template v-slot:title-button>
      <v-btn
        icon
        x-small
        color="info"
        @click="editingPpm = !editingPpm"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <div v-if="editingPpm">
      <paginated-autocomplete
        label="Select PPM Calculator"
        v-model="ppmCalc"
        item-text="name"
        item-value="id"
        base-url="/api/ppm_calculations/"
      >
        <template v-slot:append-outer>
          <v-btn
            class="mr-2"
            color="success"
            icon
            fab
            x-small
            :loading="loading"
            :disabled="loading"
            @click="$emit('updatePpm', ppmCalc)"
          >
            <v-icon>mdi-check </v-icon>
          </v-btn>
        </template>
      </paginated-autocomplete>
    </div>
    <router-link
      v-else-if="purchase.ppm_calculation"
      :to="{ name: 'ppm-calculation', params: { id: purchase.ppm_calculation.id }}"
    >
      {{ purchase.ppm_calculation | filterObject('name') }}
    </router-link>
    <div v-else>
      {{ `No Calculation Attached` }}
    </div>
  </card-list-item>
</template>

<script>
export default {
  name: 'PurchasePpmCalc',
  props: {
    purchase: { type: Object, required: true },
    loading: { type: Boolean, required: true }
  },
  data: () => ({
    editingPpm: false,
    ppmCalc: null,
    wasLoading: false
  }),
  watch: {
    loading () {
      if (this.loading) this.wasLoading = true
      if (!this.loading && this.wasLoading) this.editingPpm = false
    }
  },
  mounted () {
    this.ppmCalc = this.purchase.ppm_calculation
  }
}
</script>
