<template>
  <ppm-value-card
    v-if="purchase.ppm_calculation"
    :pt="purchase.estimated_ctoz.pt"
    :pd="purchase.estimated_ctoz.pd"
    :rh="purchase.estimated_ctoz.rh"
  >
    <v-card-subtitle>
      Contained toz (estimated using ppm calculator)
      <info-tooltip>
        Calculated by using the <em>purchase.ppm_calculator.[metal]_ctoz_per_pc *
          purchase.total_cat_pieces</em>
      </info-tooltip>
    </v-card-subtitle>
  </ppm-value-card>
  <v-card v-else>
    <v-card-subtitle>
      Estimations
    </v-card-subtitle>
    <card-list-item
      title="Please attach a PPM Calculator"
    />
  </v-card>
</template>

<script>
import PpmValueCard from '@/components/PpmValueCard.vue'

export default {
  name: 'PurchaseEstimationsSection',
  components: { PpmValueCard },
  props: {
    purchase: { type: Object, required: true }
  }
}
</script>
