<template>
  <div>
    <slot
      name="activator"
      :open="() => dialog = !dialog"
    />
    <v-dialog
      v-model="dialog"
      fullscreen
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Select Inventory
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              @click="dialog = false"
            >
              Done
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Filter Available Inventory"
            v-model="filterText"
            clearable
          />
          <v-chip
            class="ma-1"
            v-for="(sug, index) in suggestions"
            small
            :key="index"
            @click="filterText = sug"
          >
            {{ sug }}
          </v-chip>
          <inventory-box-select
            :key="filterText"
            :search-filters="{ search: filterText, status: 'AC' }"
            :value="value"
            @input="$emit('input', $event)"
            @available="adjustFilter"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InventoryBoxSelect from '../../Warehouse/components/InventoryBoxSelect.vue'

export default {
  name: 'SelectInventory',
  components: { InventoryBoxSelect },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    suggestions: [],
    filterText: '',
    dialog: false
  }),
  methods: {
    adjustFilter (available) {
      const s = new Set()
      available.forEach(avail => avail.contains.forEach(contained => s.add(contained)))
      this.suggestions = Array.from(s)
    }
  }
}
</script>
