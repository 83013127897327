<template>
  <div>
    <strong>{{ location.name }}</strong>
    <br>
    <div v-if="lineOne.length > 0">
      {{ lineOne }}
    </div>
    <div v-if="lineTwo.length > 0">
      {{ lineTwo }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationBlock',
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  computed: {
    lineOne () {
      return this.location.address || ''
    },
    lineTwo () {
      let addr = []
      const comma = !!(this.location.city && this.location.state)
      if (this.location.city) addr.push(this.location.city + (comma ? ',' : ''))
      if (this.location.state) addr.push(this.location.state)
      if (this.location.zip_code) addr.push(this.location.zip_code)
      return addr.join(' ')
    }
  }
}
</script>
