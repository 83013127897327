/**
 * Style rules for PDFMake document-definition-objects
 * Intended for use with PDFMakeBtn component as a set of default styles
 *
 * @returns { Object } Styles dictionary
 */
function pdfStyles () {
  return {
    heading: {
      fontSize: 24,
      bold: true
    },
    subheading: {
      fontSize: 14
    },
    subheadingRight: {
      fontSize: 14,
      alignment: 'right'
    },
    row: {
      margin: [0, 10, 0, 5]
    },
    table: {
      margin: [0, 5, 0, 15]
    },
    body2: {
      fontSize: 18
    }
  }
}

export { pdfStyles }
