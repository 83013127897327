var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('url-breadcrumbs',{attrs:{"items":[
      ['Intake List', { name: 'intake-list' }],
      ['Create Intake Using Boxes', { name: 'create-intake-using-boxes' }]
    ]}}),_c('base-title',{attrs:{"title":"Intake from Inventory"}}),_c('v-skeleton-loader',{attrs:{"type":"table@2,card","loading":_vm.loading}},[_c('Validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('v-form',{attrs:{"value":!invalid},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.createIntake($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h6"},[_vm._v(" Beginning Inventory ")]),_c('p',[_vm._v(" These entries will be removed from inventory if not included in final inventory. ")]),_c('v-card',[_c('v-card-text',[_c('inventory-box-table',{attrs:{"value":_vm.inputBoxes,"headers":['name', 'contains', 'catalog_name', 'container_text', 'weight', 'pieces'],"no-name-link":""},on:{"rowClicked":_vm.addInputBoxToOutput}}),_c('select-inventory',{attrs:{"value":_vm.inputBoxes},on:{"input":_vm.setInputBoxes},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var open = ref.open;
return [_c('v-btn',{attrs:{"color":_vm.step === 'select-inventory' ? 'primary' : '',"fab":"","bottom":"","right":"","absolute":""},on:{"click":open}},[_c('v-icon',[_vm._v(" mdi-plus-minus-variant ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_c('em',[_vm._v(" Click on a row to add it directly to final inventory or click "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('intakeBoxStore/addAllInputBoxes')}}},[_vm._v(" here ")]),_vm._v(" to add all. ")])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalWeightInput))+" lbs")]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalPiecesInput))+" pcs")])])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h6"},[_vm._v(" Final Inventory ")]),_c('p',[_vm._v(" Add lines by clicking on a beginning inventory line, adding a new box, or searching for an existing box from inventory. ")]),_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.outputBoxes},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('search-autocomplete',{attrs:{"label":"Search for Existing Box","api-path":"/api/inventory_boxes/","clearable":"","format-entry":function (x) { return Object.assign({ friendlyName: x.name + ' ' + x.contains + ' ' + x.catalog_name }, x); }},model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}})],1),_c('div',{staticClass:"mt-2 ml-4"},[_c('v-btn',{attrs:{"color":_vm.step === 'add-boxes' ? 'primary' : ''},on:{"click":_vm.addBox}},[_vm._v(" "+_vm._s(_vm.searched ? 'Add Box' : 'New Box')+" ")])],1)])]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('br'),_c('a',{staticClass:"caption",on:{"click":function($event){return _vm.removeOutputBox(item)}}},[_vm._v(" remove ")])]}},{key:"item.catalog",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"py-2"}),_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"value":item.catalog,"items":_vm.catalogOptions,"label":"Material","item-text":"name","item-value":"url","return-object":"","error-messages":errors,"dense":""},on:{"change":function($event){return _vm.selectCatalog(item, $event)}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"value":item.container,"label":"Container","items":_vm.containerTypes,"error-messages":errors,"dense":""},on:{"change":function($event){return _vm.selectContainer(item, $event)}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","label":"lot","value":item.lot,"items":_vm.availableLots,"item-text":"name","item-value":"url","return-object":"","required":"","placeholder":"Select a Lot","error-messages":errors},on:{"change":function($event){return _vm.setLot(item, $event)}}})]}}],null,true)})],1)]}},{key:"item.weight",fn:function(ref){
    var item = ref.item;
return [_c('rules-text',{attrs:{"validation-rules":"required|max_value:99999999|decimal:2","label":"Gross","value":item.weight,"hint":"in pounds","type":"number","dense":""},on:{"input":function($event){return _vm.setWeight(item, $event)}}})]}},{key:"item.net",fn:function(ref){
    var item = ref.item;
return [_c('rules-text',{attrs:{"validation-rules":"required|max_value:99999999|decimal:2|min_value:0","label":"Tare","value":item.tare,"hint":"in pounds","type":"number","dense":""},on:{"input":function($event){return _vm.setTare(item, $event)}}})]}},{key:"item.tare",fn:function(ref){
    var item = ref.item;
return [_c('rules-text',{attrs:{"validation-rules":"required|max_value:99999999|decimal:2|min_value:0","label":"Net","value":item.net,"hint":"in pounds","type":"number","readonly":"","dense":""}})]}},{key:"item.pieces",fn:function(ref){
    var item = ref.item;
return [_c('rules-text',{attrs:{"validation-rules":"required|max_value:99999999|decimal:0|min_value:0","label":"Pieces","value":item.pieces,"type":"number"},on:{"input":function($event){return _vm.setPieces(item, $event)}}})]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"text-right"},[_c('td',[_vm._v(" "+_vm._s(_vm.totalBoxes)+" Boxes ")]),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalWeight))+" lbs ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalTare))+" lbs ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalNet))+" lbs ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalPieces))+" pc ")])])]},proxy:true}],null,true)})],1)],1)],1)],1),_c('h2',{staticClass:"text-h6"},[_vm._v(" Other Details ")]),_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"label":"Notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('div',[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" The resulting inventory will have the following check ins linked. ")]),_c('ul',_vm._l((_vm.containsInput),function(ci,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(ci.id + ' ' + ci.yard_name)+" ")])}),0)]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"value":_vm.location,"label":"Location","item-text":"name","item-value":"url","items":_vm.locations},on:{"input":_vm.setLocation}})]}}],null,true)})],1)],1),_c('text-fab',{attrs:{"disabled":invalid || _vm.outputBoxes.length === 0,"color":_vm.step === 'save-intake' ? 'primary' : '',"bottom":"","right":"","fixed":"","icon-text":"mdi-check"},on:{"click":function($event){_vm.showCreateDialog = !_vm.showCreateDialog}}},[_vm._v(" Complete Intake ")]),_c('create-intake-confirmation',{model:{value:(_vm.showCreateDialog),callback:function ($$v) {_vm.showCreateDialog=$$v},expression:"showCreateDialog"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }