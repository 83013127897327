/**
 * Required PROP
 * baseUrl {String}
 *
 * Available Methods
 *  - formattedExportData
 *  - headers
 *  - showAllFields
 *  - showNoFields
 *  - setExportData\
 *
 * be sure to initialize
 *  use with chipsMixin
 *    mounted () {
 *     this.reflectQueryToChipProps()
 *     this.executeChipsQuery(this.setExportData)
 *   }
 * */

export default {
  data: () => ({
    baseUrl: '/api/hedges/',
    ready: false,
    exportData: [],
    possibleFields: []
  }),
  computed: {
    formattedExportData () {
      return this.exportData
        .map(this.__flattenObject)
        .map(this.__fieldFilter)
    },
    // turns visible fields into table headers for v-data-table
    headers () {
      const header = text => text.replace(/_/gi, ' ')
      return this.__visibleFieldsArray.map(x => ({ text: header(x), value: x }))
    },
    // turns the visible fields into an array
    __visibleFieldsArray () {
      return this.possibleFields.filter(x => x.visible).map(x => x.text)
    }
  },
  methods: {
    // toggles all possible fields as shown
    showAllFields () {
      this.possibleFields = this.possibleFields.map(field => {
        field.visible = true
        return field
      })
    },
    // toggles all fields as possible fields as false
    showNoFields () {
      this.possibleFields = this.possibleFields.map(field => {
        field.visible = false
        return field
      })
    },
    // Used as a callback for getting data with ExecuteChipsQuery (sets the export data prop and examines fields)
    setExportData (data) {
      this.ready = true
      this.exportData = data
      this.__setPossibleFields(data)
    },
    // Filters the JSON object by the field filter
    __fieldFilter (row) {
      const clone = {}
      for (const key in row) {
        if (this.__visibleFieldsArray.includes(key)) {
          clone[key] = row[key]
        }
      }
      return clone
    },
    // Running into some errors here
    __flattenObject (obj, prefix = '') {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = prefix.length ? prefix + '__' + key : key
        const value = obj[key]

        // Simple values are relayed
        if (value === null || typeof value !== 'object') {
          acc[newKey] = value
          return acc
        }

        // Simple Arrays are compressed
        if (Array.isArray(value)) {
          acc[newKey] = value.map(String).join(', ')
          return acc
        }

        // we are merging them here
        Object.assign(acc, this.__flattenObject(value, newKey))
        return acc
      }, {})
    },
    // Extracts the possible fields from a json Object
    __setPossibleFields (data) {
      if (data.length > 0) {
        this.possibleFields = Object.keys(this.__flattenObject(data[0])).map(text => ({ text, visible: true }))
      }
    }
  }
}
