import axios from 'axios'

export function createIntake ({ notes }) {
  return axios.post('/api/intakes/', { notes })
}

export function fetchIntake (id) {
  return axios.get(`/api/intakes/${id}/`)
}

export function fetchIntakeLines (id) {
  return axios.get('/api/intake_lines/', {
    params: { intake: id, page_size: 0 }
  })
}

/**
 * Creates a new Intake and applies line inventory changes
 * @param intake
 * @param toDelete
 * @param toUpdate
 * @param toCreate
 * @returns {Promise<void>}
 */
export function addToIntakeFromInventory (intakeId, inputBoxes, outputBoxes) {
  // remove the :: that marks a box as temporary
  outputBoxes = outputBoxes.map(box => {
    if (String(box.id).startsWith('::')) box.id = null
    return box
  })
  return axios.post(`/api/intakes/${intakeId}/from_inventory/`, {
    input_boxes: inputBoxes,
    output_boxes: outputBoxes
  })
}
