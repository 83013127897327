<!--
  <skid-labels-editor
    addingSkids => boolean; loading animation on button
    checkInSkids => array; each skid receiving a shipping label
    small => boolean; small button size option
  />

  ** there is a slot called activator that allows the use of a custom button
  to open the dialog **

  <template v-slot:activator="{ on, disabled, loading}">
    <v-btn
      @click="on"
      :disabled="disabled"
      :loading="loading"
    >
      Example
    </v-btn>
  </template>

  -->

<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
      :disabled="disabled"
      :loading="addingSkids"
    >
      <v-btn
        @click="dialog = !dialog"
        :disabled="disabled"
        :loading="addingSkids"
        :small="isSmallBtn"
      >
        Print Skids
      </v-btn>
    </slot>
    <slot>
      <pdf-make-btn
        :doc-def-builder="docDef"
        :styles="{
          packingSlipBody: {
            fontSize: 20
          },
          packingSlipBox: {
            fontSize: 32
          }
        }"
      >
        <template
          v-slot:activator="{ on }"
        >
          <edit-dialog
            v-model="dialog"
            headline-text="Print Skid Shipping Labels"
            accept-text="Print"
            @accept="on"
          >
            <v-select
              v-model="configChoice"
              :items="configChoices"
              return-object
              item-text="name"
              dense
              label="Skid Labeling Theme"
            />
            <v-checkbox
              dense
              v-model="blankBoxNumbers"
              label="Leave box numbers blank (write them in by hand)"
            />
            <v-checkbox
              dense
              v-model="blankWeightsAndPieces"
              label="Leave box weights and pieces blank (write them in by hand)"
            />
            <p class="body-1">
              Which skids need labels?
            </p>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="skidsWithBoxNumbers"
              item-key="box_number"
              class="elevation-0"
              disable-pagination
              show-select
            />
            <p>
              <v-checkbox
                dense
                v-model="anonymousShipper"
                label="Redact shipper name and address from packing slips?"
              />
            </p>
          </edit-dialog>
        </template>
      </pdf-make-btn>
    </slot>
  </div>
</template>

<script>
import EditDialog from '@/base/components/EditDialog'
import PdfMakeBtn from '@/base/components/PdfMakeBtn'
import { CONFIGS } from '@/../izzi_config'

export default {
  name: 'SkidLabelsEditor',
  components: {
    EditDialog,
    PdfMakeBtn
  },
  props: {
    checkIn: { type: Object, required: true },
    checkInSkids: { type: Array, required: true },
    // Awaiting new skid POST response
    addingSkids: { type: Boolean, required: true },
    isSmallBtn: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    configChoices: Object.values(CONFIGS),
    configChoice: Object.values(CONFIGS)[0],
    blankWeightsAndPieces: false,
    blankBoxNumbers: false,
    dialog: false,
    headers: [
      { text: 'Box #', value: 'box_number' },
      { text: 'Expected Weight', value: 'expected_weight' },
      { text: 'Expected Pieces', value: 'expected_pieces' }
    ],
    selected: [],
    anonymousShipper: false
  }),
  watch: {
    // Set the selected to the available boxes when they change
    skidsWithBoxNumbers: {
      immediate: true,
      handler (boxes) {
        this.selected = [...boxes]
      }
    }
  },
  computed: {
    // Maps the CheckInSkids with an box_number
    skidsWithBoxNumbers () {
      return this.checkInSkids.map((skid, index) => Object.assign({ box_number: index + 1 }, skid))
    },
    disabled () {
      return this.addingSkids || this.checkInSkids.length < 1
    }
  },
  methods: {
    formatWeightAndPieces (value) {
      if (this.blankWeightsAndPieces) return ''
      return value || ''
    },
    docDef () {
      // Formatted packing slip for printing/PDF with PDFMake
      return this.selected.map((skid, index) => {
        let yardShippingColumn = {
          stack: [
            {
              text: 'Customer / Yard:'
            },
            {
              margin: [0, 0, 0, 5],
              text: this.checkIn.yard.name
            },
            {
              style: 'packingSlipBody',
              text: 'Ship from:'
            },
            {
              style: 'packingSlipBody',
              text: this.checkIn.yard.name
            },
            {
              style: 'packingSlipBody',
              text: this.checkIn.yard.address || ''
            },
            {
              margin: [0, 0, 0, 20],
              style: 'packingSlipBody',
              text: `${this.checkIn.yard.city || ''}, ${this.checkIn.yard.state || ''} ${this.checkIn.yard.zip_code || ''}`
            }
          ]
        }
        let pacShippingColumn = {
          stack: [
            {
              text: 'Buyer:'
            },
            {
              margin: [0, 0, 0, 5],
              text: this.checkIn.created_by_username
            },
            {
              style: 'packingSlipBody',
              text: 'Ship to:'
            },
            {
              style: 'packingSlipBody',
              text: this.checkIn.to_location.name
            },
            {
              style: 'packingSlipBody',
              text: this.checkIn.to_location.address
            },
            {
              margin: [0, 0, 0, 20],
              style: 'packingSlipBody',
              text: `${this.checkIn.to_location.city}, ${this.checkIn.to_location.state} ${this.checkIn.to_location.zip_code}`
            }
          ]
        }
        let checkInId = `${this.checkIn.id}`
        if (this.checkIn.cart_sequence_id) checkInId += ':' + this.checkIn.cart_sequence_id
        let checkInColumn = {
          stack: [
            {
              text: `Expected Weight: ${this.formatWeightAndPieces(skid.expected_weight)}`
            },
            {
              text: `Expected Pieces: ${this.formatWeightAndPieces(skid.expected_pieces)}`
            },
            {
              text: 'Check-In: ' + checkInId
            },
            {
              text: `Lot: ${(this.checkIn.intended_lot !== null) ? this.checkIn.intended_lot.name : ''}`
            },
            {
              margin: [0, 0, 0, 20],
              text: 'Received Weight: ' // Handwritten
            }
          ]
        }
        // Include QR code if identifier_token exists for this inventory box
        let qrColumn = (!skid.identifier_token_id) ? {} : {
          eccLevel: 'H',
          fit: 200,
          qr: `https://trackkat.com/#/identify-token/${skid.identifier_token_id}`
        }
        let boxesCountText = ''
        if (this.blankBoxNumbers) {
          boxesCountText = '____ of ____'
        } else {
          boxesCountText = `${skid.box_number} of ${this.checkInSkids.length}`
        }
        let page = [
          {
            style: 'heading',
            columns: [
              {
                margin: [0, 0, 0, 30],
                image: this.configChoice.png(),
                width: 200
              }
              // {
              //   style: 'subheadingRight',
              //   stack: [
              //     { text: '4857 W Van Buren St', style: 'subheading' },
              //     { text: 'Phoenix, Arizona 85043', style: 'subheading' },
              //     { text: '(602) 258-6500', style: 'subheading' }
              //   ]
              // }
            ]
          },
          // Box name + number/total boxes
          {
            margin: [0, 0, 0, 30],
            style: 'packingSlipBox',
            text: `${skid.name}, Box. ${boxesCountText}`
          },
          // Transaction date
          {
            style: 'subheader',
            margin: [0, 0, 0, 10],
            text: `Transaction Date: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
          },
          // Shipping Info
          // Hide yardShippingColumn if this.anonymousShipper is true
          { columns: (this.anonymousShipper) ? [pacShippingColumn] : [yardShippingColumn, pacShippingColumn] },
          // Box contents and QR
          { columns: [checkInColumn, qrColumn] }
        ]
        // Add page break to each page, unless it is last
        if (index !== this.selected.length - 1) page.push({ text: '', pageBreak: 'after' })
        return page
      })
    }
  }
}
</script>
