<template>
  <v-container
    fluid
  >
    <template
      v-if="results.length"
    >
      <v-pagination
        v-model="page"
        :length="pages"
      />
      <v-card
        class="mx-auto"
        max-width="800"
      >
        <v-list
          dense
          two-line
          rounded
          subheader
        >
          <v-list-item
            v-for="result in results"
            :key="result.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ result.name }} {{ result.unit_of_measure }}
                <span>
                  -- $ {{ result.price || 0 }}
                </span>
              </v-list-item-title>
              <span class="caption">
                {{ result.description }}
              </span>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                icon
                :disabled="hasLine(result)"
                @click="addCatalog(result)"
              >
                <v-icon
                  :color="hasLine(result) ? 'accent' : 'primary'"
                >
                  {{ hasLine(result) ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
      <v-pagination
        v-model="page"
        :length="pages"
      />
    </template>
    <template v-else>
      <strong>No Catalog Results</strong>
      <p class="caption">
        Catalog entries will appear as lines in the check in once you've selected the target on the right.
        <br>
        These entries cover anything from dust to other raw material. The prices for these items are not effected by the market.
      </p>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SearchDialogCatalog',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    page: 1,
    count: 0,
    pageLength: 20,
    results: [],
    prices: []
  }),
  watch: {
    value () {
      this.page = 1
      this.search()
    },
    page () { this.search() }
  },
  computed: {
    pages () {
      return Math.ceil(this.count / 20)
    },
    ...mapGetters('checkIn/edit', [
      'getLines'
    ])
  },
  methods: {
    ...mapActions('checkIn/edit', [
      'addCatalogLine'
    ]),
    hasLine (catalog) {
      return !!this.getLines.find(cl => cl.catalog.url === catalog.url)
    },
    async search () {
      this.$emit('loading', true)
      const params = {
        name__icontains: this.value,
        material_type__ne: 'CV',
        page: this.page
      }
      const r = await axios.get('/api/catalogs/', { params })
      this.results = r.data.results
      this.count = r.data.count
      this.$emit('loading', false)
    },
    addCatalog (catalog) {
      this.addCatalogLine({ catalogUrl: catalog.url, price: catalog.price })
    }
  }
}
</script>
