<template>
  <unauthorized
    :authorized="$store.getters.can('create-check-ins')"
    message="Not Authorized to Create Check Ins"
  >
    <v-container>
      <app-loading-indicator v-if="loading" />
      <h3 v-else-if="failedToLoad">
        Failed to Load
      </h3>
      <div
        v-else
        class="mt-0"
      >
        <v-card
          flat
          min-height="100vh"
          class="mb-16"
        >
          <v-card-text class="text-center">
            <cash-disclaimer />
          </v-card-text>
        </v-card>
        <v-sheet
          id="payment"
          class="px-4"
        >
          <ValidationObserver
            v-slot="{ invalid }"
            ref="validationObserver"
          >
            <h1 class="text-center">
              Check Out Shipping and Payment
            </h1>
            <v-row>
              <v-col>
                <v-switch
                  :disabled="dockBuy"
                  label="Requires Transit"
                  v-model="requiresTransit"
                />
                <span v-if="requiresTransit">
                  This Check-In <strong>does</strong> require transit. Logistics will be notified.
                </span>
                <span v-else>
                  This Check-In <strong>does not</strong> require transit.
                  The current location will be updated to
                  <strong>
                    {{ checkIn.to_location_name }}.
                  </strong>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Payment Type*"
                    v-model="paymentType"
                    :items="$store.getters['getPaymentTypes']"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  :rules="dockBuy ? null : 'required'"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Transport Method*"
                    v-model="transportMethod"
                    :disabled="dockBuy"
                    :items="$store.getters['getTransportMethods']"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  :rules="paymentType && paymentType === 'UNP' ? null : 'required'"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Payment Method"
                    v-model="paymentMethod"
                    :items="$store.getters['getPaymentMethods']"
                    item-text="text"
                    item-value="value"
                    :disabled="!paymentType || paymentType === 'UNP'"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider
                  :rules="paymentType && paymentType === 'UNP' ? null : 'required'"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Payment Amount"
                    prepend-icon="mdi-currency-usd"
                    :hint="checkIn.total_prices ? `Total Cost: $${checkIn.total_prices.total }` : ''"
                    v-model="paymentAmount"
                    :disabled="!paymentType || paymentType === 'UNP'"
                    :error-message="errors"
                    type="number"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  label="Notes (Internal)"
                  v-model="notes"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  label="Notes (External)"
                  v-model="publicNotes"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="External Reference"
                  v-model="customerRef"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Customer Name*"
                    v-model="customerName"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider
                  rules="email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Customer Email"
                    v-model="customerEmail"
                    :error-messages="errors"
                    hint="This will *NOT* send the customer an email"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col>
                <h3> Customer Signature </h3>
                <v-card>
                  <VueSignaturePad
                    style="background: lightgrey;"
                    height="400px"
                    ref="signaturePad"
                    :options="{ onEnd }"
                  />
                </v-card>
                <div
                  class="text-right mt-2"
                  v-if="!finishedSigning"
                >
                  <v-btn
                    :disabled="locked"
                    color="error"
                    @click="clearSignature"
                    class="mr-4"
                  >
                    Clear
                  </v-btn>
                  <v-btn
                    :disabled="emptySignature"
                    color="primary"
                    @click="finishSigning"
                  >
                    Finish Signing
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn
                large
                :loading="completing"
                :disabled="invalid || !finishedSigning"
                color="primary"
                class="ma-4"
                @click="completeCheckIn"
              >
                Complete Check-In
              </v-btn>
            </div>
          </ValidationObserver>
        </v-sheet>
      </div>
      <edit-dialog
        v-model="displaySigningDialog"
        headline-text="Signature Confirmation"
        accept-text="Accept Signature"
        :inputs-valid="cashSigned !== null"
        @accept="confirmedSignature"
      >
        <v-select
          label="Confirm Signature Type"
          v-model="cashSigned"
          :items="cashSignedOptions"
        />
      </edit-dialog>
      <!--      <customer-signature-dialog-->
      <!--        :display-dialog="displaySigningDialog"-->
      <!--        @closeDialog="displaySigningDialog = false"-->
      <!--        @signatureConfirmed="confirmedSignature"-->
      <!--      />-->
    </v-container>
  </unauthorized>
</template>

<script>
// import moment from 'moment'
import VueSignaturePad from 'vue-signature-pad'
// import CustomerSignatureDialog from './components/CustomerSignatureDialog'
import { mapActions, mapState } from 'vuex'
import CashDisclaimer from '../components/CashDisclaimer'
import axios from 'axios'

export default {
  name: 'CheckInPayment',
  components: {
    CashDisclaimer,
    // CustomerSignatureDialog,
    VueSignaturePad
  },
  data: () => ({
    cashSigned: null,
    cashSignedOptions: [
      { text: 'CASH PAYMENT', value: 'CP' },
      { text: 'NO CASH PAYMENT', value: 'NC' }
    ],
    requiresTransit: false,
    loading: true,
    failedToLoad: false,
    customerName: '',
    customerEmail: '',
    paymentAmount: null,
    paymentType: null,
    paymentMethod: null,
    transportMethod: '',
    notes: '',
    publicNotes: '',
    emptySignature: true,
    locked: false,
    displaySigningDialog: false,
    finishedSigning: false,
    dockBuy: false,
    completing: false,
    customerRef: ''
  }),
  computed: {
    ...mapState('checkIn', [
      'checkIn'
    ])
  },
  methods: {
    ...mapActions({
      createInboundTransit: 'inboundTransitStore/createInboundTransit',
      requestInboundTransit: 'inboundTransitStore/requestInboundTransit',
      createDockBuy: 'inboundTransitStore/createDockBuyInboundTransit'
    }),
    ...mapActions('checkIn', [
      'fetchCheckIn',
      'updateCheckIn',
      'sendCheckInEmail'
    ]),
    async loadCheckIn (id) {
      try {
        await this.fetchCheckIn(id)
      } catch (e) {
        this.$store.commit('setSnackbarError', 'Unable to get Check In', { root: true })
        this.failedToLoad = true
      } finally {
        if (this.checkIn.dock_buy) {
          this.dockBuy = true
          this.paymentType = 'PIF'
        }
        this.notes = this.checkIn.notes
        this.publicNotes = this.checkIn.public_notes
        this.customerRef = this.checkIn.customer_ref
        this.requiresTransit = this.checkIn.requires_transit
        this.loading = false
      }
    },
    /**
     * Complete the Check In by including the payment and customer info
     */
    async completeCheckIn () {
      this.completing = true

      const signature = this.$refs.signaturePad.saveSignature()
      const formData = new FormData()
      formData.set('requires_transit', this.requiresTransit)
      // Automatically updates the Current Location if Transit is not Required
      if (!this.requiresTransit && this.checkIn.to_location) {
        formData.set('current_location', this.checkIn.to_location)
      }
      formData.set('customer_name', this.customerName)
      if (this.customerEmail) {
        formData.set('customer_email', this.customerEmail)
      }
      formData.set('payment_type', this.paymentType)
      if (this.paymentType !== 'UNP') {
        formData.set('payment_method', this.paymentMethod)
        formData.set('payment_amount', this.paymentAmount)
      }
      formData.set('transport_method', this.transportMethod)
      formData.set('notes', this.notes)
      formData.set('public_notes', this.publicNotes)
      formData.set('customer_ref', this.customerRef)
      formData.set('customer_signature', signature.data)
      formData.set('status', 'CH')
      formData.set('is_cash_signed', this.cashSigned === 'CP' ? 'true' : 'false')

      const body = { formData, id: this.$route.params.id }
      const accepted = await this.updateCheckIn(body)
      if (accepted) {
        // Attempt to generate a transit for completed check-in
        await axios.post(`/api/check_ins/${this.checkIn.id}/generate_initial_transit/`)
        // Send update email
        await this.sendCheckInEmail(body)
        // Automatically creates dock by
        if (this.dockBuy) {
          await this.createDockBuy(this.checkIn)
        }
        // route to check in page
        return this.$router.push({ name: 'check-in', params: { id: this.checkIn.id } })
      } else {
        this.$store.commit('setSnackbarError', 'Failed to complete Check In. Please try again.')
      }
      this.completing = false
    },
    clearSignature () {
      this.emptySignature = true
      this.$refs.signaturePad.clearSignature()
    },
    finishSigning () {
      this.displaySigningDialog = true
    },
    confirmedSignature () {
      this.displaySigningDialog = false
      this.finishedSigning = true
      this.emptySignature = false
      this.$refs.signaturePad.lockSignaturePad()
    },
    onEnd () {
      this.emptySignature = this.$refs.signaturePad.isEmpty()
    }
  },
  mounted () {
    Promise.all([
      this.loadCheckIn(this.$route.params.id).then(() => {
        // Scrolls user to payment body by default
        this.$el.querySelector('#payment').scrollIntoView()
      })
    ]).finally(() => {
      this.loading = false
    })
  }
}
</script>
