<!--
Single select can be used by passing a :selected=prop and setting it with @select="prop = $event"
Be sure to have show-select enabled
-->
<template>
  <v-data-table
    :data="selected"
    :headers="tableHeaders"
    :items="value"
    disable-pagination
    hide-default-footer
    single-select
    selectable-key="id"
    :show-select="showSelect"
    :items-per-page="-1"
    @click:row="$emit('rowClicked', $event)"
    @input="$emit('select', $event)"
  >
    <template v-slot:item.name="{ item }">
      <info-tooltip>
        <template v-slot:wrap-me>
          <div
            v-if="noNameLink"
            class="text-no-wrap"
          >
            {{ item.name }}
          </div>
          <router-link-id
            v-else
            name="inventory-on-hand-box"
            :id="item.source_id"
          >
            <span class="text-no-wrap">
              {{ item.name }}
            </span>
          </router-link-id>
        </template>
        #{{ item.source_id }}
      </info-tooltip>
    </template>
    <template v-slot:item.location_name="{ item }">
      {{ item.location_name }}
      <div>
        <div class="grey--text">
          {{ item.gps_number }}
        </div>
      </div>
    </template>
    <template v-slot:item.weight="{ item }">
      {{ item.weight | formatNumber }} lbs
    </template>
    <template v-slot:item.pieces="{ item }">
      {{ item.pieces | formatNumber }}
    </template>
    <template v-slot:item.contains="{ item }">
      <div
        v-for="checkIn in item.contains"
        :key="checkIn"
      >
        {{ checkIn }}
      </div>
    </template>
    <template v-slot:item.in_lots="{ item }">
      <div
        v-for="lot in item.in_lots"
        :key="lot"
      >
        {{ lot }}
      </div>
    </template>
    <template v-slot:item.buyers="{ item }">
      <div
        v-for="buyer in item.buyers"
        :key="buyer"
      >
        {{ buyer }}
      </div>
    </template>
    <template v-slot:item.manifest="{ item }">
      <div v-if="item.manifest_data">
        <span v-if="noNameLink">
          {{ item.manifest_data.id }}
        </span>
        <router-link-id
          v-else-if="item.manifest_data && !noNameLink"
          name="manifest-view"
          :id="item.manifest_data.id"
        />
      </div>
    </template>
    <template v-slot:item.eff_start_datetime="{ item }">
      {{ item.eff_start_datetime | formatTimeDate }}
    </template>
    <slot />
  </v-data-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: Array
    },
    selected: {
      type: Array,
      default: Array
    },
    historic: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: Array
    },
    noNameLink: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    availableHeaders: {
      'name': { text: 'Name', value: 'name', sortable: false },
      'status': { text: 'Status', value: 'status_text', sortable: false },
      'location_name': { text: 'Location/GPS', value: 'location_name', sortable: false },
      'manifest': { text: 'Manifest', value: 'manifest', sortable: false },
      'contains': { text: 'Vendors', value: 'contains', sortable: false },
      'container_text': { text: 'Container', value: 'container_text', sortable: false },
      'in_lots': { text: 'Lots', value: 'in_lots', sortable: false },
      'catalog_name': { text: 'Material', value: 'catalog_name', sortable: false },
      'weight': { text: 'Gross', value: 'weight', align: 'right', sortable: false },
      'tare': { text: 'Tare', value: 'tare', align: 'right', sortable: false },
      'net': { text: 'Net', value: 'net', align: 'right', sortable: false },
      'pieces': { text: 'Pieces', value: 'pieces', align: 'right', sortable: false },
      'buyers': { text: 'Buyers', value: 'buyers', sortable: false },
      'notes': { text: 'Notes', value: 'notes', sortable: false },
      'status_text': { text: 'Status', value: 'status_text', sortable: false },
      'eff_start_datetime': { text: 'Effective Date', value: 'eff_start_datetime', sortable: false }
    }
  }),
  computed: {
    tableHeaders () {
      let headers
      if (this.headers.length) {
        headers = this.headers
      } else {
        headers = [
          'name',
          'status',
          'location_name',
          'manifest',
          'contains',
          'in_lots',
          'catalog_name',
          'weight',
          // 'tare',
          // 'net',
          'pieces'
        ]
        if (this.historic) {
          headers = headers.concat(['notes', 'status_text', 'eff_start_datetime'])
        }
      }
      return headers.map(key => this.availableHeaders[key])
    }
  }
}
</script>
