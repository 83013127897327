<!--
This page relies entirely on the current layout setting.
First the data is imported then reformatted based on the headers and other special functions.
This largely abstracts the result from the input making most everything automatic but heavily reliant
on INPUT + Layout Headers

There is an additional property in headers call ACCUMULATOR that changes that creates a sum of that row below the table.
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('view-cat-check-in-report')"
    message="Unauthorized to view Check Ins"
  >
    <div>
      <base-title :title="title" />
      <v-row v-show="showCharts">
        <v-col>
          <v-card>
            <v-card-title>
              Charts
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <check-in-charts
                    title="Units Purchased By Buyer"
                    :raw-data="exportData"
                    :init-designations="['DR']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <check-in-charts
                    title="Units Purchased By Converter Group"
                    init-chart="pie-chart"
                    :raw-data="exportDataByUnitType"
                    :init-designations="['DR']"
                    :chart-key-method="row => row.pieceType"
                    :chart-value-method="row => Number(row.pieceCount)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <edit-dialog
            v-model="showFilters"
            headline-text="Filters"
            @accept="routePageWithChips('cat-check-in-report')"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <date-range-input
                    v-if="chipsReflected"
                    :start-date="startDate"
                    :end-date="endDate"
                    start-date-label="Created Date From"
                    end-date-label="Creation Date To"
                    @alterStartDate="startDate = $event"
                    @alterEndDate="endDate = $event"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <search-autocomplete
                    v-model="yardName"
                    :params="{ page_size: 0, fields: 'name', ordering: 'name' }"
                    label="Yard Search"
                    api-path="/api/yards/"
                    item-value="name"
                    multiple
                    :format-entry="x => ({ friendlyName: x.name, name: x.name })"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <search-autocomplete
                    v-model="createdFor"
                    :params="{ fields: 'username', ordering: 'username' }"
                    label="Created for Search"
                    api-path="/api/users/"
                    item-value="username"
                    multiple
                    :format-entry="x => ({ friendlyName: x.username, username: x.username })"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <v-select
                    label="Divisions"
                    v-model="divisionSet"
                    multiple
                    :items="divisions"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <v-select
                    label="Designation"
                    v-model="designationSet"
                    multiple
                    :items="$store.getters['lotStore/lotTypes']"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <v-select
                    label="Statuses"
                    v-model="statusSet"
                    multiple
                    :items="statuses"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </edit-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Results: {{ layoutName }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-btn
                    class="mr-2"
                    color="accent"
                    small
                    @click="showFilters = !showFilters"
                  >
                    Show Filters
                  </v-btn>
                  <v-btn-toggle
                    dense
                    tile
                    :loading="loading"
                    v-model="layout"
                  >
                    <v-btn
                      small
                    >
                      Pieces
                    </v-btn>
                    <v-btn
                      small
                    >
                      Cost
                    </v-btn>
                    <v-btn
                      small
                    >
                      Buyer
                    </v-btn>
                    <v-btn
                      small
                    >
                      Report
                    </v-btn>
                    <v-btn
                      small
                    >
                      Raw
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    color="accent"
                    class="mx-2"
                    small
                    @click="showCharts = !showCharts"
                  >
                    Charts
                    <v-icon
                      class="ml-2"
                      small
                    >
                      mdi-chart-box-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    small
                    @click="fullScreenTable = !fullScreenTable"
                  >
                    Pop Out
                    <v-icon
                      class="ml-2"
                      small
                    >
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                v-show="!fullScreenTable"
                :loading="loading"
                :headers="layoutHeaders"
                :items="formattedExportData"
                dense
              >
                <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | formatDate }}
                </template>
                <template slot="body.append">
                  <tr>
                    <th
                      class="subtitle-2"
                      v-for="(header, index) in layoutHeaders"
                      :key="index"
                    >
                      <span v-if="header.accumulator">
                        {{ header.accumulator(header.value) }}
                      </span>
                    </th>
                  </tr>
                </template>
              </v-data-table>
              <edit-dialog
                v-model="fullScreenTable"
                :max-width="2000"
                :show-decline="false"
                headline-text="Results"
                accept-text="Ok"
              >
                <v-data-table
                  v-if="fullScreenTable"
                  :loading="loading"
                  :headers="layoutHeaders"
                  :items="formattedExportData"
                  dense
                >
                  <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | formatDate }}
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th
                        class="subtitle-2"
                        v-for="(header, index) in layoutHeaders"
                        :key="index"
                      >
                        <span v-if="header.accumulator">
                          {{ header.accumulator(header.value) }}
                        </span>
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </edit-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          Options/Utilities
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <download-excel
                class="v-btn"
                :data="namedFormattedExportData"
                type="csv"
                :name="`check-in-${layoutName}-${new Date().toISOString().split('T')[0]}.csv`"
              >
                <v-btn
                  :disabled="loading"
                  text
                  color="primary"
                >
                  Download Raw CSV Data <v-icon small>
                    mdi-file-delimited-outline
                  </v-icon>
                </v-btn>
              </download-excel>
              <v-btn
                text
                @click="settingsDialog = !settingsDialog"
              >
                Fields (For Raw Output)
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <edit-dialog
        v-model="settingsDialog"
        headline-text="Report Settings"
        :show-decline="false"
        accept-text="Ok"
      >
        <p class="subtitle-1">
          Columns
        </p>
        <v-list>
          <v-list-item>
            <v-btn
              @click="showAllFields"
            >
              All
              <v-icon>
                mdi-checkbox-marked
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              @click="showNoFields"
            >
              None
              <v-icon>
                mdi-checkbox-blank
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item
            v-for="(field, index) in possibleFields"
            :key="index"
          >
            <v-checkbox
              :label="field.text"
              v-model="field.visible"
            />
          </v-list-item>
        </v-list>
      </edit-dialog>
    </div>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import reportMixin from '@/base/filter/reportMixin'
import axios from 'axios'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import CheckInCharts from './components/CheckInCharts'
import { mapState } from 'vuex'

export default {
  components: { CheckInCharts, SearchAutocomplete },
  mixins: [chipsMixin, reportMixin],
  name: 'CatCheckInReport',
  data: function () {
    return {
      title: 'Cat Check In Report',
      showCharts: false,
      showFilters: false,
      fullScreenTable: false,
      settingsDialog: false,
      baseUrl: '/api/check_ins/',
      exportData: [],
      possibleFields: [],
      loading: false,
      layout: 0,
      createdFor: null,
      // 0 'pieces'
      // 1 'cost'
      // 1 'buyer'
      // 3 'raw'
      converterGroups: ['AM', 'BD', 'DF', 'DP', 'DS', 'GN', 'IF', 'OM'],

      fields: [
        'id',
        'yard_id',
        'yard_name',
        'purchase_id',
        'status',
        'transport_method',
        'linked_lot_names',
        'total_counts',
        'total_prices',
        'created_for',
        'created_at',
        'purchase_division_code',
        'purchase_designation'
      ],

      ...this.chipPropagation([
        // { name: 'ID', prop: 'id', type: String, query: 'id' },
        // { name: 'Yard Name', prop: 'customerName', type: String, query: 'yard__name__icontains' },
        { name: 'Yard ID', prop: 'yardName', type: String, query: 'yard__name__iexact' },
        { name: 'Purchase ID', prop: 'purchaseId', type: String, query: 'purchase__id' },
        { name: 'Created For', prop: 'createdFor', type: String, query: 'created_for__username__iexact' },

        // { name: 'Transport Method', prop: 'transportMethodSet', type: Array, query: 'transport_method__in' },
        // { name: 'Payment Methods', prop: 'paymentMethodSet', type: Array, query: 'payment_method__in' },
        // { name: 'Payment Types', prop: 'paymentTypeSet', type: Array, query: 'payment_type__in' },
        { name: 'Statuses', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'lot__division__code__in' },
        { name: 'Designations', prop: 'designationSet', type: Array, query: 'purchase__designation__in' },

        // { name: 'Invoiced', prop: 'invoiced', type: String, query: 'invoiced' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ]),

      divisions: []
    }
  },
  computed: {
    ...mapState('checkIn', [
      'statuses'
    ]),
    // gets the layout name
    layoutName () {
      switch (this.layout) {
        case 0:
          return 'Pieces'
        case 1:
          return 'Cost'
        case 2:
          return 'Buyer'
        case 3:
          return 'Report'
        default:
          return 'Raw'
      }
    },
    // for piece count reporting
    exportDataByUnitType () {
      return this.exportData.reduce((s, row) => s.concat([
        Object.assign({ pieceType: 'After Market', pieceCount: row.total_counts.AM }, row),
        Object.assign({ pieceType: 'Bead', pieceCount: row.total_counts.BD }, row),
        Object.assign({ pieceType: 'Domestic Foil', pieceCount: row.total_counts.DF }, row),
        Object.assign({ pieceType: 'DPF', pieceCount: row.total_counts.DP }, row),
        Object.assign({ pieceType: 'Diesel', pieceCount: row.total_counts.DS }, row),
        Object.assign({ pieceType: 'Generic', pieceCount: row.total_counts.GN }, row),
        Object.assign({ pieceType: 'Import Foil', pieceCount: row.total_counts.IF }, row),
        Object.assign({ pieceType: 'OEM', pieceCount: row.total_counts.OM }, row)
      ]), [])
    },
    formattedExportData () {
      let data = this.exportData.map(this.__flattenObject).map(this.__fieldFilter)
      if (this.layout === 2) data = this.addBuyerFields(data)
      return data
        .map(row => {
          // Adding total_cost to row
          row.total_cost = Number(row.total_prices__other) + Number(row.total_prices__total)
          return row
        })
        .map(row => this.layoutHeaders.reduce((c, header) => {
          // reducing to only required fields for proper exporting
          c[header.value] = row[header.value]
          return c
        }, {}))
    },
    namedFormattedExportData () {
      return this.formattedExportData.map(row => {
        let fmt = {}
        this.layoutHeaders.forEach(({ text, value }) => {
          if (value === 'created_at') {
            fmt[text.toUpperCase()] = row[value].split('T')[0]
          } else {
            fmt[text.toUpperCase()] = row[value]
          }
        })
        return fmt
      })
    },
    layoutHeaders () {
      const baseStart = [
        { text: 'ID', value: 'id', accumulator: _ => 'Totals' },
        { text: 'Status', value: 'status' },
        { text: 'Created', value: 'created_at' },
        { text: 'Yard', value: 'yard_name' },
        { text: 'User', value: 'created_for__username' },
        { text: 'Division', value: 'purchase_division_code' },
        { text: 'Lots', value: 'linked_lot_names' },
        { text: 'Designation', value: 'purchase_designation' }
      ]
      const baseEnd = [
        { text: 'Converter Count',
          value: 'total_counts__total',
          accumulator: this.sumField
        },
        { text: 'Non Converter Cost',
          value: 'total_prices__other',
          accumulator: this.sumField
        },
        { text: 'Converter Cost',
          value: 'total_prices__total',
          accumulator: this.sumField
        },
        // total_prices__other + total_prices__total
        { text: 'Total Cost',
          value: 'total_cost',
          accumulator: this.sumField
        }
      ]

      const cvTypes = this.$store.getters['converterTypes']
      const getKeyText = key => {
        const cv = cvTypes.find(t => t.value === key)
        return (cv) ? cv.text : key
      }
      const makePieceLine = key => ({ text: getKeyText(key) + ' pc',
        value: 'total_counts__' + key,
        accumulator: this.sumField
      })
      const makeCostLine = key => ({ text: getKeyText(key) + ' $',
        value: 'total_prices__' + key,
        accumulator: this.sumField
      })
      const makeAverageLine = key => ({ text: getKeyText(key) + ' AVG',
        value: 'average__' + key,
        accumulator: this.averageField
      })

      switch (this.layout) {
        case 1:
          // cost
          return baseStart.concat(this.converterGroups.map(makeCostLine)).concat(baseEnd)
        case 2:
          // AVERAGE / COUNT / COST / AVERAGE-COST

          // BUYER
          // !BROKERED UNITS B
          // !BROKERED TOTAL COST C
          // !BROKERED AVERAGE D
          // BROKERED UNITS E
          // TOTAL UNITS F

          // OTHER Total G
          // OTHER Cost H
          // LOOSE AVERAGE I

          // ----skip---- THESE ARE NEVER COMBINED. thus not needed
          // CONVERTER TOTAL J
          // CONVERTER PC K
          // CONVERTER INVESTED L

          // ----units----
          // PIECE
          // INVESTED
          // AVERAGE
          // ----skip----
          // REMAINING PIECES - PIECES (this row doesn't make any sense, it's subtracted from previous total)
          // REMAINING TOTAL - PIECES (this row doesn't make any sense, it's subtracted from previous total)
          return [
            { text: 'User', value: 'created_for__username' },
            // { text: 'Designation', value: 'purchase_designation' },

            { text: 'Total Cost',
              value: 'total_counts__total',
              accumulator: this.sumField
            },

            { text: 'Converter Count', // Total Units
              value: 'total_counts__total',
              accumulator: this.sumField
            },
            { text: 'Converter Cost', // Total Cost
              value: 'total_prices__total',
              accumulator: this.sumField
            },
            { text: 'Converter Average', // Total Average
              value: 'average__total',
              accumulator: this.sumField
            },

            { text: 'Non Converter Count', // Other Total Count
              value: 'total_pieces__other',
              accumulator: this.sumField
            },
            { text: 'Non Converter Cost', // Other Total cost
              value: 'total_prices__other',
              accumulator: this.sumField
            },
            { text: 'Non Converter Cost', // Other Total cost
              value: 'average__other',
              accumulator: this.sumField
            }].concat(
            ...this.converterGroups.map(key => [
              makePieceLine(key),
              makeCostLine(key),
              makeAverageLine(key)
            ]))
        case 3:
          // report
          const reportHeaders = [
            { text: 'created at', value: 'created_at' },
            { text: 'purchase id', value: 'purchase_id' },
            { text: 'checkin id', value: 'id' },
            { text: 'yard name', value: 'yard_name' },
            // { text: 'yard id', value: 'yard_id' },
            // { text: 'status', value: 'status' },
            { text: 'counts ' + getKeyText('OM'), value: 'total_counts__OM', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('OM'), value: 'total_prices__OM', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('AM'), value: 'total_counts__AM', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('AM'), value: 'total_prices__AM', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('BD'), value: 'total_counts__BD', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('BD'), value: 'total_prices__BD', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('DS'), value: 'total_counts__DS', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('DS'), value: 'total_prices__DS', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('DP'), value: 'total_counts__DP', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('DP'), value: 'total_prices__DP', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('DF'), value: 'total_counts__DF', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('DF'), value: 'total_prices__DF', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('IF'), value: 'total_counts__IF', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('IF'), value: 'total_prices__IF', accumulator: this.sumField },
            { text: 'counts ' + getKeyText('GN'), value: 'total_counts__GN', accumulator: this.sumField },
            { text: 'prices ' + getKeyText('GN'), value: 'total_prices__GN', accumulator: this.sumField },
            { text: 'counts other', value: 'total_counts__other', accumulator: this.sumField },
            { text: 'prices other', value: 'total_prices__other', accumulator: this.sumField },
            { text: 'counts total', value: 'total_counts__total' },
            { text: 'prices total', value: 'total_prices__total' }
          ]
          // let matches = ax.match(/[A-Z]{2}$/)
          // if (matches.length > 0)
          return reportHeaders
        case 4:
          // raw
          return this.headers
        case 0:
        default:
          // piece
          return baseStart.concat(this.converterGroups.map(makePieceLine)).concat(baseEnd)
      }
    }
  },
  methods: {
    addBuyerFields (data) {
      let def = {
        'total_cost': 0,
        'total_counts__total': 0,
        'total_prices__total': 0,
        // 'average__total': 0,
        'total_pieces__other': 0,
        'total_prices__other': 0
        // 'average__other': 0
      }
      this.converterGroups.forEach(key => {
        def[`total_counts__${key}`] = 0
        def[`total_prices__${key}`] = 0
        // def[`average__${key}`] = 0
      })

      // first we must consolidate the buyers
      const s = data.reduce((carry, row) => {
        let storage = null
        const username = row.created_for__username

        if (!carry.has(username)) {
          storage = Object.assign({ 'created_for__username': username }, def)
        } else {
          storage = carry.get(username)
        }

        for (const key in def) {
          if (row.hasOwnProperty(key)) storage[key] += row[key]
        }

        carry.set(username, storage)
        return carry
      }, new Map())

      const makeAverage = (dollar, count) => count === 0 ? 0 : Math.round(dollar / count)
      let arraySet = Array.from(s.values())
      const formattedData = arraySet.map(row => {
        row['average__total'] = makeAverage(row['total_prices__total'], row['total_counts__total'])
        row['average__other'] = makeAverage(row['total_prices__other'], row['total_pieces__other'])
        this.converterGroups.forEach(key => {
          row[`average__${key}`] = makeAverage(row[`total_prices__${key}`], row[`total_counts__${key}`])
        })
        return row
      })
      return formattedData
    },
    sumField (field) {
      return Math.round(this.formattedExportData.reduce((a, b) => a + Number((b[field] || 0)), 0))
    },
    averageField (field) {
      if (this.formattedExportData.length === 0) return
      const sum = this.formattedExportData.reduce((a, b) => a + Number((b[field] || 0)), 0)
      return Math.round(sum / this.formattedExportData.length * 100)
    },
    fetchDivisions () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,url').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.name, url: d.url }))
        })
      }
    }
  },
  async mounted () {
    this.loading = true
    this.fetchDivisions()
    this.reflectQueryToChipProps()
    this.executeChipsQuery(this.setExportData, { chipsRequired: true }).then(() => {
      this.loading = false
    })
    // open the filter dialog if no filters are set
    if (Object.keys(this.querifyChips()).length === 0) this.showFilters = true
  }
}
</script>
