<template>
  <unauthorized
    :authorized="$store.getters.can('view-purchase')"
    message="Not Authorized to View a Check In"
  >
    <v-container>
      <url-breadcrumbs
        :items="breadcrumbs"
      />
      <base-title v-if="!loading && checkIn">
        Modify {{ `Check-In ID #${checkIn.id}` }}
        <div
          v-if="checkIn.cart_sequence_id"
          class="caption"
        >
          Exchange Cart #{{ checkIn.cart_sequence_id }}
        </div>
      </base-title>
      <app-loading-indicator
        v-if="loading"
      />
      <div
        v-else
      >
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-form
            @submit.stop.prevent="saveCheckIn"
          >
            <v-card>
              <v-card-text>
                <div class="text-h6">
                  Yard <v-chip
                    v-if="checkIn.read_only"
                    x-small
                  >
                    Read Only
                  </v-chip>
                  <v-btn
                    v-if="!checkIn.read_only"
                    :disabled="!$store.getters.can('edit-check-in-yard')"
                    :color="modifyYard ? 'accent' : ''"
                    x-small
                    @click="modifyYard = !modifyYard"
                  >
                    <v-icon x-small>
                      {{ !modifyYard ? 'mdi-pencil-outline' : 'mdi-pencil-off-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="!modifyYard">
                  {{ checkIn.yard.name }}
                </div>
                <ValidationProvider
                  v-else
                  rules="required"
                  v-slot="{ errors }"
                  immediate
                >
                  <search-autocomplete
                    v-model="yard"
                    :params="{ page_size: 0, fields: 'id,name,url,logistics_location', ordering: 'name' }"
                    label="Yard Search"
                    api-path="/api/yards/"
                    :format-entry="x => Object.assign({}, { friendlyName: x.id + '# ' + x.name }, x)"
                  />

                  <span
                    v-for="err in errors"
                    :key="err"
                    class="error--text"
                  >
                    {{ err }}
                  </span>
                </ValidationProvider>
                <div class="text-h6">
                  Current Location
                  <v-btn
                    :disabled="!$store.getters.can('edit-check-in-yard')"
                    :color="modifyCurrentLocation ? 'accent' : ''"
                    x-small
                    @click="modifyCurrentLocation = !modifyCurrentLocation"
                  >
                    <v-icon x-small>
                      {{ !modifyCurrentLocation ? 'mdi-pencil-outline' : 'mdi-pencil-off-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="!modifyCurrentLocation">
                  {{ checkIn.current_location.name }}
                </div>
                <ValidationProvider
                  v-else
                  rules="required"
                  v-slot="{ errors }"
                  immediate
                >
                  <search-autocomplete
                    v-model="currentLocation"
                    api-path="/api/yards/"
                    :params="{ fields: 'id,url,name,full_address,status,logistics_location' }"
                    :format-entry="formatAddress"
                  />
                  <span
                    v-for="err in errors"
                    :key="err"
                    class="error--text"
                  >
                    {{ err }}
                  </span>
                </ValidationProvider>
                <div class="text-h6">
                  To Location
                  <v-btn
                    :disabled="!$store.getters.can('edit-check-in-yard')"
                    :color="modifyToLocation ? 'accent' : ''"
                    x-small
                    @click="modifyToLocation = !modifyToLocation"
                  >
                    <v-icon x-small>
                      {{ !modifyToLocation ? 'mdi-pencil-outline' : 'mdi-pencil-off-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="!modifyToLocation">
                  {{ checkIn.to_location.name }}
                </div>
                <ValidationProvider
                  v-else
                  rules="required"
                  v-slot="{ errors }"
                  immediate
                >
                  <search-autocomplete
                    v-model="destinationLocation"
                    api-path="/api/yards/"
                    :params="{ fields: 'id,url,name,full_address,status,logistics_location' }"
                    :format-entry="formatAddress"
                  />
                  <span
                    v-for="err in errors"
                    :key="err"
                    class="error--text"
                  >
                    {{ err }}
                  </span>
                </ValidationProvider>
                <div class="text-h6">
                  Lot
                  <v-btn
                    :disabled="!$store.getters.can('edit-check-in-lot')"
                    :color="modifyLot ? 'accent' : ''"
                    x-small
                    @click="modifyLot = !modifyLot"
                  >
                    <v-icon x-small>
                      {{ !modifyLot ? 'mdi-pencil-outline' : 'mdi-pencil-off-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="!modifyLot">
                  {{ checkIn.intended_lot ? checkIn.intended_lot.name : 'N/A' }}
                </div>
                <ValidationProvider
                  v-else
                  rules="required"
                  v-slot="{ errors }"
                  immediate
                >
                  <search-autocomplete
                    v-model="intendedLot"
                    api-path="/api/lots/"
                    :params="{ fields: 'url,id,name' }"
                    :format-entry="l => Object.assign({ friendlyName: l.name }, l)"
                  />
                  <span
                    v-for="err in errors"
                    :key="err"
                    class="error--text"
                  >
                    {{ err }}
                  </span>
                </ValidationProvider>
                <div class="text-h6">
                  External Ref
                  <v-btn
                    :color="modifyExternalReference ? 'accent' : ''"
                    x-small
                    @click="modifyExternalReference = !modifyExternalReference"
                  >
                    <v-icon x-small>
                      {{ !modifyExternalReference ? 'mdi-pencil-outline' : 'mdi-pencil-off-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="!modifyExternalReference">
                  {{ checkIn.customer_ref ? checkIn.customer_ref : 'N/A' }}
                </div>
                <rules-text
                  v-else
                  validation-rules="required|max:60"
                  v-model="externalReference"
                  counter="60"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="invalid"
                  color="primary"
                  type="submit"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validationobserver>
      </div>
    </v-container>
  </unauthorized>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'
import axios from 'axios'
import RulesText from '@/components/ValidationFields/RulesText.vue'

export default {
  name: 'CheckInModify',
  components: { RulesText, SearchAutocomplete },
  data: () => ({
    loading: true,
    currentLocation: null,
    destinationLocation: null,
    yard: null,
    intendedLot: null,
    externalReference: null,
    modifyCurrentLocation: false,
    modifyToLocation: false,
    modifyYard: false,
    modifyLot: false,
    modifyExternalReference: false
  }),
  computed: {
    breadcrumbs () {
      if (!this.checkIn) return []
      return [
        this.checkIn.purchase,
        this.checkIn.url,
        ['Modify']
      ]
    },
    ...mapGetters({
      checkIn: 'checkIn/edit/getCheckIn'
    })
  },
  methods: {
    ...mapActions({
      fetchCheckInDetailed: 'checkIn/edit/fetchCheckInDetailed'
    }),
    formatAddress (x) {
      return Object.assign(x, { friendlyName: `${x.name} | ${x.full_address} | ${x.status}` })
    },
    fetchCheckIn () {
      return this.fetchCheckInDetailed({ checkinID: this.$route.params.id })
    },
    addYard (payload) {
      // Validation current location
      if (this.modifyYard) {
        if (!this.yard) return 'Yard not selected'
        if (!this.yard.url) return 'Yard missing location data'
        payload.yard = this.yard.url
      }
    },
    addCurrentLocation (payload) {
      // Validation current location
      if (this.modifyCurrentLocation) {
        if (!this.currentLocation) return 'Current Yard Location not selected'
        if (!this.currentLocation.logistics_location) return 'Current Yard Location missing location data'
        payload.current_location = this.currentLocation.logistics_location
      }
    },
    addToLocation (payload) {
      // Validation to_location
      if (this.modifyToLocation) {
        if (!this.destinationLocation) return 'Destination Yard Location not selected'
        if (!this.destinationLocation.logistics_location) return 'Destination Yard Location missing location data'
        payload.to_location = this.destinationLocation.logistics_location
      }
    },
    addIntendedLot (payload) {
      if (this.modifyLot) {
        if (!this.intendedLot) return 'Lot not selected'
        if (!this.intendedLot.url) return 'Lot missing location data'
        payload.intended_lot = this.intendedLot.url
      }
    },
    addExternalReference (payload) {
      if (this.modifyExternalReference) {
        if (!this.externalReference) return 'External reference not selected'
        payload.customer_ref = this.externalReference
      }
    },
    saveCheckIn () {
      const payload = {}

      for (const method of [this.addCurrentLocation, this.addToLocation, this.addIntendedLot, this.addYard, this.addExternalReference]) {
        const err = method(payload)
        if (err) {
          this.$store.commit('setSnackbarError', err)
          return
        }
      }

      axios.patch(`/api/check_ins/${this.$route.params.id}/`, payload).then(response => {
        if (response?.status === 201) {
          this.$store.commit('setSnackbarInfo', 'Check In changes saved')
          return this.$router.push({ name: 'check-in', params: { id: this.$route.params.id } })
        } else {
          let message = 'Check In changes saved'
          if (response.data?.message) message = response.data.message
          console.log(response.data)
          this.$store.commit('setSnackbarError', message)
        }
      })
    }
  },
  mounted () {
    Promise.all([
      this.fetchCheckIn()
    ]).then(() => {
      this.loading = false
    })
  }
}
</script>
