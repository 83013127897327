<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col class="text-left col-md-4">
        <h2>
          Payment Disclosure
        </h2>
        <br>
        <div>
          As a customer of Phoenix Group Metals please understand that it is vitally important for
          our company to keep good records and keep track of all expenditures.
          <br>
          <br>
          Therefore, we must require all payees to provide a full, real, legible signature upon
          receipt of payment. You will not be issued a 1099, or any tax documentation from Phoenix
          Group Metals.
          <br>
          <br>
          Your signature confirms that you are duly and legally authorized to sell these catalytic
          converters.
          <br>
          <br>
          We thank you for your business, for your understanding, and for your cooperation in this
          matter.
          <br>
          <br>
          THIS SIGNATURE BY A PHOENIX GROUP METALS REPRESENTATIVE ASSURES THAT ALL
          INFORMATION ON THIS FORM IS TRUE AND CORRECT, THAT ALL MONIES WERE PAID TO THE
          "PAYEE" AND THAT THE NAME AND SIGNATURE PROVIDED ARE IN FACT ACTUAL, LEGIBLE AND
          CORRECT.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CashDisclaimer'
}
</script>

<style scoped>

</style>
