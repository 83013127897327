<template>
  <div
    class="cloudimage-360"
    data-keys
    data-autoplay
    data-full-screen
    data-autoplay-reverse
    data-spin-reverse
    data-bottom-circle
    data-control-reverse
    data-stop-at-edges
    :data-image-list="JSON.stringify(this.images)"
  />
</template>

<script>
export default {
  name: 'ConverterSpinner',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  beforeRouteLeave (to, from, next) {
    window.CI360.destroy()
    next()
  },
  mounted () {
    window.CI360.init()
  }
}
</script>
