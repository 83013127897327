var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"data":_vm.selected,"headers":_vm.tableHeaders,"items":_vm.value,"disable-pagination":"","hide-default-footer":"","single-select":"","selectable-key":"id","show-select":_vm.showSelect,"items-per-page":-1},on:{"click:row":function($event){return _vm.$emit('rowClicked', $event)},"input":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [(_vm.noNameLink)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('router-link-id',{attrs:{"name":"inventory-on-hand-box","id":item.source_id}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])])]},proxy:true}],null,true)},[_vm._v(" #"+_vm._s(item.source_id)+" ")])]}},{key:"item.location_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.location_name)+" "),_c('div',[_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.gps_number)+" ")])])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.weight))+" lbs ")]}},{key:"item.pieces",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.pieces))+" ")]}},{key:"item.contains",fn:function(ref){
var item = ref.item;
return _vm._l((item.contains),function(checkIn){return _c('div',{key:checkIn},[_vm._v(" "+_vm._s(checkIn)+" ")])})}},{key:"item.in_lots",fn:function(ref){
var item = ref.item;
return _vm._l((item.in_lots),function(lot){return _c('div',{key:lot},[_vm._v(" "+_vm._s(lot)+" ")])})}},{key:"item.buyers",fn:function(ref){
var item = ref.item;
return _vm._l((item.buyers),function(buyer){return _c('div',{key:buyer},[_vm._v(" "+_vm._s(buyer)+" ")])})}},{key:"item.manifest",fn:function(ref){
var item = ref.item;
return [(item.manifest_data)?_c('div',[(_vm.noNameLink)?_c('span',[_vm._v(" "+_vm._s(item.manifest_data.id)+" ")]):(item.manifest_data && !_vm.noNameLink)?_c('router-link-id',{attrs:{"name":"manifest-view","id":item.manifest_data.id}}):_vm._e()],1):_vm._e()]}},{key:"item.eff_start_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimeDate")(item.eff_start_datetime))+" ")]}}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }