<template>
  <v-dialog
    v-if="!noNextStatus"
    v-model="statusChangeDialog"
    persistent
    width="550"
    height="500"
  >
    <template v-slot:activator="{ on }">
      <slot :on="on">
        <v-btn
          icon
          x-small
          color="info"
          v-on="on"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title>
        Update Status
        <v-spacer />
        <base-button
          icon-bool
          icon="mdi-close"
          @clicked="statusChangeDialog = false"
        />
      </v-card-title>
      <v-card-text>
        Clicking update will change the status of this {{ srcType }}.
        <ul>
          <li
            v-for="(reason, index) in invalidStatusChangeReason"
            :key="index"
          >
            {{ reason }}
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        <b> Warning: </b> Once changed, the status cannot be changed back to the current status.
      </v-card-text>
      <h3
        v-if="nextStatus"
        class="text-center"
      >
        {{ `From ${status} to ${nextStatus.text}` }}
      </h3>
      <v-row
        class="mx-0"
        justify="center"
      >
        <base-button
          auto-center
          text
          @clicked="statusChangeDialog = false"
          color="error"
          label="Cancel"
        />
        <v-tooltip
          bottom
          :disabled="!invalidStatusChange"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <base-button
                auto-center
                text
                :loading="loading"
                :disabled="invalidStatusChange"
                @clicked="updateStatus"
                color="success"
                label="Update"
              />
            </span>
          </template>
          <span v-if="incompleteCheckIns">The purchase has incomplete check-ins</span>
          <span v-else-if="noCheckIns"> Purchase must have at least one check-in </span>
          <span v-else-if="needsSummary"> Status will change when a Shipment Summary is created</span>
        </v-tooltip>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StatusChangeDialog',
  props: {
    status: { type: String, required: true },
    purchase: { type: Object, required: false, default: null },
    lot: { type: Object, required: false, default: null },
    statuses: { type: Array, required: true },
    // 'lot' or 'purchase'
    srcType: { type: String, required: true },
    loading: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    statusChangeDialog: false,
    noNextStatus: false
  }),
  computed: {
    nextStatus () {
      const next = this.getNextStatus()
      if (!next) return null
      return next
    },
    invalidStatusChange () {
      if (this.incompleteCheckIns || this.noCheckIns || this.needsSummary) return true
      return false
    },
    invalidStatusChangeReason () {
      let reasons = []
      if (this.incompleteCheckIns) reasons.push('Has Incomplete CheckIns')
      if (this.noCheckIns) reasons.push('Has No CheckIns')
      if (this.needsSummary) reasons.push('Requires a Shipment Summary')
      return reasons
    },
    // If lot and status === 'RE', will be changed to 'RC' when shipment summary is created
    needsSummary () {
      if (this.srcType === 'purchase') return false
      if (this.status === 'Received by Smelter') return true
      return false
    },
    incompleteCheckIns () {
      if (this.srcType === 'lot') return false
      if (!this.nextStatus === 'RE') return false
      let v = this.purchase.check_ins.filter(c => c.status !== 'CH')
      if (v.length > 0) return true
      return false
    },
    noCheckIns () {
      if (this.srcType === 'lot') return false
      return !this.purchase.check_ins || this.purchase.check_ins.length < 1
    }
  },
  methods: {
    updateStatus () {
      this.$emit('updateStatus', this.nextStatus)
      this.statusChangeDialog = false
    },
    /**
     * Get the next status for a purchase or lot
     * @return {Object | Null}
     */
    getNextStatus () {
      for (let i = 0; i < this.statuses.length; i++) {
        const obj = this.statuses[i]
        if (obj.text === this.status && i + 1 < this.statuses.length) {
          return this.statuses[i + 1]
        }
      }
      return null
    },
    /**
     * Determine whether or not the user can update the status and that there is
     * a status after the current status
     */
    canUpdateStatus () {
      const nextStatus = this.getNextStatus()
      if (!nextStatus) this.noNextStatus = true
    }
  },
  mounted () {
    this.canUpdateStatus()
  }
}
</script>
