var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("activator",[_c('v-btn',{attrs:{"disabled":_vm.disabled,"loading":_vm.addingSkids,"small":_vm.isSmallBtn},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" Print Skids ")])],{"on":function () { return _vm.dialog = !_vm.dialog; },"disabled":_vm.disabled,"loading":_vm.addingSkids}),_vm._t("default",[_c('pdf-make-btn',{attrs:{"doc-def-builder":_vm.docDef,"styles":{
        packingSlipBody: {
          fontSize: 20
        },
        packingSlipBox: {
          fontSize: 32
        }
      }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('edit-dialog',{attrs:{"headline-text":"Print Skid Shipping Labels","accept-text":"Print"},on:{"accept":on},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-select',{attrs:{"items":_vm.configChoices,"return-object":"","item-text":"name","dense":"","label":"Skid Labeling Theme"},model:{value:(_vm.configChoice),callback:function ($$v) {_vm.configChoice=$$v},expression:"configChoice"}}),_c('v-checkbox',{attrs:{"dense":"","label":"Leave box numbers blank (write them in by hand)"},model:{value:(_vm.blankBoxNumbers),callback:function ($$v) {_vm.blankBoxNumbers=$$v},expression:"blankBoxNumbers"}}),_c('v-checkbox',{attrs:{"dense":"","label":"Leave box weights and pieces blank (write them in by hand)"},model:{value:(_vm.blankWeightsAndPieces),callback:function ($$v) {_vm.blankWeightsAndPieces=$$v},expression:"blankWeightsAndPieces"}}),_c('p',{staticClass:"body-1"},[_vm._v(" Which skids need labels? ")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.skidsWithBoxNumbers,"item-key":"box_number","disable-pagination":"","show-select":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('p',[_c('v-checkbox',{attrs:{"dense":"","label":"Redact shipper name and address from packing slips?"},model:{value:(_vm.anonymousShipper),callback:function ($$v) {_vm.anonymousShipper=$$v},expression:"anonymousShipper"}})],1)],1)]}}])})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }