
<template>
  <v-container>
    <url-breadcrumbs
      v-if="intake"
      :items="[
        ['intake list', { name: 'intake-list' }],
        intake.url
      ]"
    />
    <v-skeleton-loader
      :loading="loading"
      type="title,card@2"
    >
      <div v-if="intake && intakeLines.length">
        <base-title>
          {{ `Intake ID #${intake.id}` }}
        </base-title>
        <div>
          <v-row>
            <v-col class="text-right">
              <!-- delete -->
              <v-btn
                small
                v-if="$store.getters.can('delete-intakes')"
                class="mr-2"
                color="accent"
                :disabled="!correctPurStatus"
                @click="deleteDialog = true"
              >
                Delete
              </v-btn>
              <base-dialog
                v-model="deleteDialog"
                headline-text="Delete Intake"
                :message-text="`Are you sure you would like to delete intake ${intake.id}?`"
                decline-text="Cancel"
                accept-text="Delete"
                @accept="deleteIntake()"
              />
              <!-- resend emails -->
              <info-tooltip>
                <template v-slot:wrap-me>
                  <v-btn
                    color="accent"
                    @click="emailDialog = !emailDialog"
                    small
                  >
                    Re-Send Emails
                  </v-btn>
                </template>
                This will email everyone on the New Intake list
              </info-tooltip>
              <edit-dialog
                v-model="emailDialog"
                headline-text="Send the emails"
                accept-text="send"
                @accept="sendEmails(intake.id)"
              >
                Are you sure you would like to send the intake emails?
              </edit-dialog>
            </v-col>
          </v-row>
          <v-card>
            <v-card-subtitle>
              Intake Details
            </v-card-subtitle>
            <v-row class="ml-4">
              <card-list-item
                title="Dust Total"
                v-if="dustWeight !== 0"
              >
                {{ dustWeight | filterNA | trimDecimals | addCommas }} lbs
              </card-list-item>
              <card-list-item title="Created By">
                <span v-if="intake.created_by">
                  {{ intake.created_by.username }}
                </span>
              </card-list-item>
              <card-list-item title="Created At">
                {{ intake.created_at | formatDate }}
              </card-list-item>
              <card-list-item title="Notes">
                {{ intake.notes | filterNA | trimDecimals | addCommas }}
              </card-list-item>
              <card-list-item title="Reference">
                {{ intake.reference | filterNA }}
              </card-list-item>
              <card-list-item title="Catalytic Converter Total">
                {{ intake.converter_count | filterNA | trimDecimals | addCommas }} pcs
              </card-list-item>
              <card-list-item title="Total Intake Lines">
                {{ intake.line_total | filterNA | trimDecimals | addCommas }}
              </card-list-item>
            </v-row>
          </v-card>
          <br>
          <v-card>
            <v-card-subtitle>
              Intake Lines
            </v-card-subtitle>
            <v-data-table
              :headers="tableHeaders"
              :items="intakeLines"
            >
              <template v-slot:item.id="{ item }">
                {{ item.id }}
                <br>
                <router-link-id
                  v-if="item.box_sid"
                  name="inventory-on-hand-box"
                  :id="item.box_sid"
                >
                  {{ item.box_name }}
                </router-link-id>
              </template>
              <template v-slot:item.catalog="{ item }">
                {{ item.catalog | filterObject('name') }}
              </template>
              <template v-slot:item.measurement="{ item }">
                {{ item.catalog | filterObject('unit_of_measure') }}
              </template>
              <template v-slot:item.box_weight="{ item }">
                {{ item.box_weight | formatNumber }} lbs
              </template>
              <template v-slot:item.box_tare="{ item }">
                {{ item.box_tare | formatNumber }} lbs
              </template>
              <template v-slot:item.box_net="{ item }">
                {{ item.box_net | formatNumber }} lbs
              </template>
              <template v-slot:item.box_pieces="{ item }">
                <span v-if="item.box_pieces">
                  {{ item.box_pieces | formatNumber }}
                </span>
                <span v-else>
                  {{ item.count | formatNumber }}
                </span>
              </template>
              <template v-slot:item.lot="{ item }">
                {{ item | getByCategory(getLots) }}
              </template>
            </v-data-table>
          </v-card>
          <br>
          <v-expansion-panels
            accordion
            @change="loadCheckIns"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-card__subtitle font-weight-bold">
                <div>
                  Check-Ins
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-model="checkInExtraDetails"
                  label="Show Additional Details"
                />
                <v-data-table
                  :loading="checkInLoading"
                  :headers="checkInHeaders"
                  :items="checkInLines"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.purchase="{ item }">
                    <router-link-id
                      v-if="item.purchase"
                      :id="item.purchase"
                      target="_blank"
                      name="purchase"
                    >
                      {{ item.purchase }}
                    </router-link-id>
                  </template>
                  <template v-slot:item.check_in="{ item }">
                    <router-link-id
                      v-if="item.check_in"
                      :id="item.check_in"
                      target="_blank"
                      name="check-in"
                    >
                      {{ item.check_in }}
                    </router-link-id>
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | formatDate }}
                  </template>
                  <template v-slot:item.transits="{ item }">
                    <div
                      v-for="id in item.transits"
                      :key="id"
                    >
                      <router-link-id
                        :id="id"
                        target="_blank"
                        name="transit-view"
                      >
                        {{ id }}
                      </router-link-id>
                    </div>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th
                        v-for="header in checkInHeaders"
                        :key="header.value"
                        :class="{ 'text-right': header.value !== 'purchase' }"
                      >
                        {{ checkInTotals[header.value] }}
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="header in checkInHeaders"
                        :key="header.value"
                        :class="{ 'text-right': header.value !== 'purchase' }"
                      >
                        {{ checkInIntake[header.value] }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div v-else>
        Missing Data
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import BaseDialog from '@/base/components/BaseDialog'
import { fetchIntake, fetchIntakeLines } from '../../requests/intake'

function saveToLocalStorage () {
  localStorage.setItem('intakeView_checkInExtraDetails', this.checkInExtraDetails)
}

export default {
  name: 'Intake',
  components: {
    BaseDialog
  },
  data: () => ({
    intake: [],
    intakeLines: [],
    emailDialog: false,
    deleteDialog: false,
    loading: true,
    loadingIntakeLines: false,
    tableIntakeLines: [],
    options: {
      page: 1,
      itemsPerPage: 10
    },
    apiPage: 1,
    checkInLines: [],
    checkInTotals: {},
    checkInIntake: {},
    checkInLoading: false,
    checkInExtraDetails: false,
    tableHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Catalog', value: 'catalog' },
      { text: 'Count', value: 'count' },
      { text: 'Gross', value: 'box_weight' },
      { text: 'Tare', value: 'box_tare' },
      { text: 'Net', value: 'box_net' },
      { text: 'Pieces', value: 'box_pieces' },
      { text: 'Measurement', value: 'measurement' },
      { text: 'Lot', value: 'lot' }
    ],
    checkInHeadersData: [
      { text: 'Purchase', value: 'purchase' },
      { text: 'Check In', value: 'check_in' },
      { text: 'Cart', value: 'cart' },
      { text: 'Transit', value: 'transits' },
      { text: 'Created', value: 'created_at' },
      { text: 'Status', value: 'status' },
      { text: 'Yard', value: 'yard_name' },
      { text: 'OEM', value: 'OM_count', align: 'right' },
      { text: 'AM', value: 'AM_count', align: 'right' },
      { text: 'Diesel', value: 'DS_count', align: 'right' },
      { text: 'Bead', value: 'BD_count', align: 'right' },
      { text: 'DPF', value: 'DP_count', align: 'right' },
      { text: 'IF', value: 'IF_count', align: 'right' },
      { text: 'DF', value: 'DF_count', align: 'right' },
      { text: 'Generic', value: 'GN_count', align: 'right' },
      { text: 'Total', value: 'total_count', align: 'right' }
    ]
  }),
  filters: {
    getByCategory (item, lots) {
      if (item.catalog.converter) {
        let x = lots.find(l => l.type === item.catalog.converter.category)
        return x.lot
      }
    }
  },
  computed: {
    ...mapGetters({
      purchases: 'purchaseStore/purchases'
    }),
    checkInHeaders () {
      let headers = this.checkInHeadersData
      if (!this.checkInExtraDetails) {
        const exclude = ['transits', 'created_at', 'status']
        headers = headers.filter(x => !exclude.includes(x.value))
      }
      return headers
    },
    // purchase must be 'INTOOK' for intake to be deleted
    correctPurStatus () {
      return this.purchases.some(p => p.status === 'IN')
    },
    dustWeight () {
      return this.intake.count_total - this.intake.converter_count
    },
    // All material types in an intake go to the same lot
    getLots () {
      let pls = []
      this.purchases.forEach(p => {
        pls.push(p.purchase_lots_details)
      })
      let newArr = this.$store.getters['getLotTypes'].map(ct => {
        let rObj = {}
        rObj['type'] = ct.value
        rObj['lot'] = ''
        rObj['lot_id'] = ''
        pls.flat().forEach(p => {
          if (rObj.type === p.converter_type && !rObj.lot.includes(p.lot_name)) {
            rObj.lot = p.lot_name
            rObj.lot_id = p.lot_id
          }
        })
        return rObj
      })
      return newArr
    }
  },
  watch: {
    options: {
      handler () {
        this.getMoreIntakeLines()
      },
      deep: true
    },
    checkInExtraDetails: saveToLocalStorage
  },
  methods: {
    ...mapActions({
      sendEmails: 'intakeStore/sendEmails',
      fetchPurchases: 'purchaseStore/fetchPurchases',
      updatePurchase: 'purchaseStore/updatePurchase'
    }),
    async loadCheckIns () {
      this.checkInLoading = true
      const response = await axios.get(`/api/intakes/${this.intake.id}/check_ins/`)
      if (response.data) {
        this.checkInLines = response.data.lines
        this.checkInTotals = Object.assign({ purchase: 'Total' }, response.data.totals)
        this.checkInIntake = Object.assign({ purchase: 'Intake' }, response.data.intake)
      }
      this.checkInLoading = false
    },
    async fetchIntake (id) {
      const response = await fetchIntake(id)
      this.intake = response.data
    },
    async fetchIntakeLines (id) {
      const response = await fetchIntakeLines(id)
      this.intakeLines = response.data
    },
    // Delete intake and update purchase to reflect deletion
    deleteIntake () {
      axios.delete(this.intake.url).then(response => {
        if (response.status === 204) {
          for (let p of this.purchases) {
            let body = { id: p.id }
            body.params = { intake_id: null, status: 'RE' }
            this.updatePurchase(body)
          }
          this.$store.commit('setSnackbarSuccess', `Successfully deleted Intake ${this.intake.id}`)
          this.$router.push({ name: 'intake-list' })
        }
      })
    },
    loadFromLocalStorage () {
      if (localStorage.getItem('intakeView_checkInExtraDetails')) {
        this.checkInExtraDetails = localStorage.getItem('intakeView_checkInExtraDetails') === 'true'
      }
    }
  },
  mounted () {
    this.loadFromLocalStorage()
    const intakeId = this.$route.params.id
    Promise.all([
      this.fetchIntake(intakeId),
      this.fetchIntakeLines(intakeId),
      this.fetchPurchases({ intake__id: intakeId })
    ]).finally(() => {
      this.loading = false
    })
  }
}
</script>
