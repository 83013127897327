<!--
Super easy to use bar charts leveraging Chart helpers
chartComponent?

bar-chart
horizontal-bar-chart
line-chart
pie-chart
emit('openSettings')

title String
simpleData
[
      { key: 'jim', value: 3 },
      { key: 'John', value: 4 },
      { key: 'brown', value: 10 }
]
-->
<template>
  <div>
    <div class="subtitle-2">
      {{ title }}
      <v-btn
        icon
        @click="chartComponent = 'bar-chart'"
      >
        <v-icon>
          mdi-chart-box-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="chartComponent = 'horizontal-bar-chart'"
      >
        <v-icon>
          mdi-align-horizontal-left
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="chartComponent = 'line-chart'"
      >
        <v-icon>
          mdi-chart-timeline-variant
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="chartComponent = 'pie-chart'"
      >
        <v-icon>
          mdi-chart-pie
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="magnify = !magnify"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn
        color="accent"
        v-if="settingsBtn"
        icon
        @click="$emit('openSettings')"
      >
        <v-icon>
          mdi-cog-outline
        </v-icon>
      </v-btn>
    </div>
    <component
      :is="chartComponent"
      :chart-data="chartData"
    />
    <edit-dialog
      max-width="2000"
      v-model="magnify"
      :show-decline="false"
      :headline-text="title"
      accept-text="done"
    >
      <component
        v-if="magnify"
        :is="chartComponent"
        :chart-data="chartData"
      />
    </edit-dialog>
  </div>
</template>

<script>
import ChartHelpers from '../ChartHelpers'
import BarChart from './BarChart'
import HorizontalBarChart from './HorizontalBarChart'
import LineChart from './LineChart'
import PieChart from './PieChart'

export default {
  mixins: [ChartHelpers],
  components: { PieChart, LineChart, HorizontalBarChart, BarChart },

  name: 'SimpleChart',
  props: {
    initChart: {
      type: String,
      default: 'bar-chart'
    },
    settingsBtn: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    simpleData: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      chartComponent: this.initChart,
      magnify: false
    }
  },
  watch: {
    simpleData: {
      immediate: true,
      handler (data) {
        this.chartData = this.simpleToChartData(data)
      }
    }
  }
}
</script>
