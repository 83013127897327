<script>
import { Doughnut } from 'vue-chartjs'
export default {
  extends: Doughnut,
  name: 'PieceCountsDoughnut',
  props: {
    datasets: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.renderChart(this.datasets, this.options)
  }
}
</script>
