<template>
  <div>
    <v-img
      v-if="images.length === 0"
      style="background-color: #acacac"
      :src="photoGroup.primary_image_url"
      min-height="50"
      min-width="50"
      alt="Converter Cover Image"
      @click="fetchImageGroup"
    />
    <converter-spinner
      v-else-if="photoGroup.type === 'SP'"
      :images="images"
    />
    <div v-else>
      <v-img
        v-if="index === null"
        style="background-color: #acacac"
        :src="photoGroup.primary_image_url"
        min-height="50"
        min-width="50"
        alt="Converter Cover Image"
        @click="index = 0"
      />
      <vue-gallery-slideshow
        v-if="index !== null"
        :images="images"
        :index="index"
        @close="index = null"
      />
    </div>
  </div>
</template>

<script>
import ConverterSpinner from '@/base/components/ConverterSpinner'
import axios from 'axios'
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  name: 'ConverterImageViewer',
  components: { ConverterSpinner, VueGallerySlideshow },
  props: {
    photoGroup: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    index: 0,
    images: []
  }),
  methods: {
    fetchImageGroup () {
      // use images if already exist
      if (this.photoGroup.images) {
        this.images = this.photoGroup.images.map(i => i.image)
        return
      }
      // fetch images based on picture set url
      axios.get(this.photoGroup.url).then(r => {
        this.images = r.data.images.map(i => i.image)
      })
    }
  }
}
</script>
