<template>
  <unauthorized
    :authorized="$store.getters.can('view-intakes')"
    message="Not Authorized to view Intakes"
  >
    <base-title title="Intake List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Notes"
            v-model="notes"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <paginated-autocomplete
            v-model="yard"
            label="Yard"
            item-text="name"
            item-value="name"
            base-url="/api/yards/"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="intake"
        />
      </template>
      <template v-slot:item.check_ins="{ item }">
        <p
          v-for="ci in item.check_ins"
          :key="'ci' + ci.id"
        >
          <router-link-id
            class="mr-2"
            :id="ci.id"
            name="check-in"
          >
            {{ ci.id }}
          </router-link-id>
          {{ ci.yard.name }}
        </p>
      </template>
      <template v-slot:item.count_total="{ item }">
        {{ item.count_total | filterNA | trimDecimals | addCommas }}
      </template>
      <template v-slot:item.line_total="{ item }">
        {{ item.line_total | filterNA | trimDecimals | addCommas }}
      </template>
      <template v-slot:item.created_by="{ item }">
        {{ item.created_by | filterObject('username') }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-intakes')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="showCreateOptions = true"
    >
      Create Intake
    </text-fab>
    <div class="py-8" />
    <v-dialog
      v-model="showCreateOptions"
      max-width="600"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Create Intake Select
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="showCreateOptions = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-btn x-small>
            From Inventory
          </v-btn> allows a user to select boxes directly from inventory on hand. This creates lines of inventory directly on the lot.
          <br>
          <v-btn
            color="primary"
            x-small
          >
            By Purchase
          </v-btn> First purchases are selected, with each piece entered by category and assigned to a lot through it's purchase.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="$router.push({ name: 'create-intake-using-boxes' })"
          >
            From Inventory
          </v-btn>
          <v-btn
            color="primary"
            @click="$router.push({ name: 'create-intake' })"
          >
            By Purchase
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
// import pagination from 'pdfmake/src/documentContext'

export default {
  components: { ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'IntakeList',
  data: function () {
    return {
      showCreateOptions: false,
      pageName: 'intake-list',

      headers: [
        { text: 'Intake Number', sortable: true, value: 'id' },
        { text: 'Check Ins', sortable: false, value: 'check_ins' },
        { text: 'Notes', sortable: true, value: 'notes' },
        { text: 'Total Count', sortable: false, value: 'count_total' },
        { text: 'Total Lines', sortable: false, value: 'line_total' },
        { text: 'Created By', sortable: false, value: 'created_by' },
        { text: 'Created', sortable: false, value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' },
        { name: 'Yard', prop: 'yard', type: String, query: 'purchase__check_ins__yard__name__icontains' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    // pagination,
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        // 'url',
        'id',
        'check_ins',
        'count_total',
        'converter_count',
        'line_total',
        // 'purchase_set',
        'created_at',
        'created_by',
        'total_counts',
        'notes',
        'yard'
      ].join(',')
      axios.get('/api/intakes/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
