<template>
  <unauthorized
    :authorized="$store.getters.can('create-check-ins')"
    message="Not Authorized to Create Check Ins"
  >
    <v-container
      class="d-print-none"
    >
      <app-loading-indicator v-if="loading" />
      <h3 v-else-if="failedToLoad">
        Failed to Load
      </h3>
      <unauthorized
        v-else
        :authorized="checkIn.status !== 'CH'"
        :route="{ name: 'checkin', params: { id: checkIn.id } }"
        :route-message="`Click here to go back to check in #${checkIn.id}`"
        message="Check In has already been completed"
      >
        <url-breadcrumbs
          :items="[
            checkIn.purchase,
            checkIn.url,
            ['Edit', { name: 'edit-checkin', params: { id: checkIn.id } }],
            ['Complete', { name: 'complete-checkin', params: { id: checkIn.id } }]
          ]"
        />
        <base-title title="Check Out">
          <span slot="subtitle">
            <router-link
              :to="{ name: 'yard', params: { id: checkIn.yard.id } }"
            >
              {{ checkIn.yard.name }}
            </router-link>
          </span>
        </base-title>
        <v-btn
          :to="{ name: 'edit-checkin', params: { id: checkIn.id } }"
          bottom
          right
          fixed
          style="z-index: 99;"
          color="primary"
        >
          <v-icon> mdi-arrow-left </v-icon> Back To Edit
        </v-btn>
        <v-sheet class="my-4">
          <v-divider class="mb-4" />
          <!--Converter-->
          <div v-if="converterLines.length > 0">
            <h3 class="text-center">
              Checkin # {{ checkIn.id }}
            </h3>
            <v-sheet class="pa-2">
              <converter-full-value-table />
            </v-sheet>
          </div>
          <div v-if="converterLines.length === 0">
            <h3 class="text-center">
              No Converters on Check In
            </h3>
          </div>
        </v-sheet>
        <!--Skids Section-->
        <add-check-in-skids
          :skids="skids"
          :adding-skid="addingSkid"
          :check-in="checkIn"
          :updating-skid="updatingSkid"
          :deleting-skid="deletingSkid"
          @deleteSkid="deleteSkid"
          @updateSkid="updateSkid"
          @addSkid="addSkid"
        />
        <p
          class="text-center"
          v-if="checkDisabled"
        >
          <b>Press + to add at least one skid to continue</b>
        </p>
        <!--Lot Selection Section-->
        <select-intended-lot :check-in="checkIn" />
        <select-intended-hedge :check-in="checkIn" />
        <info-tooltip>
          <template v-slot:wrap-me>
            <v-btn
              :disabled="checkIn.status !== 'PR'"
              color="primary"
              class="mx-4"
              @click="emailAndExit"
            >
              send emails & exit
            </v-btn>
          </template>
          For Preliminary Check In's that will be completed later
        </info-tooltip>
        <pdf-make-btn
          include-pgm-header
          title="Print receipt"
          :doc-def-builder="pdfDoc"
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn @click="on">
              print
              <v-icon class="pl-2">
                mdi-printer
              </v-icon>
            </v-btn>
          </template>
        </pdf-make-btn>
        <!--Complete Check In button-->
        <complete-check-in-dialog
          @complete="completeCheckIn"
          :loading="updatingCheckIn"
          :show-message="checkDisabled"
          :disabled="checkDisabled"
        />
        <!--<export-complete-check-in :check-in-id="checkIn.id" />-->
      </unauthorized>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { checkInReceipt } from '@/helpers/pdfMake/checkInReceipt'
import AddCheckInSkids from './components/AddCheckInSkids'
import CompleteCheckInDialog from './components/CompleteCheckInDialog'
import ConverterFullValueTable from './components/ConverterFullValueTable'
import SelectIntendedLot from './components/SelectIntendedLot'
import PdfMakeBtn from '@/base/components/PdfMakeBtn'
import SelectIntendedHedge from '@/views/CheckIns/Complete/components/SelectIntendedHedge.vue'

export default {
  name: 'CompleteCheckIn',
  components: {
    SelectIntendedHedge,
    PdfMakeBtn,
    SelectIntendedLot,
    ConverterFullValueTable,
    AddCheckInSkids,
    // ExportCompleteCheckIn,
    CompleteCheckInDialog
  },
  data: () => ({
    loading: true,
    updatingCheckIn: false,
    addingCheckInSkids: false,
    failedToLoad: false,
    checkInSkids: [],
    addingSkid: false,
    updatingSkid: false,
    deletingSkid: false
  }),
  computed: {
    ...mapGetters({
      lines: 'checkIn/edit/getLines',
      checkIn: 'checkIn/edit/getCheckIn',
      totalCount: 'checkIn/edit/getAllCount',
      totalCost: 'checkIn/edit/getDollarTotal',
      skids: 'checkInSkidStore/checkInSkids'
    }),
    checkDisabled () {
      if (this.checkIn.dock_buy && this.skids.length === 0) return true
      return false
    },
    converterLines () {
      if (!this.checkIn.converter_lines) return []
      return this.checkIn.converter_lines.filter(cl => this.totalPieces(cl) !== 0).map((line) => {
        return { ...line, total_pieces: this.totalPieces(line) }
      })
    },
    checkInLines () {
      if (!this.checkIn.check_in_lines) return []
      return this.checkIn.check_in_lines.filter(cl => cl.count !== 0)
    },
    lineTotalCost () {
      if (!this.lines) return 0
      return Math.round(this.lines.reduce((total, line) => {
        return total + (line.count * parseFloat(line.price))
      }, 0))
    }
  },
  methods: {
    ...mapActions({
      fetchCheckInDetailed: 'checkIn/edit/fetchCheckInDetailed',
      updateCheckIn: 'checkIn/updateCheckIn',
      fetchCheckInSkids: 'checkInSkidStore/fetchCheckInSkids',
      deleteCheckInSkid: 'checkInSkidStore/deleteCheckInSkid',
      generateCheckInSkid: 'checkInSkidStore/generateCheckInSkid',
      updateCheckInSkid: 'checkInSkidStore/updateCheckInSkid',
      sendCheckInEmail: 'checkIn/sendCheckInEmail'
    }),
    pdfDoc () {
      return checkInReceipt(this.checkIn.id)
    },
    async emailAndExit () {
      this.sendCheckInEmail({ id: this.checkIn.id })
      return this.$router.push({ name: 'check-in', params: { id: this.checkIn.id } })
    },
    /**
     * Delete the given skid given its id
     * @param {Number} id
     */
    deleteSkid (id) {
      this.deletingSkid = true
      this.deleteCheckInSkid(id).then(() => {
        this.getSkids().then(() => { this.deletingSkid = false })
      })
    },
    /**
     * Add the given skid
     */
    addSkid () {
      this.addingSkid = true
      const params = { checkIn: this.checkIn, totalPieces: this.totalCount }
      this.generateCheckInSkid(params).then(() => {
        this.getSkids().then(() => { this.addingSkid = false })
      })
    },
    /**
     * Add the given skid
     * @param {Object} skid
     */
    updateSkid (skid) {
      this.updatingSkid = true
      if (this.checkIn.dock_buy) skid.weight = skid.expected_weight
      this.updateCheckInSkid(skid).then(() => {
        this.getSkids().then(() => { this.updatingSkid = false })
      })
    },
    getSkids () {
      return this.fetchCheckInSkids({ check_in_id: this.$route.params.id, page_size: 0 })
    },
    /**
     * Get a check in by it's id
     * @param {Integer} id
     */
    loadCheckIn (id) {
      Promise.all([
        this.fetchCheckInDetailed({ checkinID: id }),
        this.getSkids()
      ])
        .catch(() => { this.failedToLoad = true })
        .finally(() => { this.loading = false })
    },
    /**
     * Get the total quantity of pieces for a given converter line
     * @param {Object} converterLine
     */
    totalPieces (converterLine) {
      return ['qr', 'hl', 'tq', 'fl'].reduce((accu, unit) => {
        accu += parseInt(converterLine[`${unit}_count`])
        return accu
      }, 0)
    },
    /**
     * Update the check in and route user to payment
     */
    async completeCheckIn () {
      this.updatingCheckIn = true
      const formattedCheckInSkids = this.checkInSkids.map(skid => {
        const yardId = this.checkIn.yard.id
        return {
          expected_weight: skid.weight,
          expected_pieces: skid.pieces,
          origin_location_id: yardId,
          location_id: yardId,
          check_in: this.checkIn.url
        }
      })
      const params = {
        id: this.$route.params.id,
        checkInSkids: formattedCheckInSkids
      }
      // formData: { status: 'CH' },
      this.updateCheckIn(params).then(wasUpdated => {
        if (wasUpdated) this.$router.push({ name: 'payment-checkin', params: { id: params.id } })
        this.updatingCheckIn = false
      })
    }
  },
  mounted () {
    this.loadCheckIn(this.$route.params.id)
  }
}
</script>
