<template>
  <v-card class="mb-n2">
    <v-card-subtitle>
      Intake Information
    </v-card-subtitle>
    <div v-if="intake && $store.getters.can('view-intakes')">
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <card-list-item title="Intake Reference">
            <router-link :to="{ name: 'intake', params: { id: intake.id } }">
              {{ intake.reference || intake.id }}
            </router-link>
          </card-list-item>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <card-list-item title="Total Pieces">
            {{ intake.count_total | appendPcs }}
          </card-list-item>
        </v-col>
      </v-row>
    </div>
    <card-list-item
      title="Cannot add intake without check-in"
      v-else-if="purchase.check_ins.length === 0"
    />
    <card-list-item
      title="Cannot add intake with incomplete check-ins"
      v-if="incompleteCheckIn"
    />
    <card-list-item
      title="Purchase must be received to add intake"
      v-if="notInReceived"
    />
    <div v-if="canAddIntake">
      <card-list-item title="No Intakes Found" />
      <v-btn
        :to="{ name: 'create-intake', params: { purchase: this.purchase } }"
        fab
        small
        bottom
        right
        absolute
        color="primary"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'IntakeSection',
  props: {
    purchase: { type: Object, required: true }
  },
  computed: {
    intake () {
      return this.purchase.intake
    },
    incompleteCheckins () {
      return this.purchase.check_ins.filter(c => c.status !== 'CH')
    },
    incompleteCheckIn () {
      if (this.intake) return false
      if (this.purchase.check_ins.length === 0) return false
      if (this.incompleteCheckins.length > 0) return true
      return false
    },
    notInReceived () {
      if (this.intake) return false
      if (this.purchase.check_ins.length === 0) return false
      if (this.incompleteCheckins.length === 0 && this.purchase.status === 'OP') return true
      return false
    },
    canAddIntake () {
      return !this.intake && this.$store.getters.can('create-intakes') && this.purchase.status === 'RE'
    }
  }
}
</script>
