<template>
  <edit-dialog
    :value="value"
    @accept="createIntake"
    @input="$emit('input', $event)"
  >
    <div>
      <ul>
        <li
          v-for="(box, index) in boxChanges.toDelete"
          :key="index"
        >
          {{ index }} - <span class="red--text">DELETE</span>
        </li>
        <li
          v-for="(box, index) in boxChanges.toUpdate"
          :key="index"
        >
          {{ index }} - <span class="orange--text">CHANGE</span>
        </li>
        <li
          v-for="(box, index) in boxChanges.toCreate"
          :key="index"
        >
          {{ index }} - <span class="green--text">CREATE</span>
        </li>
      </ul>
    </div>
  </edit-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { addToIntakeFromInventory, createIntake } from '../../../requests/intake'
import store from '../../../store'

export default {
  name: 'CreateIntakeConfirmation',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    notes: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('intakeBoxStore', [
      'boxChanges'
    ]),
    ...mapState('intakeBoxStore', [
      'inputBoxes',
      'outputBoxes',
      'location'
    ])
  },
  methods: {
    async createIntake () {
      const res1 = await createIntake({ notes: this.notes })
      if (!res1.data?.id) {
        store.commit('setSnackbarError', 'Failed to create intake, aborting.')
        return
      }
      const outputBoxes = this.outputBoxes.map(box => {
        box.location = this.location
        return box
      })
      const res2 = await addToIntakeFromInventory(res1.data.id, this.inputBoxes, outputBoxes)
      if (res2.data) {
        this.$store.commit('intakeBoxStore/clearStore')
        this.$router.push({ name: 'intake', params: { id: res2.data.id } })
      } else {
        this.$store.commit('setSnackbarError', `Unable to complete inventory changes for intake ${res1.data.id}`)
      }
    }
  }
}
</script>
