<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="550"
    height="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :color="skid.gps_number ? 'accent' : ''"
        v-on="on"
      >
        <v-icon> {{ skid.gps_number ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-question' }} </v-icon>
      </v-btn>
    </template>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <v-card class="pb-4 px-4">
        <v-card-title>
          Update Status
          <v-spacer />
          <base-button
            icon-bool
            icon="mdi-close"
            @clicked="closeDialog()"
          />
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mx-4 mb-4"
              label="GPS Number"
              type="number"
              v-model="gpsNumber"
              :error-messages="errors"
            />
          </ValidationProvider>
          <div class="text-center">
            <v-btn
              v-if="skid.gps_number && !addingGps"
              :disabled="updatingSkid"
              :loading="updatingSkid && !addingGps"
              class="mr-4"
              color="error"
              @click="removeGps"
            >
              Remove GPS Number
            </v-btn>
            <v-tooltip
              bottom
              :disabled="!invalid"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    :disabled="updatingSkid || invalid"
                    :loading="updatingSkid && !removingGps"
                    color="success"
                    @click="addGps"
                  >
                    {{ skid.gps_number ? 'Update' : 'Add' }} GPS Number
                  </v-btn>
                </span>
              </template>
              <span> Invalid gps number </span>
            </v-tooltip>
          </div>
        </v-card-text>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  name: 'GpsNumberDialog',
  props: {
    skid: { type: Object, required: true },
    updatingSkid: { type: Boolean, required: true }
  },
  data: () => ({
    gpsNumber: null,
    wasUpdating: false,
    dialog: false,
    removingGps: false,
    addingGps: false
  }),
  watch: {
    updatingSkid () {
      if (this.updatingSkid) this.wasUpdating = true
      if (!this.updatingSkid && this.wasUpdating) this.closeDialog()
    },
    dialog () {
      this.gpsNumber = this.skid.gps_number
    }
  },
  methods: {
    /**
     * Remove the gps number from the given skid
     */
    removeGps () {
      this.removingGps = true
      this.$emit('setGpsNumber', { gpsNumber: null, skid: this.skid })
    },
    /**
     * Add the gps number to the given skid
     */
    addGps () {
      this.addingGps = true
      this.$emit('setGpsNumber', { gpsNumber: this.gpsNumber, skid: this.skid })
    },
    /**
     * Close the dialog and reset values
     */
    closeDialog () {
      this.addingGps = false
      this.removingGps = false
      this.gpsNumber = null
      this.dialog = false
    }
  }
}
</script>
