import { Pie, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      default: () => ({
        tooltips: {
          enabled: true
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0
              let dataArr = ctx.chart.data.datasets[0].data
              dataArr.map(data => {
                sum += data
              })
              let percentage = (value * 100 / sum).toFixed(2) + '%'
              return percentage
            }
          }
        }
      })
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
