<template>
  <v-card>
    <v-card-subtitle>
      Customer Details
    </v-card-subtitle>
    <v-row>
      <card-list-item title="Name">
        {{ checkIn.customer_name | filterNA }}
      </card-list-item>
      <card-list-item title="Email">
        {{ checkIn.customer_email | filterNA }}
      </card-list-item>
    </v-row>
    <v-subheader v-if="checkIn.customer_signature">
      Signature
    </v-subheader>
    <v-list-item
      class="pb-2"
      v-if="checkIn.customer_signature"
    >
      <a
        :href="checkIn.customer_signature"
        target="_blank"
      >
        <v-img
          max-height="200"
          max-width="200"
          contain
          :src="checkIn.customer_signature"
          class="grey lighten-2"
          target="_blank"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </a>
    </v-list-item>
    <v-list-item>
      <help-dialog
        headline-text="Disclosure"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click="on"
          >
            View Included Disclaimer
          </v-btn>
        </template>
        <cash-disclaimer />
      </help-dialog>
    </v-list-item>
  </v-card>
</template>

<script>
import CashDisclaimer from './CashDisclaimer'
export default {
  name: 'CustomerSection',
  components: { CashDisclaimer },
  props: {
    checkIn: { type: Object, required: true }
  }
}
</script>
