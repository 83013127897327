<template>
  <v-simple-table
    v-if="invoice"
    dense
    class="text-right subtitle-2"
  >
    <thead>
      <tr>
        <th>Item - Category</th>
        <th />
        <th class="text-right">
          Pieces
        </th>
        <th
          v-if="showFullValue"
          class="text-right"
        >
          Value
        </th>
        <th class="text-right">
          Total Prices
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in lines"
        :key="index"
        :bgcolor="(!row.item && !$vuetify.theme.dark) ? 'lightgrey' : ''"
        :class="{ 'primary-row': !!row.item, 'font-italic': !row.item, 'sub-row': !row.item}"
      >
        <td class="text-left">
          {{ row.item }}
        </td>
        <td v-if="row.name">
          {{ row.name }}
        </td>
        <td v-else>
          ${{ row.price }}
        </td>
        <td v-if="showFullValue">
          {{ row.pieces }}
        </td>
        <td>
          {{ row.value }}
        </td>
        <td class="end-column">
          <span v-if="!!row.item">$</span>
          {{ row.total }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr
        v-for="(row, index) in footerRows"
        :key="index"
        class="subtitle-1 font-weight-black"
      >
        <td
          class="text-left"
        >
          {{ row.item }}
        </td>
        <td>
          <span v-if="!showFullValue && showAverage(row.identity)">
            {{ row.value_average }}
          </span>
        </td>
        <td>
          {{ showFullValue ? row.pieces : row.values }}
        </td>
        <td v-if="showFullValue">
          <span v-if="showAverage(row.identity)">
            {{ row.average }}
          </span>
        </td>
        <td>
          $ {{ row.total }}
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ConverterFullValueTable',
  data: () => ({
    invoice: null
  }),
  methods: {
    showAverage (identity) {
      if (identity === 'grand-total') return false
      if (identity === 'other-total') return false
      if (identity === 'converter-total' && !this.invoice.formatting.show_overall_average) {
        return false
      }
      return true
    }
  },
  computed: {
    footerRows () {
      return this.invoice.footer.concat(this.invoice.footer_totals)
    },
    showFullValue () {
      return this.invoice.formatting.show_full_value
    },
    lines () {
      let lines = []
      this.invoice.lines.map(line => {
        lines.push(line)
        if (this.showFullValue) {
          line.breakdown.map(breakdownLine => {
            lines.push(breakdownLine)
          })
        }
      }, [])
      this.invoice.other_lines.map(line => {
        lines.push(line)
      }, [])
      return lines
    }
  },
  async mounted () {
    const id = this.$route.params.id
    const result = await axios.get(`/api/check_ins/${id}/invoice/`)
    this.invoice = result.data
  }
}
</script>

<style scoped>
  table td {
    border: 1px solid #bababa;
  }
  tr.primary-row {
    border-left: 1px green !important;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  tr.sub-row td {
    font-size: 0.8em;
  }
  td.end-column {
    border-left: 1px solid;
  }
</style>
