<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      @input="$emit('input', false)"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <base-button
            icon-bool
            small
            icon="mdi-close"
            styles="mt-4"
            color="white"
            @clicked="$emit('input', false)"
          />
          <v-toolbar-title>Pricing Config</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-card>
            <v-card-title>
              Check Out Settings
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn-toggle
                    dense
                    tile
                    :loading="updatingSetting"
                    v-model="showFullValueInt"
                    @change="updateShowFullValue"
                  >
                    <v-btn
                      small
                      text
                    >
                      Show
                    </v-btn>
                    <v-btn
                      text
                      small
                    >
                      Hide
                    </v-btn>
                  </v-btn-toggle>
                  Pieces in Checkout
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn-toggle
                    dense
                    tile
                    :loading="updatingSetting"
                    v-model="showOverallAverageInt"
                    @change="updateOverallAverage"
                  >
                    <v-btn
                      small
                      text
                    >
                      Show
                    </v-btn>
                    <v-btn
                      text
                      small
                    >
                      Hide
                    </v-btn>
                  </v-btn-toggle>
                  Overall Average (footer/complete)
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>
            <!--List of converters these are auto repriced OR manual-->
            <v-card-title>
              Converter Line Pricing
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent=""
                v-model="percentOk"
              >
                <!--percentage is deducted from the pricing -->
                <v-text-field
                  v-model="spread"
                  label="percentage of spread"
                  type="number"
                  hint="Spread should not fall below 10"
                  :loading="loading"
                  :rules="percentRules"
                  append-outer-icon="mdi-percent-outline"
                />
                <small>
                  <strong>
                    example:
                  </strong>
                  200 - (200 * {{ Number(spread).toFixed(2) }} / 100) = {{ 200 - (200 * (Number(spread).toFixed(2) / 100)) }}
                </small>
              </v-form>
              <div class="d-flex justify-end mt-4">
                <v-btn
                  outlined
                  small
                  @click="setLatestPricingZero"
                >
                  <span v-if="$vuetify.breakpoint.mdAndUp">set pricing to zero</span>
                  <span v-else>zero prices</span>
                </v-btn>
                <v-btn
                  :loading="loading"
                  class="ml-2"
                  color="primary"
                  small
                  @click="fetchConverterPrices"
                >
                  get latest pricing
                </v-btn>
              </div>
            </v-card-text>
            <v-subheader>(repriced based on current market)</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row
                    class="caption"
                  >
                    <v-col cols="5">
                      Converter
                    </v-col>
                    <v-col
                      cols="3"
                      class="text-right"
                    >
                      Current Price
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="2">
                      New Price
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <result-pagination
                v-model="page"
                :pages="pageCount"
              />
              <pricing-dialog-converter
                v-for="converterLine in visibleConverters"
                :key="converterLine.url"
                :converter-line="converterLine"
              />
              <result-pagination
                v-model="page"
                :pages="pageCount"
              />
            </v-list>
            <v-card-actions
              right
            >
              <v-spacer />
              <v-btn
                :loading="loading"
                color="primary"
                @click="acceptMarketRateAndPricing"
              >
                update pricing
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            class="mx-auto my-5"
            max-width="800"
          >
            <v-list>
              <v-card-title>
                Catalog Line Pricing
              </v-card-title>
              <!--List of lines these are repriced manually only-->
              <pricing-dialog-catalog
                v-for="line in getLines"
                :key="line.url"
                :catalog-line="line"
              />
            </v-list>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PricingDialogConverter from './PricingDialogConverter'
import PricingDialogCatalog from './PricingDialogCatalog'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  name: 'PricingDialog',
  components: {
    ResultPagination,
    PricingDialogCatalog,
    PricingDialogConverter
  },
  props: {
    value: { type: Boolean, default: false },
    checkIn: { type: Object, required: true }
  },
  data: () => ({
    percentOk: true,
    page: 1,
    pageLength: 25,
    loading: false,
    spread: 15,
    marketKeys: [
      ['Platinum', 'pt_market'],
      ['Palladium', 'pd_market'],
      ['Rhodium', 'rh_market']
    ],
    updatingSetting: false,
    showFullValueInt: 0,
    showOverallAverageInt: 0
  }),
  methods: {
    /**
     * Accepts the current market rate and pricing and spread
     */
    acceptMarketRateAndPricing () {
      this.loading = true
      if (!this.getLatestMarketRate) {
        this.$store.commit('setSnackbarError', 'Market Rate is not ready')
        return
      }
      let confirmed = confirm('All converter line prices will be updated, is this ok?')
      if (confirmed) {
        this.commitLatestPricing().then(() => {
          const payload = { marketRateUrl: this.getLatestMarketRate['url'], spread: Number(this.spread).toFixed(2) }
          this.patchCheckIn(payload).then(() => {
            this.loading = false
          })
        })
      }
    },
    fetchPricing () {
      this.fetchLatestMarketRate()
      this.fetchConverterPrices()
    },
    // fetches converter prices for each line based on the spread
    fetchConverterPrices () {
      if (!this.percentOk) {
        this.$store.commit('setSnackbarError', 'Unable to price converters at current margin')
        return
      }
      this.loading = true
      return this.fetchLatestPrices({ spread: this.spread }).finally(() => {
        this.loading = false
      })
    },
    indicatorType (oldValue, newValue) {
      if (oldValue === newValue) return 'none'
      if (oldValue > newValue) return 'down'
      return 'up'
    },
    /**
     * Indicator for market rate changes
     * @param oldValue
     * @param newValue
     */
    indicator (oldValue, newValue) {
      oldValue = Number(oldValue)
      newValue = Number(newValue)
      const i = this.indicatorType(oldValue, newValue)
      if (i === 'up') return '+' + String(newValue - oldValue)
      if (i === 'down') return '-' + String(oldValue - newValue)
      return '--'
    },
    /**
     * Changes text color based on up or down
     * @param oldValue
     * @param newValue
     * @returns {{'success--text': boolean}|{'error--text': boolean}|{}}
     */
    indicatorStyle (oldValue, newValue) {
      const i = this.indicatorType(Number(oldValue), Number(newValue))
      if (i === 'up') return { 'success--text': true }
      if (i === 'down') return { 'error--text': true }
      return {}
    },
    updateShowFullValue () {
      this.updatingSetting = true
      this.patchCheckIn({ showFullValue: this.showFullValue })
        .then(() => { this.updatingSetting = false })
    },
    updateOverallAverage () {
      this.updatingSetting = true
      this.patchCheckIn({ showOverallAverage: this.showOverallAverage })
        .then(() => { this.updatingSetting = false })
    },
    ...mapMutations('checkIn/edit', [
      'setLatestPricingZero'
    ]),
    ...mapActions('checkIn/edit', [
      'fetchLatestMarketRate',
      'fetchLatestPrices',
      'commitLatestPricing',
      'patchCheckIn',
      'fetchPricingMinPercent'
    ])
  },
  computed: {
    // changes min pricing percentage depending on user group
    percentRules () {
      const minimum = this.getMinPercent
      return [
        v => (v < 100 && v >= minimum) || `Percent must be less then 100 and greater then or equal to ${minimum}`
      ]
    },
    pageCount () {
      return Math.ceil(this.getConverters.length / this.pageLength)
    },
    visibleConverters () {
      const start = (this.page - 1) * this.pageLength
      const end = start + this.pageLength
      let lineNumber = start
      return this.getConverters.slice(start, end).map(cv => {
        cv.lineNumber = ++lineNumber
        return cv
      })
    },
    showOverallAverage: {
      get () {
        return this.showOverallAverageInt === 0
      },
      set (value) {
        if (value === true) {
          this.showOverallAverageInt = 0
        } else {
          this.showOverallAverageInt = 1
        }
      }
    },
    showFullValue: {
      get () {
        return this.showFullValueInt === 0
      },
      set (value) {
        if (value === true) {
          this.showFullValueInt = 0
        } else {
          this.showFullValueInt = 1
        }
      }
    },
    ...mapGetters('checkIn/edit', [
      'getMarketRate',
      'getLatestMarketRate',
      'getConverters',
      'getLines',
      'getLatestPricing',
      'getMinPercent'
    ])
  },
  watch: {
    value: function (val) {
      if (val) this.fetchPricing()
    }
  },
  mounted () {
    this.showFullValue = this.checkIn.show_full_value
    this.showOverallAverage = this.checkIn.show_overall_average
    this.spread = this.checkIn.pricing_spread
  }
}
</script>
