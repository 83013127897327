<template>
  <v-card>
    <v-card-title>
      Select lot designations
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        :items="getAvailableLots"
        item-text="name"
        item-value="url"
        return-object
        required
        placeholder="Select for all"
        @input="selectForAll"
      />
      <v-row
        v-for="cv in converterTypes"
        :key="cv.value"
      >
        <v-col
          :cols="2"
        >
          <v-chip
            :color="getColorByConverterCategory(cv.value)"
          >
            {{ cv.value }}
          </v-chip>
        </v-col>
        <v-col
          :cols="4"
          class="text-left"
        >
          {{ cv.text }}
        </v-col>
        <v-col
          :cols="6"
        >
          <v-autocomplete
            :items="getAvailableLots"
            v-model="allocations[cv.value]"
            item-text="name"
            return-object
            required
            placeholder="Select a Lot"
          />
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn
          :disabled="!submittable"
          color="success"
          @click="$emit('selected', allocations)"
        >
          Done
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AllocationSelect',
  data: () => ({
    allocations: {
      'AM': null,
      'GN': null,
      'IF': null,
      'DF': null,
      'OM': null,
      'DP': null,
      'DS': null,
      'BD': null
    }
  }),
  computed: {
    ...mapGetters({
      converterTypes: 'getConverterTypes',
      getAvailableLots: 'purchaseStore/getAvailableLots'
    }),
    submittable () {
      if (this.getAvailableLots.length === 0) return true
      return (this.allocations.AM &&
        this.allocations.GN &&
        this.allocations.IF &&
        this.allocations.DF &&
        this.allocations.OM &&
        this.allocations.DP &&
        this.allocations.DS &&
        this.allocations.BD)
    }
  },
  methods: {
    selectForAll (value) {
      for (const key in this.allocations) {
        this.allocations[key] = value
      }
    },
    /**
     * Assign color based on category
     * @param {Object} line
     * @return {String}
     */
    getColorByConverterCategory (key) {
      if (key === 'AM') return '#E27D60'
      else if (key === 'IF') return '#A9AF35'
      else if (key === 'DF') return '#1CE45B'
      else if (key === 'OM') return '#E8A87C'
      else if (key === 'DP') return '#C38D9E'
      else if (key === 'DS') return '#41B3A3'
      else if (key === 'GN') return 'info'
      else if (key === 'BD') return 'accent'
      else return 'primary'
    }
  }
}
</script>
