<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!prStatusOk"
        color="primary"
        small
        v-bind="attrs"
        v-on="on"
      >
        Delete
      </v-btn>
    </template>
    <v-toolbar dark>
      <v-toolbar-title>
        Delete Purchase
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <p class="body-1">
          Are you sure you would like to delete this purchase <strong>{{ purchase.id }}</strong> and related all Check
          Ins?
        </p>
        <p
          v-show="error"
          class="error--text"
        >
          <strong>
            {{ error }}
          </strong>
        </p>
        <v-list>
          <v-list-item
            v-for="ci in purchase.check_ins"
            :key="ci.id"
          >
            <p :class="{ 'error--text': ci.status === 'CH' }">
              {{ ci.id }} {{ ci.status }}
              <span v-if="ci.status === 'CH'">
                only purchases with open check ins may be deleted
              </span>
            </p>
          </v-list-item>
        </v-list>
        <v-text-field
          v-model="confirmText"
          type="text"
          label="Please type `delete` to proceed"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!!error"
          color="error"
          @click="deletePurchaseAndCheckIns"
        >
          DELETE PURCHASE & CHECK INS
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PurchaseDeleteDialog',
  props: {
    purchase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      confirmText: ''
    }
  },
  computed: {
    prStatusOk () {
      return this.purchase.status === 'OP'
    },
    ciStatusOk () {
      // return false if any purchase is not OP
      return typeof this.purchase.check_ins.find(ci => ci.status === 'CH') === 'undefined'
    },
    // user has typed delete in confirm text area
    confirmed () {
      return this.confirmText.toLowerCase() === 'delete'
    },
    // conditions are clear for deleting
    error () {
      if (!this.$store.getters.can('delete-purchase')) return 'NOT AUTHORIZED'
      if (!this.prStatusOk) return 'purchase must be open'
      if (!this.ciStatusOk) return 'check_ins must all be open'
      if (!this.confirmed) return 'please fill out confirm text'
      return false
    }
  },
  methods: {
    // delete you monster
    deletePurchaseAndCheckIns () {
      axios.delete(this.purchase.url).then(response => {
        if (response.status === 204) {
          let id = this.purchase.id
          this.$store.commit('setSnackbarSuccess', `Successfully deleted Purchase ${id}`)
          this.$router.push({ name: 'purchase-list' })
        }
      })
    }
  }
}
</script>
