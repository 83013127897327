<template>
  <unauthorized
    :authorized="$store.getters.can('view-dump-reports')"
    message="Unauthorized to view Hedges"
  >
    <div>
      <base-title :title="title" />
      <v-row>
        <v-col>
          <v-btn
            @click="columnsDialog = !columnsDialog"
          >
            Options
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-data-table
                v-if="ready"
                :headers="headers"
                :items="formattedExportData"
                dense
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            :loading="!ready"
            color="primary"
          >
            <download-excel
              :data="formattedExportData"
              type="csv"
            >
              Download CSV Data
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>
      <edit-dialog
        v-model="columnsDialog"
        headline-text="Report Settings"
        :show-decline="false"
        accept-text="Ok"
      >
        <p class="subtitle-1">
          Columns
        </p>
        <v-list>
          <v-list-item>
            <v-btn
              @click="showAllFields"
            >
              All
              <v-icon>
                mdi-checkbox-marked
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              @click="showNoFields"
            >
              None
              <v-icon>
                mdi-checkbox-blank
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item
            v-for="(field, index) in possibleFields"
            :key="index"
          >
            <v-checkbox
              :label="field.text"
              v-model="field.visible"
            />
          </v-list-item>
        </v-list>
      </edit-dialog>
    </div>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import reportMixin from '@/base/filter/reportMixin'

export default {
  mixins: [chipsMixin, reportMixin],
  name: 'DataDumpReport',
  data: () => ({
    columnsDialog: false
  }),
  mounted () {
    if (this.action && !this.$store.getters.can(this.action)) {
      this.$router.push({ name: '403' })
    } else {
      this.reflectQueryToChipProps()
      this.executeChipsQuery(this.setExportData)
    }
  }
}
</script>
