<!--
Entirely responsible for retrieving and rendering attached check ins
-->
<template>
  <v-card>
    <v-card-subtitle>
      {{ title }}
      <info-tooltip>
        Piece counts are assigned to lots during intake ( ONLY COMPLETED )
      </info-tooltip>
    </v-card-subtitle>
    <v-row v-if="checkIns.length > 0 && totalCats > 0">
      <v-col
        cols="12"
        md="6"
      >
        <v-simple-table v-if="!loading">
          <thead>
            <tr>
              <th>Type</th>
              <th>Lot</th>
              <th class="text-right">
                Check-In Count
              </th>
              <th class="text-right">
                Intake Count
              </th>
            </tr>
          </thead>
          <tbody>
            <piece-counts-row
              v-for="row in formattedRows"
              :key="row.key"
              :type="row.key"
              :purchase-lot="row.purchaseLot"
              :name="row.name"
              :count="row.count"
              :intake-count="row.icounts"
            />
          </tbody>
          <tfoot>
            <tr
              v-for="(count, index) in formattedFooter"
              :key="index"
            >
              <td class="text-uppercase">
                <strong>{{ count.name }}</strong>
              </td>
              <td />
              <td class="text-right">
                <strong>{{ count.count }}</strong>
              </td>
              <td
                class="text-right"
              >
                <strong>{{ count.icounts }}</strong>
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>
      <v-col
        v-if="!hideChart"
        cols="12"
        md="6"
      >
        <p class="text-center">
          <b>Check-In Counts</b>
        </p>
        <div style="max-width: 300px; margin:  50px auto;">
          <piece-counts-doughnut
            :datasets="chartCounts"
          />
        </div>
      </v-col>
    </v-row>
    <card-list-item
      title="No Converters Found"
      v-else-if="totalCats < 1"
      class="text-center"
    />
    <card-list-item
      title="No Check-Ins Attached"
      v-else
      class="text-center"
    />
  </v-card>
</template>

<script>
import ColorHash from 'color-hash'
import { mapActions, mapGetters } from 'vuex'
import PieceCountsDoughnut from './PieceCountsDoughnut'
import PieceCountsRow from './PieceCountsRow'

export default {
  name: 'PieceCountsSection',
  components: {
    PieceCountsRow,
    PieceCountsDoughnut
  },
  props: {
    checkIns: { type: Array, required: true },
    intakeCounts: { type: Object, required: false, default: null },
    purchaseLots: { type: Array, required: true },
    pieceCounts: { type: Object, required: true },
    hideChart: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: 'Piece Count Information' }
  },
  data: () => ({
    loading: false,
    dialog: true,
    selectedLine: null
  }),
  computed: {
    ...mapGetters({
      converterTypes: 'getConverterTypes'
    }),
    /**
     * Get the total number of converters for this purchase
     */
    totalCats () {
      return Object.values(this.pieceCounts).reduce((accu, count) => {
        accu += parseFloat(count)
        return accu
      }, 0)
    },
    formattedCounts () {
      let rows = []
      for (const key in this.pieceCounts) {
        let keyName = this.converterTypes.find(ps => ps.value === key)
        let purchaseLot = this.purchaseLots.find(pl => pl.converter_type === key)
        rows.push({
          key,
          name: keyName ? keyName.text : key,
          purchaseLot,
          count: this.pieceCounts[key],
          icounts: 0
        })
      }
      if (this.intakeCounts) {
        for (let [key, value] of Object.entries(this.intakeCounts)) {
          rows.forEach(row => {
            if (row.key === key) row.icounts = value
          })
        }
      }
      return rows
    },
    formattedRows () {
      return this.formattedCounts.filter(row => row.name !== 'other' && row.name !== 'total')
    },
    formattedFooter () {
      return this.formattedCounts.filter(row => row.name === 'total')
    },
    chartCounts () {
      let data = []
      let labels = []
      this.formattedRows.forEach(row => {
        labels.push(row.name)
        data.push(row.count)
      })
      let colorHash = new ColorHash()
      return {
        datasets: [{
          data,
          backgroundColor: labels.map(label => colorHash.hex(label) + '33'),
          borderColor: labels.map(label => colorHash.hex(label)),
          hoverBorderColor: 'rgba(0, 0, 0, 0.2)',
          borderWidth: 3
        }],
        labels
      }
    }
  },
  methods: {
    ...mapActions('purchaseStore', [
      'fetchAvailableLots',
      'fetchAssignedLots'
    ])
  },
  async mounted () {
    this.loading = true
    await this.fetchAssignedLots(this.purchaseLots.map(x => x.lot_id))
    await this.fetchAvailableLots()
    this.loading = false
  }
}
</script>
