<!--
A Check In chart used for displaying bars by user
title? String
initChart? String
rawData? Boolean
configurable?  Boolean
initDesignations?  Array
initStatuses? Array
chartKeyMethod? Function for Calculating the key field
chartValueMethod? Function for Calculating the shown field
-->
<template>
  <div>
    <simple-chart
      :title="title"
      :init-chart="initChart"
      :settings-btn="configurable"
      :simple-data="simpleData"
      @openSettings="settingsDialog = !settingsDialog"
    />
    <div class="text-right subtitle-1">
      {{ simpleDataTotal }}
    </div>
    <div
      v-if="configurable"
      class="text-center"
    >
      <edit-dialog
        max-width="1200"
        v-model="settingsDialog"
        headline-text="Show On Chart"
        :show-decline="false"
        accept-text="done"
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <p>Status</p>
            <v-checkbox
              v-model="statuses"
              label="Complete"
              value="CH"
            />
            <v-checkbox
              v-model="statuses"
              label="Preliminary"
              value="PR"
            />
            <v-checkbox
              v-model="statuses"
              label="Open"
              value="OP"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <p>Designation</p>
            <v-checkbox
              v-for="designation in this.$store.getters.getMaterialDesignations"
              :key="designation.value"
              v-model="designations"
              :label="designation.text"
              :value="designation.value"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <p>Created By</p>
            <v-btn
              @click="buyers = Array()"
            >
              unselect all
            </v-btn>
            <v-checkbox
              v-for="buyer in buyerList"
              :key="buyer"
              v-model="buyers"
              :label="buyer"
              :value="buyer"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <p>Division</p>
            <v-checkbox
              v-for="division in divisionList"
              :key="division"
              v-model="divisions"
              :label="division"
              :value="division"
            />
          </v-col>
        </v-row>
      </edit-dialog>
    </div>
  </div>
</template>

<script>
import SimpleChart from '../../Charts/Bar/SimpleChart'

function getBuyerName (row) {
  return (row.created_by && row.created_by.username) ? row.created_by.username : 'n/a'
}
export default {
  name: 'CheckInCharts',
  components: { SimpleChart },
  props: {
    title: {
      type: String,
      default: 'Check In Charts'
    },
    initChart: {
      type: String,
      default: 'bar-chart'
    },
    rawData: {
      type: Array,
      required: true
    },
    configurable: {
      type: Boolean,
      default: true
    },
    initDesignations: {
      type: Array,
      default: () => []
    },
    initStatuses: {
      type: Array,
      default: () => ['CH']
    },
    chartKeyMethod: {
      type: Function,
      default: getBuyerName
    },
    chartValueMethod: {
      type: Function,
      default: row => Number(row.total_counts.total)
    }
  },
  data: function () {
    return {
      settingsDialog: false,
      config: {},
      designations: this.initDesignations,
      divisions: [],
      statuses: this.initStatuses,
      buyers: []
    }
  },
  watch: {
    rawData: {
      immediate: true,
      handler () {
        this.buyers = this.buyerList
        this.divisions = this.divisionList
      }
    }
  },
  computed: {
    buyerList () {
      const s = new Set()
      this.rawData.forEach(row => { s.add(getBuyerName(row)) })
      return Array.from(s.keys()).sort()
    },
    divisionList () {
      const s = new Set()
      this.rawData.forEach(row => { s.add(row.purchase_division_code) })
      return Array.from(s.keys()).sort()
    },
    simpleData () {
      // Included total counts, but excludes BROKERED
      const dataSet = this.rawData.reduce((c, row) => {
        const key = this.chartKeyMethod(row)
        if (!c.hasOwnProperty(key)) c[key] = 0

        if (!this.designations.includes(row.purchase_designation)) return c
        if (!this.divisions.includes(row.purchase_division_code)) return c
        if (!this.statuses.includes(row.status)) return c
        if (!this.buyers.includes(getBuyerName(row))) return c

        c[key] += this.chartValueMethod(row)
        return c
      }, {})
      // make into an array
      let sd = []
      for (const key in dataSet) {
        if (dataSet[key] > 0) sd.push({ key, value: dataSet[key] })
      }
      return sd.sort((a, b) => a.key > b.key ? 1 : -1)
    },
    simpleDataTotal () {
      return this.simpleData.reduce((a, b) => a + Number(b.value), 0)
    }
  }
}
</script>
