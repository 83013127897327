/**
 * Pass in chart data structured as an array with key values pairs... and you're done
 * [{ key, value }]
 */
import ColorHash from 'color-hash'

export default {
  data: function () {
    return {
      chartData: {}
    }
  },
  methods: {
    simpleToChartData (arraySet) {
      let labels = []
      let data = []
      let backgroundColor = []
      let borderColor = []
      let colorHash = new ColorHash()

      arraySet.forEach(({ key, value }) => {
        labels.push(key)
        data.push(value)
        // assigning colors
        const color = colorHash.hex(key)
        backgroundColor.push(color + '33')
        borderColor.push(color)
      })
      return {
        labels,
        datasets: [{
          label: 'total',
          data,
          backgroundColor,
          borderColor,
          borderWidth: 2
        }]
      }
    }
  }
}
