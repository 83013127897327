<template>
  <div class="d-inline">
    <slot
      :on="() => generatePdf()"
      name="activator"
    >
      <v-btn
        icon
        :title="title"
        color="primary"
        @click="generatePdf"
      >
        <v-icon v-if="(pdfAction) === 'download'">
          mdi-download
        </v-icon>
        <v-icon v-if="(pdfAction) === 'print'">
          mdi-printer
        </v-icon>
      </v-btn>
    </slot>
  </div>
</template>

<script>
// { Array } Style definitions templates inherit
import { pdfStyles } from '@/helpers/pdfMake/pdfStyles'
// { String } PGM Logo image in Base64
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import config from '@/../izzi_config'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'PdfMakeBtn',
  props: {
    // PDFMake docDefinition generator function
    // @returns { Promise }
    docDefBuilder: {
      type: Function,
      required: true
    },
    // Include PGM header the generated document
    includePgmHeader: {
      type: Boolean,
      default: false,
      required: false
    },
    // Which pdfMake.createPdf method we should call, either download or print
    pdfAction: {
      type: String,
      default: 'print',
      validator (value) {
        return ['print', 'download'].indexOf(value) !== -1
      }
    },
    styles: {
      type: Object,
      default: Object
    },
    // If pdf page orientation is landscape
    landscape: {
      type: Boolean,
      default: false,
      required: false
    },
    // Button title attribute
    title: {
      type: String,
      required: false,
      default: ''
    },
    // If the button is clickable
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    pgmHeader () {
      if (!this.includePgmHeader) return {}
      const [addr1, addr2] = config.address.split('|')
      return {
        style: 'heading',
        columns: [
          {
            margin: [0, 0, 0, 30],
            image: config.png(),
            width: 200
          },
          {
            style: 'subheadingRight',
            stack: [
              { text: addr1, style: 'subheading' },
              { text: addr2, style: 'subheading' },
              { text: config.phone, style: 'subheading' }
            ]
          }
        ]
      }
    },
    // The document-definition-object expected by pdfMake.createPdf()
    async docDefinition () {
      try {
        const docDefContent = await this.docDefBuilder()
        return {
          content: [
            this.pgmHeader(),
            ...docDefContent
          ],
          pageSize: 'A4',
          pageMargins: [ 20, 20, 20, 20 ],
          pageOrientation: this.landscape ? 'landscape' : 'portrait',
          styles: Object.assign(pdfStyles(), this.styles)
        }
      } catch (err) {
        this.$store.commit('setSnackbarError', `An error occurred while trying to build invoice`)
      }
      return ''
    },
    // Generate a PDF from the docDefinition using PDFMake
    async generatePdf () {
      const pdfGenerator = pdfMake.createPdf(await this.docDefinition())
      if (this.pdfAction === 'print') pdfGenerator.print()
      else if (this.pdfAction === 'download') pdfGenerator.download()
    }
  }
}
</script>
