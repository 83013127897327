<template>
  <div>
    <v-dialog
      v-model="addHedgeDialog"
      persistent
      width="600"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          bottom
          right
          absolute
          color="primary"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Add Hedge to Purchase
          <v-spacer />
          <base-button
            icon-bool
            @clicked="addHedgeDialog = false"
            icon="mdi-close"
          />
        </v-card-title>
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-card-text>
            <v-list-item v-if="!newHedge">
              <v-checkbox
                label="Add Existing Hedge"
                v-model="existingHedge"
              />
            </v-list-item>
            <div v-if="existingHedge">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-list-item>
                  <paginated-autocomplete
                    label="Select a Hedge"
                    v-model="hedgeId"
                    item-text="id"
                    item-value="id"
                    base-url="/api/hedges/"
                    search-field="id"
                    :error-messages="errors"
                  />
                </v-list-item>
              </ValidationProvider>
              <base-button
                auto-center
                :disabled="invalid"
                @clicked="addHedge"
                label="Add to Purchase"
              />
            </div>
            <v-list-item
              class="mt-n4"
              v-if="!existingHedge"
            >
              <v-checkbox
                label="Create New"
                v-model="newHedge"
              />
            </v-list-item>
            <div v-if="newHedge">
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <paginated-autocomplete
                      label="Select a Smelter"
                      v-model="smelter"
                      item-text="name"
                      item-value="id"
                      base-url="/api/smelters/"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Platinum Troy Ounces"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="ptInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Platinum Rate / toz"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="ptRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Palladium Troy Ounces"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="pdInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Palladium Rate / toz"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="pdRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Rhodium Troy Ounces"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="rhInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Rhodium Rate / toz"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="rhRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <date-input
                label="Expiration Date"
                v-model="expires"
              />
              <v-text-field
                v-model="reference"
                label="Reference"
              />
              <v-textarea
                :counter="true"
                :rows="2"
                auto-grow
                maxlength="280"
                label="Memo"
                v-model="memo"
              />
              <base-button
                auto-center
                :disabled="invalid"
                label="Create and Add Hedge"
                @clicked="createHedge"
              />
            </div>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'HedgeDialog',
  data: (vm) => ({
    addHedgeDialog: false,
    existingHedge: false,
    newHedge: false,
    modified: false,
    hedgeId: null,
    ptRatePerToz: 0,
    pdRatePerToz: 0,
    rhRatePerToz: 0,
    ptInitialOz: 0,
    pdInitialOz: 0,
    rhInitialOz: 0,
    memo: '',
    expires: new Date().toISOString().substr(0, 10),
    reference: '',
    smelter: null
  }),
  watch: {
    expires (val) {
      this.expiresFormatted = this.formatDate(this.expires)
    }
  },
  methods: {
    /**
     * Tell the parent to add a hedge to the purchase
     * @param {Number} hedgeId
     */
    addHedge (hedgeId) {
      this.$emit('addHedge', this.hedgeId)
      this.addHedgeDialog = false
    },
    /**
     * Tell the parent to create a new hedge
     */
    async createHedge () {
      const {
        ptRatePerToz,
        pdRatePerToz,
        rhRatePerToz,
        ptInitialOz,
        pdInitialOz,
        rhInitialOz,
        expires,
        smelter,
        reference,
        memo
      } = this
      const body = {
        smelter_id: smelter,
        memo,
        reference,
        pt_available_toz: ptInitialOz,
        pt_initial_toz: ptInitialOz,
        pt_rate_per_toz: ptRatePerToz,
        pd_available_toz: pdInitialOz,
        pd_initial_toz: pdInitialOz,
        pd_rate_per_toz: pdRatePerToz,
        rh_available_toz: rhInitialOz,
        rh_initial_toz: rhInitialOz,
        rh_rate_per_toz: rhRatePerToz,
        expires_on: new Date(expires)
      }
      this.$emit('createPurchaseHedge', body)
      this.addHedgeDialog = false
    }
  }
}
</script>
