<template>
  <v-card>
    <v-card-subtitle>
      Purchase Information
    </v-card-subtitle>
    <v-row>
      <card-list-item title="Created At">
        {{ purchase.created_at | formatDate }}
      </card-list-item>
      <card-list-item title="Estimated Purchase Date">
        {{ purchase.est_purchase_date | formatDate }}
      </card-list-item>
      <card-list-item title="Division">
        {{ purchase.division | filterObject('name') | filterNA }}
      </card-list-item>
      <card-list-item
        title="Status"
      >
        <template>
          {{ formatStatus(purchase.status) | filterNA }}
        </template>
        <template slot="title-button">
          <status-change-dialog
            v-if="$store.getters.can('update-purchase-statuses') && purchase.status && purchase.status !== 'CU'"
            :status="formatStatus(status)"
            :purchase="purchase"
            :statuses="purchaseStatuses"
            src-type="purchase"
            @updateStatus="updatePurchaseStatus"
          />
        </template>
      </card-list-item>
      <card-list-item title="Designation">
        <template>
          {{ getDesignationChoiceText(purchase.designation) }}
        </template>
        <template slot="title-button">
          <v-btn
            icon
            x-small
            color="info"
            @click="designationDialog = true"
          >
            <v-icon> mdi-pencil </v-icon>
            <edit-dialog
              v-model="designationDialog"
              headline-text="Change Purchase Designation"
              @accept="updateDesignation(designation)"
            >
              <v-select
                :value="purchase.designation"
                :items="designationChoices"
                @input="designation = $event"
              />
            </edit-dialog>
          </v-btn>
        </template>
      </card-list-item>
      <purchase-ppm-calc
        :purchase="purchase"
        :loading="loading"
        @updatePpm="updatePpm"
      />
    </v-row>
    <card-list-item
      :title="getPieceCounts().title"
      no-cols
    >
      {{ getPieceCounts().pieces | filterNA | trimDecimals | addCommas | appendPcs }}
    </card-list-item>
  </v-card>
</template>

<script>
import StatusChangeDialog from '@/base/components/StatusChangeDialog'
import PurchasePpmCalc from './PurchasePpmCalc'
import { mapGetters } from 'vuex'

export default {
  name: 'PurchaseData',
  components: {
    StatusChangeDialog,
    PurchasePpmCalc
  },
  props: {
    purchase: { type: Object, required: true },
    status: { type: String, required: true },
    purchaseStatuses: { type: Array, required: true },
    loading: { type: Boolean, required: true }
  },
  data: () => ({
    designationDialog: false,
    designation: null
  }),
  computed: {
    ...mapGetters({
      'designationChoices': 'getMaterialDesignations'
    })
  },
  methods: {
    updateDesignation (key) {
      const params = { designation: key }
      this.$emit('updatePurchase', params)
    },
    getDesignationChoiceText (key) {
      const found = this.$store.getters['getMaterialDesignations'].find(choice => choice.value === key)
      if (found) return found.text
      return 'N/A'
    },
    /**
     * Tell the parent to update the purchase ppm
     * @param {Number} newPpmId
     */
    updatePpm (newPpmId) {
      this.$emit('updatePpm', newPpmId)
    },
    /**
     * Tell the parent to update the status of the purchase
     * @param {String} nextStatus
     */
    updatePurchaseStatus (nextStatus) {
      let params = { status: nextStatus.value }
      this.$emit('updatePurchase', params)
    },
    /**
     * Get the human readable version of a given status abbreviation
     * @param {String} status
     */
    formatStatus (status) {
      for (const obj of this.purchaseStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    },
    /**
     * Get the piece counts for a given purchase
     * @return {Object} {pieces: Integer, title: String}
     */
    getPieceCounts () {
      let title = 'Projected Pieces Count'
      let pieces = this.purchase.projected_pieces
      if (this.purchase.intake) {
        title = 'Total Pieces Intaken'
        pieces = this.purchase.total_cat_pieces
      } else if (this.purchase.check_ins.length > 0) {
        title = 'Total Pieces Checked In'
        pieces = this.purchase.total_cat_pieces
      }
      return { pieces, title }
    }
  }
}
</script>
