<template>
  <v-container
    fluid
  >
    <template
      v-if="results.length"
    >
      <v-pagination
        v-model="page"
        :length="pages"
      />
      <v-card
        class="mx-auto"
        max-width="800"
      >
        <v-list
          two-line
        >
          <v-list-item
            v-for="result in results"
            :key="result.id"
          >
            <v-list-item-avatar
              :rounded="false"
              tile
              :size="previewSize"
            >
              <v-sheet
                v-if="result.picture_set"
                :max-width="previewSize"
                :max-height="previewSize"
              >
                <converter-image-viewer :photo-group="result.picture_set" />
              </v-sheet>
            </v-list-item-avatar>
            <br>
            <v-list-item-content>
              <!--Has Converter Already-->
              <v-list-item-title>
                <span v-if="$vuetify.breakpoint.mdAndDown && priceConverter(result)">
                  <strong>${{ priceConverter(result).price }}</strong>
                </span>
                {{ result.code }} {{ result.category }} ({{ result.converter_group_name }})
                <span v-if="$vuetify.breakpoint.lgAndUp && priceConverter(result)">
                  -- $ {{ priceConverter(result).price }}
                </span>
              </v-list-item-title>
              <span class="caption">
                {{ result.cat_name }}
                <br>
                {{ result.notes }}
              </span>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                icon
                @click="openConverter(result)"
              >
                <v-icon
                  :color="hasConverter(result) ? 'accent' : 'primary'"
                >
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
      <v-pagination
        v-model="page"
        :length="pages"
      />
    </template>
    <template v-else>
      <strong>No Converter Results</strong>
      <p class="caption">
        Clicking on a converter will add it to your check in sheet.
        <br>
        A dialog will appear for you to add additional counts.
      </p>
    </template>
    <converter-line-dialog
      v-if="getConverter(converterLine)"
      v-model="converterDialog"
      :line="getConverter(converterLine)"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import ConverterLineDialog from './ConverterLineDialog'
import ConverterImageViewer from '@/base/components/ConverterImageViewer'

export default {
  name: 'SearchDialogConverter',
  components: { ConverterImageViewer, ConverterLineDialog },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    converterDialog: false,
    converterLine: null,
    page: 1,
    count: 0,
    pageLength: 20,
    results: [],
    prices: []
  }),
  watch: {
    value () {
      this.page = 1
      this.search()
    },
    page () { this.search() }
  },
  computed: {
    previewSize () {
      if (this.$vuetify.breakpoint.xs) return 50
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100
    },
    pages () {
      return Math.ceil(this.count / 20)
    },
    ...mapGetters('checkIn/edit', [
      'getCheckIn',
      'getConverters'
    ])
  },
  methods: {
    ...mapActions('checkIn/edit', [
      'addConverterLine'
    ]),
    hasConverter (converter) {
      return !!this.getConverters.find(cl => cl.converter.url === converter.url)
    },
    getConverter (converter) {
      if (!converter) return false
      return this.getConverters.find(cl => cl.converter.url === converter.url)
    },
    priceConverter (converter) {
      return this.prices.find(cv => cv.url === converter.url)
    },
    async search () {
      this.$emit('loading', true)
      let value = this.value.replace('-', ' ').replace('.', ' ')
      // value = `%${value}%`
      const params = { search: value, page: this.page, converter_group__name__ne: 'N/A' }
      const r = await axios.get('/api/converters/', { params })
      const rResults = r.data.results.filter(cv => cv.converter_group_name !== '--')
      this.results = rResults
      this.count = r.data.count
      // runs price fetch loop
      const priceSet = await Promise.all(rResults.map(this.fetchConverterPrice))
      this.prices = priceSet.map(p => p.data)
      this.$emit('loading', false)
    },
    fetchConverterPrice (converter) {
      let params = { spread: this.getCheckIn.pricing_spread }
      const mrid = this.getCheckIn?.market_rate?.id
      if (mrid) params.market_rate = mrid
      return axios.get(converter.url + 'price/', { params })
    },
    openConverter (converter) {
      // Add converter if not previously added
      const p = this.priceConverter(converter)
      const price = p ? p.price : 0
      if (!this.hasConverter(converter)) {
        this.addConverter(converter, price)
      }
      this.converterLine = converter
      this.converterDialog = true
    },
    addConverter (converter, price) {
      this.addConverterLine({ converterUrl: converter.url, price: price })
    }
  }
}
</script>
