<!--
Creates v-model based fab that can pass click back to the parent also renders icons on the badge
> When a fab is clicked it increments the internal counter and begins a api request
the api request will fire after 3 seconds
> if the api request fails the fab is colored red and retry timeout will be added with a 5 second timer
> The fab is disabled and appears as a loading symbol while the api request is being made
-->
<template>
  <v-badge
    :icon="icon"
    color="error"
    overlap
    class="mr-3"
  >
    <!--:loading="!ready"-->
    <v-btn
      :color="statusColor"
      :small="isSmall"
      @click.stop="fabClick"
    >
      {{ count }}
    </v-btn>
  </v-badge>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CheckInFab',
  props: {
    converterLineUrl: {
      type: String,
      required: true
    },
    countField: {
      type: String,
      required: true
    },
    initialCount: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      error: false,
      count: this.initialCount,
      incrementer: 0,
      ready: true,
      timeout: null
    }
  },
  watch: {
    initialCount: function (value) {
      if (!this.locked) this.count = value
    }
  },
  computed: {
    statusColor () {
      if (this.error) return 'error'
      if (this.count === 0) return ''
      return 'primary'
    }
  },
  methods: {
    /**
     * the function makes the api requests and is intended to be used as a timeout
     */
    converterLineUpdate () {
      this.ready = false
      let payload = {}
      payload[this.countField] = this.count
      this.patchConverterLine({ converterLineUrl: this.converterLineUrl, payload })
        .then(() => {
          this.error = false
        })
        .catch(() => {
          this.error = true
          // register a retry attempt
          this.timeout = setTimeout(this.converterLineUpdate, 5000)
        })
        .finally(() => {
          this.ready = true
          this.locked = false
        })
    },
    /**
     * Increments the count value for the converter line
     * start the timeout for the converter update api request
     */
    fabClick () {
      this.locked = true
      this.count = this.count + 1
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(this.converterLineUpdate, 250)
    },
    ...mapActions('checkIn/edit', ['patchConverterLine'])
  }
}
</script>
