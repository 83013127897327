<template>
  <div>
    <v-list-item
      @click="dialog = !dialog"
    >
      <v-list-item-content>
        <v-row>
          <v-col
            cols="6"
          >
            <span class="caption">{{ converterLine.lineNumber }}</span>
            {{ converterLine.converter.code }}
          </v-col>
          <v-col
            class="text-right"
            cols="2"
          >
            ${{ Math.round(converterLine.price) }}
          </v-col>
          <v-col
            class="text-center"
            cols="1"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-col>
          <v-col
            cols="2"
          >
            ${{ getLatestPrice }}
            <v-icon v-if="symbol === 'up'">
              mdi-arrow-top-right-thick
            </v-icon>
            <v-icon v-if="symbol === 'down'">
              mdi-arrow-bottom-right-thick
            </v-icon>
          </v-col>
          <v-col
            cols="1"
          >
            <v-icon
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          Direct Line Edit
        </v-card-title>
        <v-card-subtitle>
          {{ converterLine.converter.code }}
          <br>
          {{ Math.round(converterLine.price) }} (current) -- {{ getLatestPrice }} (market)
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            :disabled="!dialogReady"
            :loading="!dialogReady"
            :placeholder="String(getLatestPrice)"
            :error="dialogError"
            v-model="dialogValue"
            prepend-icon="mdi-currency-usd"
            type="number"
            @keydown.enter="saveConverterLinePrice"
          />
        </v-card-text>
        <v-card-actions>
          <v-row
            justify="center"
            class="ma-0"
          >
            <v-btn
              color="accent"
              label="Cancel"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="saveConverterLinePrice"
            >
              Ok
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'PricingDialogConverter',
  props: {
    converterLine: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    price: null,
    dialog: false,
    dialogReady: true,
    dialogError: false,
    dialogValue: ''
  }),
  computed: {
    // reflects the movement of price
    symbol () {
      if (Math.round(this.converterLine.price) > this.getLatestPrice) return 'down'
      if (Math.round(this.converterLine.price) < this.getLatestPrice) return 'up'
      return ''
    },
    getLatestPrice () {
      const url = this.converterLine.converter.url
      const clone = this.getLatestPricing.slice().reverse()
      const found = clone.find(x => x.url === url)
      if (found && found.price) {
        return found.price
      } else {
        return 0
      }
    },
    ...mapGetters('checkIn/edit', [
      'getLatestPricing'
    ])
  },
  methods: {
    saveConverterLinePrice () {
      // Saving the converter line updates the pricing temporarily instead of per.
      const update = { converterUrl: this.converterLine.converter.url, price: Number(this.dialogValue) }
      this.updateLatestPrice(update)
      this.dialog = false
    },
    ...mapActions('checkIn/edit', [
      'patchConverterLine'
    ]),
    ...mapMutations('checkIn/edit', [
      'updateLatestPrice'
    ])
  }
}
</script>
