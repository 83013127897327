import { Line, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      default: () => ({
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        plugins: {
          datalabels: {
            color: '#fff',
            font: { weight: 800 },
            textShadowBlur: 5,
            textShadowColor: '#000'
          }
        }
      })
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
