<template>
  <div>
    <info-tooltip>
      <template v-slot:wrap-me>
        <v-list-item
          small
          :disabled="reconcileDisabled"
          @click="dialog = !dialog"
        >
          Reconcile
        </v-list-item>
      </template>
      This operation is only available for brokered Check-Ins that are not reconciled
    </info-tooltip>
    <edit-dialog
      v-model="dialog"
      headline-text="Reconcile Converters"
      accept-text="save"
      @accept="reconcileConverters"
    >
      The converters listed below have a $0 price. Please reconcile them. This operation can only be completed once.
      <v-form
        @submit.prevent
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Total Pieces</th>
              <th>Total Value</th>
              <th>Price Per Whole Unit</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(line) in reconcileConverterLines"
              :key="line.id"
            >
              <td>{{ line.converter.code }}</td>
              <td>{{ line.count }}</td>
              <td>{{ line.value }}</td>
              <td>
                <ValidationProvider
                  rules="required|two_decimal|min_value:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="line.price"
                    type="number"
                    min="0"
                  />
                </ValidationProvider>
              </td>
              <td>
                {{ line.price * line.value | currencyUSD }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-form>
    </edit-dialog>
  </div>
</template>

<script>
import EditDialog from '@/base/components/EditDialog'
import axios from 'axios'

export default {
  name: 'ReconcileDialog',
  components: {
    EditDialog
  },
  props: {
    checkIn: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    reconcileConverterLines: []
  }),
  computed: {
    reconcileDisabled () {
      if (!this.$store.getters.can('reconcile-check-ins')) return true
      if (this.checkIn.is_reconciled) return true
      return this.checkIn.status !== 'CH'
    }
  },
  methods: {
    async reconcileConverters () {
      try {
        const priceUpdates = this.reconcileConverterLines.reduce((acc, line) => {
          acc[line.id] = { price: line.price }
          return acc
        }, {})
        // Update the check-in's converter line prices
        await axios.patch('/api/check_in_converters/bulk_update/', priceUpdates)
        // Set the check-in's is_reconciled value to true
        await axios.patch(`/api/check_ins/${this.checkIn.id}/`, { is_reconciled: true })
      } catch (e) {
        this.$store.commit('setSnackbarError', 'An error occurred while reconciling converter lines.', { root: true })
      } finally {
        this.$store.dispatch('checkIn/edit/fetchCheckInDetailed', { checkinID: this.checkIn.id })
      }
    }
  },
  mounted () {
    this.reconcileConverterLines = this.checkIn.converter_lines.filter(line => line.price === '0.00' && line.count > 0)
  }
}
</script>
