<template>
  <unauthorized
    :authorized="$store.getters.can('view-purchase')"
    message="Not Authorized to view Purchases"
  >
    <base-title title="Purchase List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchAdvancedSets"
    >
      <date-range-input
        start-date-label="From Created Date"
        end-date-label="To Created Date"
        :start-date="startDate"
        :end-date="endDate"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <date-range-input
        start-date-label="From Est. Purchase Date"
        end-date-label="To Est. Purchase Date"
        :start-date="startEstDate"
        :end-date="endEstDate"
        @alterStartDate="startEstDate = $event"
        @alterEndDate="endEstDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Does Not Have Intake"
            v-model="needsIntake"
            :items="boolOptions"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Does Not Have Check In"
            v-model="needsCheckIn"
            :items="boolOptions"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            label="Statuses"
            clearable
            v-model="statusSet"
            :items="purchaseStatuses"
            item-text="text"
            item-value="value"
            multiple
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Divisions"
            v-model="divisionSet"
            multiple
            :items="divisions"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Designation"
            v-model="designationSet"
            multiple
            :items="designations"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="purchase"
        />
      </template>
      <template v-slot:item.intake_id="{ item }">
        <router-link-id
          v-if="item.intake_id"
          name="intake"
          :id="item.intake_id"
        >
          {{ item.intake_id }}
        </router-link-id>
      </template>
      <template v-slot:item.check_ins_details="{ item }">
        <router-link-id
          v-for="ci in item.check_ins_details"
          :key="ci.id"
          :id="ci.id"
          name="check-in"
        >
          {{ ci.id }}
        </router-link-id>
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status_text }}
      </template>
      <template v-slot:item.designation="{ item }">
        {{ item.designation_text }}
      </template>
      <template v-slot:item.check_ins__yard__name="{ item }">
        {{ item.yard_names.join(', ') | filterNA }}
      </template>
      <template v-slot:item.division__name="{ item }">
        {{ item.division_text }}
      </template>
      <template v-slot:item.hedge_is_requested="{ item }">
        {{ item.hedge_is_requested && !item.hedge ? 'Yes' : 'No' }}
        <info-tooltip v-if="item.hedge_is_requested">
          {{ item.hedge_request_memo }}
        </info-tooltip>
      </template>
      <template v-slot:item.created_by__username="{ item }">
        {{ (item.created_by && item.created_by.username) || '--' }}
      </template>
      <template v-slot:item.projected_pieces="{ item }">
        {{ item.projected_pieces | trimDecimals | addCommas }}
      </template>
      <template v-slot:item.est_purchase_date="{ item }">
        {{ item.est_purchase_date | formatDate }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-purchases')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'create-purchase' })"
    >
      Create Purchase
    </text-fab>
  </unauthorized>
</template>

<script>
import { mapGetters } from 'vuex'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'PurchaseList',
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'purchase-list',

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Status', value: 'status' },
        { text: 'Yard(s)', sortable: false, value: 'check_ins__yard__name' },
        { text: 'Intake', value: 'intake_id' },
        { text: 'Check Ins', value: 'check_ins_details' },
        { text: 'Currency', value: 'currency' },
        { text: 'Hedge Req.', value: 'hedge_is_requested' },

        { text: 'Division', value: 'division__name' },
        { text: 'Designation', value: 'designation' },

        { text: 'User', sortable: true, value: 'created_by__username' },
        { text: 'Est. Pieces', value: 'projected_pieces' },
        { text: 'Est Purch. date', value: 'est_purchase_date' },
        { text: 'Created At', value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Needs Intake', prop: 'needsIntake', type: String, query: 'intake__isnull' },
        { name: 'Needs Check In', prop: 'needsCheckIn', type: String, query: 'check_ins__isnull' },
        { name: 'Statuses', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'division__name__in' },
        { name: 'Designations', prop: 'designationSet', type: Array, query: 'designation__in' },

        { name: 'From Est. Purchase Date', prop: 'startEstDate', type: String, query: 'est_purchase_date__gte' },
        { name: 'To Est. Purchase Date', prop: 'endEstDate', type: String, query: 'est_purchase_date__lte' },

        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ]),

      divisions: [],
      smelters: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      purchaseStatuses: 'purchaseStore/purchaseStatuses',
      designations: 'lotStore/lotTypes'
    })
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        // 'url',
        'id',
        'yard_names',
        'check_ins_details',
        'division',
        'division_text',
        'hedge',
        'intake_id',
        'created_at',
        'status',
        'status_text',
        // 'customer_ref',
        'projected_pieces',
        'created_at',
        'est_purchase_date',
        'hedge_is_requested',
        'hedge_request_memo',
        // 'purchase_lots',
        // 'ppm_calculation',
        'designation',
        'designation_text',
        'created_by',
        'currency'
      ].join(',')
      axios.get('/api/purchases/', { params }).then(this.setResponseResults)
    },
    fetchDivisions () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,url').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.name, url: d.url }))
        })
      }
    },
    fetchAdvancedSets () {
      if (this.smelters.length === 0) {
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
        })
      }
    },
    formatStatus (status) {
      for (const obj of this.purchaseStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    }
  },
  mounted () {
    this.fetchDivisions()
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
