<template>
  <v-dialog
    :value="value"
    width="800"
    transition="dialog-bottom-transition"
    @input="$emit('input', false)"
  >
    <v-card
      min-height="800"
    >
      <v-toolbar
        dense
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Search Codes/Notes</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <p>
              Searching between Converters and Catalog can bet toggled using the the switch on the left.
              <br>
              Searching start automatically as you type.
              <br>
              Red icons indicate that the item has already been added to the check in
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            What are you searching for?
            <v-btn-toggle
              dense
              tile
              v-model="converterSwitch"
            >
              <v-btn>
                Converter
              </v-btn>
              <v-btn>
                Catalog
              </v-btn>
            </v-btn-toggle>
            <v-text-field
              v-show="converterSearch"
              v-model="converterText"
              label="Code/Notes Search"
              hint="spaces, dashes and periods are treated as wildcards"
              :loading="waiting"
              clearable
              @input="updateText"
              @change="updateText"
            />
            <v-text-field
              v-show="!converterSearch"
              v-model="catalogText"
              label="Search"
              :loading="waiting"
              clearable
              type="text"
              @input="updateText"
              @change="updateText"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-show="converterSearch">
            <search-dialog-converter
              :value="converterSearchText"
              @loading="waiting = $event"
            />
          </v-col>
          <v-col v-show="!converterSearch">
            <search-dialog-catalog
              :value="catalogSearchText"
              @loading="waiting = $event"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import SearchDialogConverter from './SearchDialogConverter'
import SearchDialogCatalog from './SearchDialogCatalog'
export default {
  name: 'SearchDialog',
  components: { SearchDialogCatalog, SearchDialogConverter },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    converterText: '',
    converterSearchText: '',
    catalogText: '',
    catalogSearchText: '',
    converterSwitch: 0,
    timeout: null,
    waiting: false
  }),
  computed: {
    converterSearch () {
      // 0 Means Converter 1 is Catalog
      return this.converterSwitch !== 1
    }
  },
  methods: {
    updateText () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.converterText && this.converterText.length > 1) this.converterSearchText = this.converterText
        if (this.catalogText && this.catalogText.length > 1) this.catalogSearchText = this.catalogText
      }, 500)
    }
  }
}
</script>
