<template>
  <v-card class="my-4">
    <v-card-title>
      Select Intended Hedge
      <info-tooltip>
        Selecting a hedge helps provide additional information to the coordinator
      </info-tooltip>
    </v-card-title>
    <v-card-text>
      <paginated-autocomplete
        label="Select Hedge"
        :value="checkIn.intended_hedge"
        item-text="id"
        item-value="url"
        base-url="/api/hedges/"
        :search-params="{ is_completed: false }"
        @input="setIntendedHedge"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SelectIntendedHedge',
  props: {
    checkIn: {
      type: Object,
      required: true
    }
  },
  methods: {
    setIntendedHedge (hedgeUrl) {
      axios.patch(this.checkIn.url, { intended_hedge: hedgeUrl || null })
    }
  }
}
</script>
