<template>
  <unauthorized
    :authorized="$store.getters.can('create-check-ins')"
    message="Not Authorized to Split a Check In"
  >
    <v-container>
      <url-breadcrumbs
        :items="breadcrumbs"
      />
      <base-title v-if="!loading && checkIn">
        {{ `Check-In ID #${checkIn.id}` }}
        <div
          v-if="checkIn.cart_sequence_id"
          class="caption"
        >
          Exchange Cart #{{ checkIn.cart_sequence_id }}
        </div>
        <template slot="subtitle">
          <router-link
            :to="{ name: 'yard', params: { id: checkIn.yard.id } }"
          >
            <h3>
              {{ checkIn.yard && checkIn.yard.name }}
            </h3>
          </router-link>
        </template>
      </base-title>
      <div class="my-4" />
      <h4 class="text-h6">
        Select Converter Lines to Transfer
      </h4>
      <v-card>
        <v-data-table
          v-model="converterLinesSelected"
          :headers="clHeaders"
          :items="checkIn.converter_lines.filter(x => !!x.count)"
          show-select
        />
      </v-card>
      <div class="my-4" />
      <h4 class="text-h6">
        Select Other Lines to Transfer
      </h4>
      <v-card>
        <v-data-table
          v-model="checkInLinesSelected"
          :headers="cilHeaders"
          :items="checkIn.check_in_lines.filter(x => !!x.count)"
          show-select
        />
      </v-card>
      <text-fab
        :disabled="!totalLines"
        bottom
        right
        fixed
        icon-text="mdi-save"
        color="primary"
        @click="splitCheckIn"
      >
        Create Check-In ( {{ totalLines }} lines )
      </text-fab>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { splitCheckIn } from '@/requests/checkIn'

export default {
  name: 'CheckIn',
  data: () => ({
    loading: false,
    clHeaders: [
      { text: 'Converter', value: 'converter.code' },
      { text: 'Count', value: 'count', align: 'right' },
      { text: 'Price', value: 'price', align: 'right' }
    ],
    converterLinesSelected: [],
    cilHeaders: [
      { text: 'Catalog', value: 'catalog.name' },
      { text: 'Count', value: 'count', align: 'right' },
      { text: 'Price', value: 'price', align: 'right' }
    ],
    checkInLinesSelected: []
  }),
  computed: {
    ...mapGetters({
      checkIn: 'checkIn/edit/getCheckIn'
    }),
    totalLines () {
      return this.converterLinesSelected.length + this.checkInLinesSelected.length
    },
    breadcrumbs () {
      if (!this.checkIn) return []
      return [
        this.checkIn.purchase,
        this.checkIn.url,
        ['Split']
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchCheckInDetailed: 'checkIn/edit/fetchCheckInDetailed'
    }),
    splitCheckIn () {
      if (confirm('Split the Check In?')) {
        splitCheckIn(this.checkIn.id, this.converterLinesSelected.map(x => x.id), this.checkInLinesSelected.map(x => x.id)).then(res => {
          if (res.data) {
            this.$store.commit('setSnackbarInfo', 'Check in split successfully')
            this.$router.push({ name: 'check-in', params: { id: res.data.id } })
          } else {
            this.$store.commit('setSnackbarWarning', 'Missing response data')
          }
        }).catch(err => {
          this.$store.commit('setSnackbarError', err.message)
        })
      }
    }
  },
  mounted () {
    Promise.all([
      this.fetchCheckInDetailed({ checkinID: this.$route.params.id })
    ]).then(() => {
      this.loading = false
    })
  }
}
</script>
