var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"550","height":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.skid.gps_number ? 'accent' : ''}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.skid.gps_number ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-question')+" ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pb-4 px-4"},[_c('v-card-title',[_vm._v(" Update Status "),_c('v-spacer'),_c('base-button',{attrs:{"icon-bool":"","icon":"mdi-close"},on:{"clicked":function($event){return _vm.closeDialog()}}})],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-4 mb-4",attrs:{"label":"GPS Number","type":"number","error-messages":errors},model:{value:(_vm.gpsNumber),callback:function ($$v) {_vm.gpsNumber=$$v},expression:"gpsNumber"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[(_vm.skid.gps_number && !_vm.addingGps)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.updatingSkid,"loading":_vm.updatingSkid && !_vm.addingGps,"color":"error"},on:{"click":_vm.removeGps}},[_vm._v(" Remove GPS Number ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":!invalid},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":_vm.updatingSkid || invalid,"loading":_vm.updatingSkid && !_vm.removingGps,"color":"success"},on:{"click":_vm.addGps}},[_vm._v(" "+_vm._s(_vm.skid.gps_number ? 'Update' : 'Add')+" GPS Number ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Invalid gps number ")])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }