<template>
  <v-card>
    <v-card-subtitle>
      Linked Lots
    </v-card-subtitle>
    <v-list-item v-if="lots.length > 0">
      <v-list-item-content>
        <v-col
          class="ml-4 body-1"
          v-for="(lot, index) in lots"
          :key="index"
        >
          <router-link :to="{ name: 'lot', params: { id: lot.lot_id }}">
            {{ lot.lot_id + ' ' + formatType(lot.converter_type) }}
          </router-link>
        </v-col>
      </v-list-item-content>
    </v-list-item>
    <card-list-item
      title="No Lots found"
      v-else
    />
  </v-card>
</template>

<script>
export default {
  name: 'PurchaseLotsSection',
  props: {
    lots: {
      type: Array,
      required: false,
      default: () => []
    },
    converterTypes: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatType (type) {
      for (const obj of this.converterTypes) {
        if (obj.value === type) return obj.text
      }
      return ''
    }
  }
}
</script>
