<template>
  <unauthorized
    :authorized="$store.getters.can('view-basics')"
    message="You are not authorized to view Purchases"
  >
    <v-container>
      <url-breadcrumbs
        v-if="purchase"
        :items="[
          ['purchases', { name: 'purchase-list' }],
          purchase.url
        ]"
      />
      <base-title v-if="!loading">
        Purchase ID #{{ purchase.id }}
        <template slot="subtitle">
          <div v-if="purchase.customer_ref">
            Note: {{ purchase.customer_ref }}
          </div>
          Opened By {{ purchase.created_by && purchase.created_by.username || '--' }}
          <br>
          {{ yardNames || 'No Yards Yet' }}
          <info-tooltip>
            Based on the check in yard selections
          </info-tooltip>
        </template>
      </base-title>
      <v-row
        class="text-right"
      >
        <v-col v-if="!loading">
          <span class="pa-2">
            <purchase-delete-dialog
              v-if="canDeletePurchase"
              :purchase="purchase"
            />
          </span>
          <span class="pa-2">
            <purchase-split-dialog
              class="pa-2"
              v-if="$store.getters.can('clone-purchase')"
              :purchase="purchase"
            />
          </span>
        </v-col>
      </v-row>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <status-stepper
        v-else
        :status="purchase.status"
        :statuses="purchaseStatuses"
      />
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <purchase-data
        v-if="$store.getters.can('view-purchase') && !loading"
        :loading="updatingPurchase"
        :purchase="purchase"
        :status="purchase.status"
        :purchase-statuses="purchaseStatuses"
        @updatePurchase="updatePurchaseData"
        @updatePpm="updatePpm"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <purchase-estimations-section
        v-if="$store.getters.can('view-purchase') && !loading"
        :purchase="purchase"
      />
      <ppm-value-card
        v-if="!loading"
        class="mt-8"
        :pt="estimatedToz.pt"
        :pd="estimatedToz.pd"
        :rh="estimatedToz.rh"
      >
        <v-card-subtitle>
          Contained toz (estimated from lines)
          <info-tooltip>
            Calculated based on the accumulation of converter.converter_line.value * converter.converter_line.converter[est_toz]
          </info-tooltip>
        </v-card-subtitle>
      </ppm-value-card>
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <purchase-lots-section
        v-if="$store.getters.can('view-purchase') && !loading"
        :lots="purchase.purchase_lots"
        :converter-types="converterTypes"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <hedges-section
        v-if="$store.getters.can('view-lots') && !loading"
        :hedge="purchase.hedge"
        :purchase="purchase"
        :updating-purchase="updatingPurchase"
        @addHedge="addHedge"
        @updatePurchase="updatePurchaseData"
        @createPurchaseHedge="createPurchaseHedge"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <check-ins-section
        v-if="$store.getters.can('view-purchase') && !loading"
        :status="purchase.status"
        :check-ins="purchase.check_ins"
        @addCheckIn="createCheckInDialog = true"
      />
      <check-in-create-dialog
        v-if="$store.getters.can('view-purchase') && !loading"
        v-model="createCheckInDialog"
        :purchase="purchase"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <piece-counts-section
        v-if="$store.getters.can('view-purchase') && !loading"
        :check-ins="purchase.check_ins"
        :piece-counts="purchase.piece_counts"
        :intake-counts="purchase.intake_counts"
        :purchase-lots="purchase.purchase_lots"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <intake-section
        v-if="$store.getters.can('create-intakes') && !loading"
        @addIntake="addIntake"
        :purchase="purchase"
      />
    </v-container>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PurchaseData from './components/PurchaseData'
import PurchaseLotsSection from './components/PurchaseLotsSection'
import PurchaseEstimationsSection from './components/PurchaseEstimationsSection'
import HedgesSection from './components/HedgesSection'
import CheckInsSection from './components/CheckInsSection'
import IntakeSection from './components/IntakeSection'
import StatusStepper from '@/base/components/StatusStepper'
import PieceCountsSection from './components/PieceCountsSection'
import CheckInCreateDialog from './components/CheckInCreateDialog'
import PurchaseSplitDialog from './components/PurchaseSplitDialog'
import PurchaseDeleteDialog from './components/PurchaseDeleteDialog'
import PpmValueCard from '@/components/PpmValueCard.vue'
import axios from 'axios'

export default {
  name: 'Purchase',
  components: {
    PpmValueCard,
    PurchaseDeleteDialog,
    PurchaseSplitDialog,
    CheckInCreateDialog,
    PieceCountsSection,
    StatusStepper,
    PurchaseData,
    PurchaseLotsSection,
    PurchaseEstimationsSection,
    HedgesSection,
    CheckInsSection,
    IntakeSection
  },
  data: () => ({
    createCheckInDialog: false,
    loading: true,
    updatingPurchase: false,
    estimatedToz: {
      pt: 0,
      pd: 0,
      rh: 0
    }
  }),
  methods: {
    ...mapActions({
      fetchPurchase: 'purchaseStore/fetchPurchase',
      updatePurchase: 'purchaseStore/updatePurchase'
    }),
    /**
     * Update the purchase ppm calculator
     * @param {Number} newPpmId
     */
    updatePpm (newPpmId) {
      this.updatingPurchase = true
      const body = { id: this.purchase.id, params: { ppm_calculation_id: newPpmId } }
      this.updatePurchase(body).then((id) => { this.updatingPurchase = false })
    },
    /**
     * Send the user to the intake page
     */
    addIntake () {
      this.$router.push({ name: 'create-intake', params: { fromPurchase: this.purchase } })
    },
    /**
     * Create a new hedge
     * @param {Integer} hedgeId
     */
    async addHedge (hedgeId) {
      const body = { params: { hedge_id: hedgeId }, id: this.purchase.id }
      this.updatePurchase(body).then(() => {
        let params = { id: this.$route.params.id }
        this.fetchPurchase(params)
      })
    },
    /**
     * Assign a hedge to this purchase
     * @param {Object} body
     */
    async createPurchaseHedge (body) {
      const params = {
        id: this.purchase.id,
        body
      }
      this.$store.dispatch('purchaseStore/createPurchaseHedge', params).then(() => {
        let params = { id: this.$route.params.id }
        this.fetchPurchase(params)
      })
    },
    /**
     * Update the status for the current purchase
     * @param {Object} newStatus
     */
    updatePurchaseData (params) {
      this.updatePurchase({ id: this.purchase.id, params })
    },
    pageLoadData () {
      let params = { id: this.$route.params.id }
      Promise.all([
        this.fetchPurchase(params)
      ]).then(() => { this.loading = false })
    },
    async fetchContainedToz () {
      const fmtNumber = (value) => Number(value).toPrecision(5)
      try {
        const res = await axios.get(`/api/purchases/${this.$route.params.id}/contained_toz/`)
        this.estimatedToz.pt = fmtNumber(res.data.pt)
        this.estimatedToz.pd = fmtNumber(res.data.pd)
        this.estimatedToz.rh = fmtNumber(res.data.rh)
      } catch (e) {
        this.$logger.error(e)
      }
    }
  },
  computed: {
    ...mapGetters({
      purchase: 'purchaseStore/purchase',
      purchaseStatuses: 'purchaseStore/purchaseStatuses',
      converterTypes: 'getConverterTypes',
      user: 'currentUser'
    }),
    yardNames () {
      return this.purchase.check_ins.map(ci => ci.yard.name).join(', ')
    },
    // If high-level user, can delete no matter what. If owner of purchase && buyer, can only delete if purchase status === open
    canDeletePurchase () {
      if (this.$store.getters.can('delete-purchase')) return true
      else if (this.$store.getters.can('am-delete-purchase') && this.user.id === this.purchase.created_by.id && this.purchase.status === 'OP') return true
      else return false
    }
  },
  mounted () {
    this.pageLoadData()
    this.fetchContainedToz()
  }
}
</script>
