<!--
Creates v-model based fab that can pass click back to the parent
also renders icons on the badge
-->
<template>
  <v-row>
    <v-col
      cols="9"
    >
      <v-text-field
        :loading="!ready"
        :prepend-icon="icon"
        type="number"
        v-model="count"
        @change="fieldChange"
        @input="fieldChange"
      />
    </v-col>
    <v-col
      class="pr-4 pt-6"
      cols="3"
    >
      <v-btn
        small
        color="primary"
        @click="fieldAddOne"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CheckInInput',
  props: {
    icon: { type: String, required: true },
    converterLineUrl: { type: String, required: true },
    countField: { type: String, required: true },
    initialCount: { type: Number, required: true }
  },
  data: function () {
    return {
      statusColor: '',
      count: this.initialCount,
      ready: true,
      timeout: null,
      locked: false
    }
  },
  watch: {
    initialCount: {
      handler (value) {
        // Ignore SOT while an api call is occurring
        // once it's completed we can accept new SOT entries
        if (!this.locked) this.count = value
      }
    }
  },
  methods: {
    /**
     * the function makes the api requests and is intended to be used as a timeout
     */
    converterLineUpdate () {
      this.ready = false
      let payload = {}
      payload[this.countField] = this.count
      this.patchConverterLine({ converterLineUrl: this.converterLineUrl, payload })
        .then(() => {
          this.statusColor = ''
        })
        .catch(() => {
          this.statusColor = 'error'
          // register a retry attempt
          this.timeout = setTimeout(this.converterLineUpdate, 5000)
        })
        .finally(() => {
          this.ready = true
          this.locked = false
        })
    },
    fieldAddOne () {
      this.fieldChange(Number(this.count) + 1)
    },
    /**
     * Increments the count value for the converter line
     * start the timeout for the converter update api request
     */
    fieldChange (count) {
      this.locked = true
      this.count = Number(count)
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(this.converterLineUpdate, 500)
    },
    ...mapActions('checkIn/edit', ['patchConverterLine'])
  }
}
</script>
