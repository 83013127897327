<template>
  <v-card>
    <v-card-title>
      Add Check-In Skids
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          Total Converters {{ totalConverters }}
        </v-col>
      </v-row>
      <v-row
        class="mx-4"
        v-for="(skid, i) in checkInSkids"
        :key="i"
      >
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 2 : 1">
          <v-list-item>
            <b> {{ `#${i+1}` }} </b>
          </v-list-item>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 4 : 5">
          <v-text-field
            label="Estimated Weight"
            v-model="skid.expected_weight"
            type="number"
            @input="updateSkid(skid)"
          />
        </v-col>
        <v-col :cols="4">
          <v-text-field
            label="Estimated Pieces"
            v-model="skid.expected_pieces"
            type="number"
            @input="updateSkid(skid)"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 1 : 2">
          <v-row class="pt-1">
            <gps-number-dialog
              :skid="skid"
              :updating-skid="updatingSkid"
              @setGpsNumber="setGpsNumber"
            />
            <base-button
              v-if="i === checkInSkids.length - 1 && !$vuetify.breakpoint.xs"
              icon-bool
              class="ml-4"
              @clicked="deleteSkid(skid)"
              :disabled="deletingSkid"
              :loading="deletingSkid"
              icon="mdi-delete"
              color="error"
            />
          </v-row>
        </v-col>
        <v-col
          :cols="1"
          v-if="i === checkInSkids.length - 1 && $vuetify.breakpoint.xs"
        >
          <base-button
            icon-bool
            class="pt-1"
            @clicked="deleteSkid(skid)"
            :disabled="deletingSkid"
            :loading="deletingSkid"
            icon="mdi-delete"
            color="error"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          sm="4"
          offset-sm="2"
        >
          <v-text-field
            dense
            flat
            outlined
            hint="Added to each skid on recalculation"
            label="Container Weight (without cats)"
            type="number"
            v-model="skidWeight"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            dense
            flat
            outlined
            hint="Multiplied times pieces on recalculation"
            label="average weight of 1 converter"
            type="number"
            v-model="cvAverageWeight"
          />
        </v-col>
      </v-row>
      <ol class="body-1">
        <li>
          Click on the GPS icon <v-icon>mdi-crosshairs-question</v-icon>
          to record any GPS tracking numbers (be sure to select the correct skids).
        </li>
        <li>
          Before moving to the next page be sure to add all skids.
        </li>
        <li>
          After adding all of the necessary skids you can auto-calculate the weights based on the count estimate
          and the container weight.
        </li>
        <li>
          Finally press skid print which will open a dialog.
          (If you forget to print the skids here you can print them from the check in page.)
        </li>
      </ol>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="mx-2"
        @click="addSkid"
        :disabled="addingSkid"
        :loading="addingSkid"
      >
        Add Skid <v-icon>mdi-plus</v-icon>
      </v-btn>
      <info-tooltip>
        <template v-slot:wrap-me>
          <v-btn
            :disabled="addingSkid || skids.length === 0"
            :loading="addingSkid"
            @click="recalculateSkids"
          >
            Calculate Skids
          </v-btn>
        </template>
        The calculations are saved immediately
      </info-tooltip>
      <skid-labels-editor
        :adding-skids="addingSkid"
        :check-in-skids="checkInSkids"
        :check-in="checkIn"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import SkidLabelsEditor from '@/views/CheckIns/components/SkidLabelsEditor'
import GpsNumberDialog from './GpsNumberDialog'

export default {
  name: 'AddCheckInSkids',
  components: {
    GpsNumberDialog,
    SkidLabelsEditor
  },
  props: {
    skids: { type: Array, required: true },
    deletingSkid: { type: Boolean, required: true },
    addingSkid: { type: Boolean, required: true },
    updatingSkid: { type: Boolean, required: true },
    checkIn: { type: Object, required: true }
  },
  data: () => ({
    checkInSkids: [{ pieces: null, weight: 0 }],
    cvAverageWeight: 10,
    skidWeight: 80
  }),
  watch: {
    skids () {
      const newSkids = this.skids.filter(skid => {
        for (const currentSkid of this.checkInSkids) {
          if (skid.id === currentSkid.id) return false
        }
        return true
      })
      this.checkInSkids = [ ...this.checkInSkids, ...newSkids ]
    }
  },
  computed: {
    totalConverters () {
      return this.checkIn.external_total_counts.CV_TOTAL
    }
  },
  methods: {
    recalculateSkids () {
      let skids = this.checkInSkids.map(skid => {
        skid.expected_pieces = 0
        skid.expected_weight = 0
        return skid
      })
      // prevents infinite loop
      if (skids.length < 1) return
      let pieces = this.totalConverters
      while (pieces > 0) {
        skids.map(skid => {
          if (pieces > 0) {
            pieces--
            skid.expected_pieces += 1
          }
        })
      }
      skids.map(skid => {
        const est = (skid.expected_pieces * parseFloat(this.cvAverageWeight)) + parseFloat(this.skidWeight)
        skid.expected_weight = Math.ceil(est)
        this.updateSkid(skid)
      })
    },
    /**
     * Delete a skid from the check in skids
     * @param {Number} index
     */
    deleteSkid (index) {
      const deletedSkid = this.checkInSkids.pop()
      this.$emit('deleteSkid', deletedSkid.id)
    },
    /**
     * Add a check in skid to the list of skids
     */
    addSkid () {
      this.$emit('addSkid')
    },
    /**
     * Update a check in skid
     * @param {Object} skid
     */
    updateSkid (skid) {
      if (!skid.expected_weight) skid.expected_weight = 0
      if (!skid.expected_pieces) skid.expected_pieces = 0
      this.$emit('updateSkid', skid)
    },
    /**
     * Update a check in skid's gps number
     * @param {Object} body
     */
    setGpsNumber (body) {
      const skid = body.skid
      skid.gps_number = body.gpsNumber
      this.updateSkid(skid)
    }
  },
  mounted () {
    this.checkInSkids = this.skids
  }
}
</script>
