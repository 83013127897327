import { HorizontalBar, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      default: () => ({
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      })
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
